import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  Layout,
  Avatar,
  Row,
  Col,
  Popover,
  Typography,
  Button,
  AutoComplete,
} from "antd";
import {
  UpOutlined,
  MenuOutlined,
  PoweroffOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

import { profileInstance } from "../utils/baseurl";
import routes from "../routes";
import logo from "../assets/images/logo.png";

const { Header } = Layout;
const { Title, Text } = Typography;

const Wrapper = styled.header`
  &
    .ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-radius: 25px;
  }
`;

function NavBar({ user }) {
  const [mobileCon, setMobileCon] = useState(false);
  const [popOverVisible, setPopOverVisible] = useState(false);
  const fullName = user.firstName + " " + user.lastName;

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 850) {
        setMobileCon(false);
      }
    });
  }, [mobileCon]);

  const userContent = (
    <div
      style={{
        maxWidth: 300,
        border: "1px solid rgba(0, 0, 0, 0.65)",
        borderRadius: 5,
        padding: 10,
      }}
    >
      <Title level={4} style={{ textTransform: "capitalize" }}>
        {fullName}
      </Title>
      <Text style={{ display: "block" }}>
        <PhoneOutlined /> {user.phoneNumber}
      </Text>
      <Text style={{ display: "block", marginBottom: "1rem" }}>
        <MailOutlined /> {user.email}
      </Text>
      <Button
        block
        icon={<PoweroffOutlined />}
        onClick={async () => {
          try {
            await profileInstance.get(
              "/profileService/api/v1/users/auth/logout"
            );
            localStorage.clear("token");
            localStorage.clear("refreshToken");
            window.location.pathname = "/";
          } catch (error) {
            console.error(error);
          }
        }}
      >
        Logout
      </Button>
    </div>
  );

  return (
    <Wrapper>
      <Header className="header">
        <Row style={{ width: "100%", height: "100%" }}>
          <Col flex="200px" className="logo">
            <img src={logo} alt="logo" />
          </Col>
          <Col flex="auto" className="search-container">
            <AutoComplete
              style={{ width: 300 }}
              placeholder="search"
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            >
              {routes.map((route, i) => {
                if (route.title) {
                  return (
                    <AutoComplete.Option key={i} value={route.title}>
                      <Link
                        to={route.path}
                        style={{ display: "inline-block", width: "100%" }}
                      >
                        {route.title}
                      </Link>
                    </AutoComplete.Option>
                  );
                }
                return null;
              })}
            </AutoComplete>
            <Popover
              overlayStyle={{ width: 300 }}
              content={userContent}
              trigger="click"
              placement="bottom"
              onVisibleChange={(visible) => setPopOverVisible(visible)}
            >
              <div className="user-menu">
                <UpOutlined
                  style={{
                    color: "white",
                    fontSize: 16,
                    marginTop: "-8px",
                    transform: popOverVisible ? "rotate(0)" : "rotate(-180deg)",
                    transition: "all .5s ease",
                  }}
                />
                <h4 style={{ textTransform: "capitalize" }}>{fullName}</h4>
                <Avatar
                  size="large"
                  icon={
                    <img
                      src={user.profileImage}
                      alt="avatar"
                      style={{ objectFit: "contain" }}
                    />
                  }
                />
              </div>
            </Popover>
          </Col>
        </Row>
        <div style={{ display: "flex", alignItems: "center" }}>
          <MenuOutlined
            className="menu-icon"
            style={{ color: "white", cursor: "pointer", fontSize: 24 }}
            onClick={() => setMobileCon(!mobileCon)}
          />
        </div>
      </Header>
      <Row
        className="mobile-search-container"
        style={mobileCon && { display: "flex" }}
      >
        <Col xs={24} sm={16} style={{ margin: "10px 0" }}>
          <AutoComplete
            style={{ width: 300 }}
            placeholder="search"
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
          >
            {routes.map((route, i) => {
              if (route.title) {
                return (
                  <AutoComplete.Option key={i} value={route.title}>
                    <Link to={route.path}>{route.title}</Link>
                  </AutoComplete.Option>
                );
              }
              return null;
            })}
          </AutoComplete>
        </Col>
        <Col xs={24} sm={8} style={{ margin: "10px 0" }}>
          <Popover
            content={userContent}
            trigger="click"
            placement="bottom"
            onVisibleChange={(visible) => setPopOverVisible(visible)}
          >
            <div className="user-menu">
              <UpOutlined
                style={{
                  color: "white",
                  fontSize: 16,
                  marginTop: "-8px",
                  transform: popOverVisible ? "rotate(0)" : "rotate(-180deg)",
                  transition: "all .5s ease",
                }}
              />
              <h4 style={{ textTransform: "capitalize" }}>{fullName}</h4>
              <Avatar
                size="large"
                icon={<img src={user.profileImage} alt="avatar" />}
              />
            </div>
          </Popover>
        </Col>
      </Row>
    </Wrapper>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(NavBar);
