import React from "react";
import ReactToPrint from "react-to-print";

import Invoice from "./invoiecFIleView";

export default function ViewFileInvoice(props) {
  const data = props.location.state;
  const ref = React.createRef();

  return (
    <div className="container mx-auto">
      <div className="flex justify-center mb-4">
        <ReactToPrint
          trigger={() => (
            <button className="bg-gold px-16 py-2 text-lg text-white mt-3">
              Print
            </button>
          )}
          content={() => ref.current}
        />
      </div>
      <Invoice ref={ref} data={data} />
    </div>
  );
}
