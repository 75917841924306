import React, { Component } from "react";
import moment from "moment";
import { Table, Button, Typography, AutoComplete, Modal, Divider } from "antd";

import { profileInstance, fileInstance } from "../../utils/baseurl";
import acceptImg from "../../assets/images/accept.png";

const getLawyersParams = (params) => {
  return {
    limit: params.pagination?.pageSize,
    pageNo: params.pagination?.current,
    ...params,
  };
};

export default class AssignConsultant extends Component {
  state = {
    userData: null,
    caseDetail: null,
    modal: false,
    data: [],
    loading: false,
    searchData: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
  };

  async componentDidMount() {
    const { pagination } = this.state;
    this.fetchLawyers({ pagination });
    this.fetchCase();
  }

  fetchCase = async () => {
    try {
      const res = await fileInstance.get(
        `/fileService/lawyerRoutes/api/v1/cases/getCaseById/${this.props.match.params.id}`
      );
      this.setState({ caseDetail: res.data.caseDetail });
    } catch (error) {
      console.error(error);
    }
  };

  fetchLawyers = async (params = {}) => {
    this.setState({ loading: true });

    try {
      const res = await profileInstance.get(
        "/profileService/api/v1/lawyer/userData/getUserswithSpecificProfile?profile[]=LEGALPENAL_USER&profile[]=RECEPTIONIST_USER",
        {
          params: getLawyersParams(params),
        }
      );
      this.setState({
        data: res.data.users.docs,
        loading: false,
        pagination: {
          current: res.data.users.page,
          pageSize: res.data.users.limit,
          total: res.data.users.totalDocs,
        },
      });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  };

  handleTableChange = (pagination) => {
    this.fetchLawyers({
      pagination,
    });
  };

  handleSearch = async (value) => {
    if (value.length === 0) {
      this.fetchLawyers();
      return;
    }
    try {
      const res = await profileInstance.get(
        `/profileService/api/v1/lawyer/userData/searchUserByPhoneNumberOrFirstName?profile=LEGALPENAL_USER&searchString=${value}`
      );
      this.setState({ searchData: res.data.users });
    } catch (error) {
      console.error(error);
    }
  };

  handleOnTapSearch = (_, { key }) => {
    const { pagination } = this.state;
    this.fetchLawyers({ pagination, userId: key });
  };

  asssignConsultant = async (userData) => {
    const { caseDetail } = this.state;
    const dataToUpdate = {
      caseId: caseDetail._id,
      attributes: { assignedTo: userData._id },
    };

    try {
      await fileInstance.post(
        "/fileService/lawyerRoutes/api/v1/cases/updateCasebyId",
        dataToUpdate
      );
      this.setState({ modal: true });
    } catch (error) {
      console.error(error);
    }
  };

  unAsssignConsultant = async (userData) => {
    const { caseDetail } = this.state;
    const dataToUpdate = {
      caseId: caseDetail._id,
      attributes: { unAssignedTo: userData._id },
    };
    try {
      await fileInstance.post(
        "/fileService/lawyerRoutes/api/v1/cases/updateCasebyId",
        dataToUpdate
      );
      this.fetchCase();
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const {
      data,
      loading,
      pagination,
      searchData,
      caseDetail,
      modal,
      userData,
    } = this.state;

    const columns = [
      {
        title: "Consultant Profile",
        render: (data) => (
          <img
            src={data.profileImage}
            className="w-16 h-16 rounded-full"
            alt="Lawyer Profile"
          />
        ),
      },
      {
        title: "First Name",
        dataIndex: "firstName",
      },
      {
        title: "last name",
        dataIndex: "lastName",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Type",
        render: (data) => data.profileDetails.type,
      },
      {
        title: "Contact No",
        dataIndex: "phoneNumber",
      },
      {
        title: "Created on",
        render: (data) =>
          data.createdAt
            ? moment(data.createdAt).format("MM/DD/YYYY, LTS")
            : "",
      },
      {
        title: "Action",
        render: (data) => {
          const { caseDetail } = this.state;
          if (caseDetail) {
            const user = caseDetail.assignedTo.find(
              (user) => user.userId === data._id
            );

            if (user) {
              return (
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  onClick={() => {
                    this.setState({ userData: data });
                    setTimeout(async () => {
                      this.unAsssignConsultant(data);
                    }, 1000);
                  }}
                >
                  Un Assign
                </Button>
              );
            } else {
              return (
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  onClick={() => {
                    this.setState({ userData: data });
                    setTimeout(() => {
                      this.asssignConsultant(data);
                    }, 1000);
                  }}
                >
                  Assign Case
                </Button>
              );
            }
          }
        },
      },
    ];

    return (
      <div className="container mx-auto shadow bg-white">
        <Table
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={data}
          scroll={{ x: true }}
          loading={loading}
          pagination={pagination}
          onChange={this.handleTableChange}
          title={() => (
            <div className="flex justify-between items-center">
              <Typography.Title level={3}>Assign Consultant</Typography.Title>
              <AutoComplete
                placeholder="search with name or phone num"
                style={{ width: 300 }}
                onSearch={this.handleSearch}
                onSelect={this.handleOnTapSearch}
              >
                {searchData.map((data) => (
                  <AutoComplete.Option
                    key={data._id}
                    value={data.firstName + " " + data.phoneNumber}
                  >
                    {data.firstName + " " + data.phoneNumber}
                  </AutoComplete.Option>
                ))}
              </AutoComplete>
            </div>
          )}
        />
        <Modal
          visible={modal}
          onCancel={() => this.setState({ modal: false })}
          closable={false}
          footer={null}
          bodyStyle={{ padding: 0 }}
          centered
        >
          <div className="h-32 bg-gray-800 flex justify-center py-4">
            <img src={acceptImg} alt="accepted" className="max-h-full" />
          </div>
          <h1 className="text-green-700 text-2xl font-bold text-center mt-4">
            Successfully Assigned!
          </h1>
          <p className="text-center">
            Case Number {caseDetail && caseDetail.caseNo} has been Successfully
            assigned to Consultant {userData && userData.firstName}
          </p>
          <Divider />
          <div className="flex justify-center pb-6">
            <button
              className="border py-1 px-10 rounded-full"
              onClick={() => {
                this.setState({ modal: false });
                this.fetchCase();
              }}
            >
              OK
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}
