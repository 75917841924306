import React, { useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import {
  Modal,
  Form,
  Input,
  Upload,
  Row,
  Col,
  DatePicker,
  Typography,
} from "antd";

const UploadLayerProfileWrapper = styled.div`
  height: 200px;
  margin-top: 1.8rem;

  & .ant-form-item-control-input {
    height: 100%;
  }

  & .ant-form-item-control-input-content {
    height: 100%;
  }
`;

export default function AddLawyer({
  modal,
  setModal,
  onCreate,
  setLawyerImage,
  confirmLoading,
  userData,
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        lastName: userData.lastName,
        firstName: userData.firstName,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        dob: moment(userData.dob),
        address: userData.address,
      });
    }
    return () => form.resetFields();
  }, [userData, form]);

  return (
    <Modal
      visible={modal}
      width={"80%"}
      closable={false}
      confirmLoading={confirmLoading}
      onCancel={() => setModal(false)}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical">
        <Row gutter={32}>
          <Col xs={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: "First Name Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <UploadLayerProfileWrapper>
              <Form.Item
                name="profileImage"
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }
                  return e && e.fileList;
                }}
                rules={[{ required: true, message: "Profile Image Required" }]}
                style={{ height: "100%" }}
              >
                <Upload.Dragger
                  onRemove={(file) => {
                    const fileList = form.getFieldValue("profileImage");
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                      fileList: newFileList,
                    };
                  }}
                  beforeUpload={(file) => {
                    form.setFieldsValue({ profileImage: file });
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                      setLawyerImage(reader.result);
                    };
                    reader.onerror = function (error) {
                      console.log("to BASE64 Conversion Error ", error);
                    };
                    return false;
                  }}
                  style={{ height: "100%" }}
                >
                  <p className="ant-upload-hint">Upload Photo</p>
                </Upload.Dragger>
              </Form.Item>
            </UploadLayerProfileWrapper>
          </Col>
          <Col xs={12} style={{ marginTop: "-145px" }}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: "Last Name Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 12, pull: 12 }} style={{ marginTop: "-62px" }}>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[{ required: true, message: "Phone Number Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Email Required" },
                { type: "email", message: "Email is not valid" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="dob"
              label="DOB"
              rules={[
                {
                  type: "object",
                  required: true,
                  message: "Please select DOB",
                },
              ]}
            >
              <DatePicker placeholder={null} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: "Address is Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {userData ? (
            <>
              <Col xs={24}>
                <Typography.Title level={2}>Recreate Password</Typography.Title>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: "Password is Required" }]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label="Confirm Password"
                  name="confirmPassword"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The two passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            </>
          ) : (
            <>
              <Col xs={24}>
                <Typography.Title level={2}>
                  Professional Details
                </Typography.Title>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label="Consultant Type"
                  name="consultantType"
                  rules={[
                    { required: true, message: "Consultant Type is Required" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label="Experience"
                  name="experience"
                  rules={[
                    { required: true, message: "Experience is Required" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </Modal>
  );
}
