import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { SET_CURRENT_USER, SET_LOADING, SET_ALL_USERS } from "./actionTypes";
import isEmpty from "../utils/isEmpty";

const initialState = {
  loading: false,
  isAuthenticated: false,
  user: {},
  allUsers: [],
};

const rootreducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: !isEmpty(action.payload),
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    default:
      return state;
  }
};

const store = createStore(
  rootreducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
