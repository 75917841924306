import axios from "axios";

let profileInstance, fileInstance;

profileInstance = axios.create({
  baseURL: "https://prod-legalemirates.wowets.com",
});
fileInstance = axios.create({
  baseURL: "https://prod-legalemirates.wowets.com",
});

// profileInstance = axios.create({
//   baseURL: "https://api-profiles.wowets.com",
// });
// fileInstance = axios.create({ baseURL: "https://api-files.wowets.com" });

export { profileInstance, fileInstance };
