import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import ModalImage from "react-modal-image";
import { Table, AutoComplete } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

import { fileInstance, profileInstance } from "../utils/baseurl";

const Wrapper = styled.div`
  &
    .ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-radius: 25px;
  }
`;

const columns = [
  {
    title: "Customer",
    render: (data) =>
      data.userProfile
        ? `${data.userProfile.firstName} ${data.userProfile.lastName}`
        : "",
    align: "center",
  },
  {
    title: "Email",
    render: (data) => (data.userProfile ? `${data.userProfile.email}` : ""),
    align: "center",
  },
  {
    title: "Contact",
    render: (data) =>
      data.userProfile ? `${data.userProfile.phoneNumber}` : "",
    align: "center",
  },
  {
    title: "Expire At",
    render: (data) => moment(data.createdAt).format("MMMM Do YYYY, LTS"),
    align: "center",
    width: 200,
  },
  {
    title: "Status",
    render: (data) => {
      switch (data.status) {
        case "CASHED":
          return <p className="text-green-600">{data.status}</p>;
        case "PENDING":
        case "CANCELED":
          return (
            <p className="text-red-600 flex items-center">
              <ExclamationCircleFilled className="text-base mr-2" />
              {data.status}
            </p>
          );
        default:
          return <p>{data.status}</p>;
      }
    },
    align: "center",
    width: 100,
  },
  {
    title: "Cheque No",
    dataIndex: "chequeNo",
    align: "center",
  },
  {
    title: "Cheque Amount",
    render: (data) => `AED ${data.chequeAmount ? data.chequeAmount : ""}`,
    align: "center",
    width: 100,
  },
  {
    title: "Show Cheque",
    render: (data) => {
      const ext = data.chequePath.split(".").pop();
      if (data.chequePath.startsWith("http")) {
        if (ext === "pdf") {
          return (
            <a
              href={data.chequePath}
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              Cheque
            </a>
          );
        } else {
          return (
            <ModalImage
              small={data.chequePath}
              large={data.chequePath}
              className="small-preview-image"
            />
          );
        }
      } else {
        if (ext === "pdf") {
          return (
            <a
              href={`https://api-files.wowets.com/fileService/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${data.chequePath}`}
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              Cheque
            </a>
          );
        } else {
          return (
            <ModalImage
              small={`https://api-files.wowets.com/fileService/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${data.chequePath}`}
              large={`https://api-files.wowets.com/fileService/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${data.chequePath}`}
              className="small-preview-image"
            />
          );
        }
      }
    },
    align: "center",
  },
];

const getPaymentChequesParams = (params) => {
  return {
    limit: params.pagination?.pageSize,
    pageNo: params.pagination?.current,
    ...params,
  };
};

export default class PaymentCheques extends Component {
  state = {
    data: [],
    loading: false,
    searchData: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.paymentCheques({ pagination });
  }

  paymentCheques = async (params = {}) => {
    this.setState({ loading: true });
    try {
      const res = await fileInstance.get(
        "/fileService/lawyerRoutes/api/v1/cheque/getChequeWithPagination",
        {
          params: getPaymentChequesParams(params),
        }
      );
      this.setState({
        data: res.data.cheques.docs,
        loading: false,
        pagination: {
          current: res.data.cheques.page,
          pageSize: res.data.cheques.limit,
          total: res.data.cheques.totalDocs,
        },
      });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  };

  handleTableChange = (pagination) => {
    this.paymentCheques({
      pagination,
    });
  };

  handleSearch = async (value) => {
    if (value.length === 0) {
      this.paymentCheques();
      return;
    }
    try {
      const res = await profileInstance.get(
        `/profileService/api/v1/lawyer/userData/searchUserByPhoneNumberOrFirstName?searchString=${value}`
      );
      this.setState({ searchData: res.data.users });
    } catch (error) {
      console.error(error);
    }
  };

  handleOnTapSearch = (v, { key }) => {
    const { pagination } = this.state;
    this.paymentCheques({ pagination, userId: key });
  };

  render() {
    const { data, loading, pagination, searchData } = this.state;

    return (
      <Wrapper className="container mx-auto">
        <Table
          columns={columns}
          bordered
          rowKey={(record) => record._id}
          dataSource={data}
          scroll={{ x: true }}
          loading={loading}
          pagination={pagination}
          onChange={this.handleTableChange}
          expandable={{
            expandedRowRender: (record) => (
              <p style={{ margin: 0 }}>Reason: {record.cancelReason}</p>
            ),
            rowExpandable: (record) => record.status === "CANCELED",
          }}
          title={() => (
            <div className="flex justify-between items-center">
              <h1 className="m-0">Bank Receipts</h1>
              <AutoComplete
                placeholder="search with name or phone num"
                style={{ width: 300 }}
                onSearch={this.handleSearch}
                onSelect={this.handleOnTapSearch}
              >
                {searchData.map((data) => (
                  <AutoComplete.Option
                    key={data._id}
                    value={data.firstName + " " + data.phoneNumber}
                  >
                    {data.firstName + " " + data.phoneNumber}
                  </AutoComplete.Option>
                ))}
              </AutoComplete>
            </div>
          )}
        />
      </Wrapper>
    );
  }
}
