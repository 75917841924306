import React from "react";
import { Modal, Form, Typography, Input, Upload, message } from "antd";
import { FileAddFilled, InboxOutlined } from "@ant-design/icons";

import { fileInstance } from "../../utils/baseurl";

export default function CaseAttachmentModal({
  attachmentModal,
  setAttachmentModal,
  caseId,
  fetchCaseDetail,
}) {
  const [form] = Form.useForm();

  const handleCaseAttachmentFile = async (values) => {
    const formData = new FormData();
    formData.append("caseId", caseId);
    formData.append("docName", values.docName);
    formData.append("docNumber", values.docNumber);
    formData.append("caseAttachment", values.caseAttachment[0].originFileObj);

    try {
      await fileInstance.post(
        "/fileService/lawyerRoutes/api/v1/cases/uploadAttachmentsByCaseId",
        formData
      );
      message.success("Case Attachment Uploaded!");
      fetchCaseDetail();
      setAttachmentModal();
    } catch (error) {
      console.error(error);
      message.error("Something went wrong!");
    }
  };

  return (
    <Modal
      visible={attachmentModal}
      centered
      width={400}
      okText="Upload Document"
      cancelText="Back"
      onCancel={setAttachmentModal}
      title={
        <div className="flex items-center">
          <FileAddFilled
            style={{
              fontSize: "1.5rem",
              marginRight: "1rem",
              color: "#be9a39",
            }}
          />
          <Typography.Title level={3} style={{ marginBottom: 0 }}>
            Add Document
          </Typography.Title>
        </div>
      }
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            handleCaseAttachmentFile(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <div>
        <Typography.Paragraph strong>
          Add the additional document here related to this case
        </Typography.Paragraph>
        <Form layout="vertical" requiredMark={false} form={form}>
          <Form.Item
            name="docName"
            label="Document Name"
            rules={[{ required: true, message: "Document Name Required!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="docNumber" label="Document Number">
            <Input />
          </Form.Item>
          <Form.Item
            name="caseAttachment"
            valuePropName="fileList"
            rules={[{ required: true, message: "Document File Required!" }]}
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
          >
            <Upload.Dragger
              onRemove={(file) => {
                const fileList = form.getFieldValue("caseAttachment");
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                return {
                  fileList: newFileList,
                };
              }}
              beforeUpload={(file) => {
                form.setFieldsValue({ profileImage: file });
                return false;
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined style={{ color: "#be9a39" }} />
              </p>
              <p className="ant-upload-text">Select Document</p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}
