import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import styled from "styled-components";
import { Row, Col, Spin, Empty, Modal, Descriptions, AutoComplete } from "antd";
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import { fileInstance, profileInstance } from "../../utils/baseurl";
import BreadCrumb from "../BreadCrumb";

const Wrapper = styled.div`
  &
    .ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-radius: 25px;
  }
`;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const renderNoOfPages = (length) => {
  const pages = [];
  for (let i = 1; i <= length; i++) {
    pages.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }
  return pages;
};

function File() {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filesPerPage, setFilesPerPage] = useState(9);
  const [fileData, setFileData] = useState(null);
  const [searchData, setSearchData] = useState(null);
  const [detailModal, setDetailModal] = useState(false);
  const [detailData, setDetailData] = useState(null);

  const fetchFiles = useCallback(
    async (id) => {
      let url;
      if (id) {
        url = `/fileService/lawyerRoutes/api/v1/files/getUsersWithPagination?userId=${id}`;
      } else {
        url = `/fileService/lawyerRoutes/api/v1/files/getUsersWithPagination?limit=${filesPerPage}&pageNo=${currentPage}`;
      }
      setLoading(true);
      try {
        const res = await fileInstance.get(url);
        setFileData(res.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },
    [filesPerPage, currentPage]
  );

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const handleSearch = async (value) => {
    if (value.length === 0) {
      fetchFiles();
      return;
    }
    try {
      const res = await profileInstance.get(
        `/profileService/api/v1/lawyer/userData/searchUserByPhoneNumberOrFirstName?searchString=${value}`
      );
      setSearchData(res.data.users);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnTapSearch = (_, { key }) => {
    fetchFiles(key);
  };

  return (
    <Wrapper className="container">
      <Row
        style={{
          border: "1px solid",
          marginBottom: "3em",
          backgroundColor: "white",
          color: "black",
          fontWeight: "bold",
        }}
      >
        <Col
          span={24}
          style={{
            borderBottom: "1px solid",
            padding: "0 24px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <BreadCrumb />
          <AutoComplete
            placeholder="Search File No"
            onSearch={handleSearch}
            onSelect={handleOnTapSearch}
            style={{ width: 300 }}
          >
            {searchData &&
              searchData.map((data) => (
                <AutoComplete.Option
                  key={data._id}
                  value={data.firstName + " " + data.phoneNumber}
                >
                  {data.firstName + " " + data.phoneNumber}
                </AutoComplete.Option>
              ))}
          </AutoComplete>
        </Col>
        {!loading ? (
          fileData?.files.docs.length > 0 ? (
            fileData?.files.docs.map((file) => {
              return (
                <Col
                  xs={24}
                  md={8}
                  key={file._id}
                  style={{ padding: 20, borderTop: "1px solid black" }}
                >
                  <div className="file-timeline">
                    <h4>FILE NO.{file.fileNoPrefix + file.fileNo}</h4>
                    <p>Client: Individual</p>
                    <p>
                      Full Name:{" "}
                      {file.userProfile.firstName +
                        " " +
                        file.userProfile.lastName}
                    </p>
                    <p>Phone No: {file.userProfile.phoneNumber}</p>
                    <div className="link">
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          setDetailData(file);
                          setDetailModal(true);
                        }}
                      >
                        click here
                      </span>
                      <span style={{ color: "gray" }}>
                        {moment(file.createdAt).format("DD-MM-YYYY")}
                      </span>
                    </div>
                  </div>
                </Col>
              );
            })
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ width: "100%" }}
            />
          )
        ) : (
          <div className="flex justify-center w-full my-4">
            <Spin indicator={antIcon} />
          </div>
        )}
      </Row>
      <div className="flex justify-between flex-wrap">
        <p>
          show
          <select
            className="mx-2 px-2 border border-solid border-gray-700"
            value={filesPerPage}
            onChange={(e) => setFilesPerPage(e.target.value)}
          >
            <option value="3">3</option>
            <option value="6">6</option>
            <option value="9">9</option>
            <option value="12">12</option>
            <option value="15">15</option>
          </select>
          per page
        </p>
        <div>
          page {currentPage} of {fileData?.files.totalPages}
          <button
            className="inline-flex py-1 px-2 ml-2 border rounded-l-full border-gray-700"
            disabled={!fileData?.files.hasPrevPage}
            onClick={() => setCurrentPage(fileData.files.prevPage)}
          >
            <CaretLeftOutlined />
          </button>
          <button
            className="inline-flex py-1 px-2 mr-2 border rounded-r-full border-gray-700"
            disabled={!fileData?.files.hasNextPage}
            onClick={() => setCurrentPage(fileData.files.nextPage)}
          >
            <CaretRightOutlined />
          </button>
          jump to page
          <select
            className="mx-2 px-2 border border-solid border-gray-700"
            onChange={(e) => setCurrentPage(e.target.value)}
          >
            {renderNoOfPages(fileData?.files.totalPages)}
          </select>
        </div>
      </div>
      {detailData && detailModal && (
        <Modal
          visible={detailModal}
          onCancel={() => setDetailModal(false)}
          title={`File No. ${detailData.fileNoPrefix + detailData.fileNo}`}
          footer={null}
          width={800}
        >
          <Descriptions bordered layout="vertical" column={{ xs: 1, md: 5 }}>
            <Descriptions.Item label="Full Name">
              {detailData.userProfile.firstName +
                " " +
                detailData.userProfile.lastName}
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              {detailData.address}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {detailData.email}
            </Descriptions.Item>
            <Descriptions.Item label="Nationality">
              {detailData.nationality}
            </Descriptions.Item>
            <Descriptions.Item label="File Status">
              {detailData.fileStatus}
            </Descriptions.Item>
            <Descriptions.Item label="Summary">
              {detailData.summary}
            </Descriptions.Item>
          </Descriptions>
        </Modal>
      )}
    </Wrapper>
  );
}

export default File;
