import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";

const { Sider } = Layout;
const { SubMenu } = Menu;

function SideBar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const location = useLocation();
  const isSessionPage = location.pathname === "/calendar";

  useEffect(() => {
    if (isSessionPage) {
      setIsDrawerOpen(true);
    } else {
      setIsDrawerOpen(false);
    }
  }, [isSessionPage]);

  return (
    <Sider
      width={300}
      className="site-layout-background"
      theme="light"
      breakpoint="md"
      collapsedWidth="0"
      collapsed={isDrawerOpen}
      collapsible={isSessionPage}
      onCollapse={(collapsed, type) => {
        setIsDrawerOpen(collapsed);
      }}
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        style={{ height: "100%" }}
      >
        <SubMenu key="sub1" title="Founder & Lawyer Panel">
          <Menu.Item key="/">
            <Link to="/">Files</Link>
          </Menu.Item>
          <Menu.Item key="/open-file">
            <Link to="/open-file">Open File</Link>
          </Menu.Item>
          <Menu.Item key="/file-invoices">
            <Link to="/file-invoices">File Invoices</Link>
          </Menu.Item>
          <Menu.Item key="/visitors">
            <Link to="/visitors">Visitors</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub3" title="Case Management System">
          <Menu.Item key="/open-new-case">
            <Link to="/open-new-case">Open a Case</Link>
          </Menu.Item>
          <Menu.Item key="/case-invoices">
            <Link to="/case-invoices">Case Invoices</Link>
          </Menu.Item>
          <Menu.Item key="/calendar">
            <Link to="/calendar">Sessions</Link>
          </Menu.Item>
          <Menu.Item key="/cases">
            <Link to="/cases">All Cases</Link>
          </Menu.Item>
          {process.env.NODE_ENV === "development" && (
            <Menu.Item key="/events">
              <Link to="/events">Events</Link>
            </Menu.Item>
          )}
        </SubMenu>
        <Menu.Item key="/bank-receipts">
          <Link to="/bank-receipts">Bank Receipts</Link>
        </Menu.Item>
        <Menu.Item key="/payment-cheques">
          <Link to="/payment-cheques">Payment Cheques</Link>
        </Menu.Item>
        <Menu.Item key="/lawyers">
          <Link to="/lawyers">Consultants</Link>
        </Menu.Item>
        {process.env.NODE_ENV === "development" && (
          <SubMenu key="sub2" title="Counselling">
            <Menu.Item key="/free-time-counselling">
              <Link to="/free-time-counselling">Free On Time Counselling</Link>
            </Menu.Item>
            <Menu.Item key="/paid-counselling">
              <Link to="/paid-counselling">Paid Counselling</Link>
            </Menu.Item>
            <Menu.Item key="/writing-counselling">
              <Link to="/writing-counselling">Writing Counselling</Link>
            </Menu.Item>
          </SubMenu>
        )}
        {process.env.NODE_ENV === "development" && (
          <Menu.Item key="/chat">
            <Link to="/chat">Live Chatting</Link>
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  );
}

export default SideBar;
