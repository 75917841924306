import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import {
  Typography,
  Switch,
  Form,
  Input,
  Row,
  Col,
  Select,
  Upload,
  Divider,
  Checkbox,
  Button,
  message,
} from "antd";
import Icon, {
  IdcardFilled,
  IdcardOutlined,
  UploadOutlined,
  FileTextOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { fileInstance } from "../../utils/baseurl";
import logo from "../../assets/images/logo3.png";

const { Option } = Select;

const { Title, Text } = Typography;
const { Dragger } = Upload;
const Wrapper = styled.div`
  & .ant-switch-checked {
    background-color: #be9d40;
  }

  & .ant-form-item-label > label {
    width: 100%;
  }

  @media (min-width: 768px) {
    & .new-case-margin-col {
      margin-top: -63px;
    }
  }
`;

const AddClientWrapper = styled.div`
  height: 150px;
  margin-top: 30px;

  & .ant-select {
    height: 100%;
    width: 100%;
  }

  & .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 100%;
  }

  & .ant-select-selection-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .ant-select-selection-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    font-size: 0.85rem;
  }
`;

const balanceScaleSolidSVG = () => (
  <svg
    width="1em"
    height="1em"
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="balance-scale"
    className="svg-inline--fa fa-balance-scale fa-w-20"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 512"
  >
    <path
      fill="currentColor"
      d="M256 336h-.02c0-16.18 1.34-8.73-85.05-181.51-17.65-35.29-68.19-35.36-85.87 0C-2.06 328.75.02 320.33.02 336H0c0 44.18 57.31 80 128 80s128-35.82 128-80zM128 176l72 144H56l72-144zm511.98 160c0-16.18 1.34-8.73-85.05-181.51-17.65-35.29-68.19-35.36-85.87 0-87.12 174.26-85.04 165.84-85.04 181.51H384c0 44.18 57.31 80 128 80s128-35.82 128-80h-.02zM440 320l72-144 72 144H440zm88 128H352V153.25c23.51-10.29 41.16-31.48 46.39-57.25H528c8.84 0 16-7.16 16-16V48c0-8.84-7.16-16-16-16H383.64C369.04 12.68 346.09 0 320 0s-49.04 12.68-63.64 32H112c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h129.61c5.23 25.76 22.87 46.96 46.39 57.25V448H112c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h416c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"
    ></path>
  </svg>
);

const caseTypeOptions = [
  { level: "Criminal Cases", value: "Criminal Cases" },
  { level: "Corporate Law", value: "Corporate Law" },
  { level: "Arbitration", value: "Arbitration" },
  {
    level: "International/ Interpol Cases",
    value: "International/ Interpol Cases",
  },
  { level: "Financial Crime", value: "Financial Crime" },
  { level: "Family business", value: "Family business" },
  { level: "Commercial", value: "Commercial" },
  { level: "Banking", value: "Banking" },
  {
    level: "Construction & Infrastructure",
    value: "Construction & Infrastructure",
  },
  { level: "Competition", value: "Competition" },
  {
    level: "Data Privacy & Cybersecurity",
    value: "Data Privacy & Cybersecurity",
  },
  { level: "Intellectual Property", value: "Intellectual Property" },
  { level: "Merger & Acquisitions", value: "Merger & Acquisitions" },
  { level: "Private Notary", value: "Private Notary" },
  { level: "Insurance", value: "Insurance" },
  { level: "Capital Market", value: "Capital Market" },
  { level: "Private Client Services", value: "Private Client Services" },
  { level: "Family Law", value: "Family Law" },
  { level: "Business Law", value: "Business Law" },
  { level: "Real Estate Law", value: "Real Estate Law" },
  { level: "Injury Law", value: "Injury Law" },
  { level: "Educational Law", value: "Educational Law" },
  { level: "VAT Law", value: "VAT Law" },
  { level: "Tax Law Advisory", value: "Tax Law Advisory" },
  { level: "International Tax Law", value: "International Tax Law" },
  { level: "Technology/Cyber Law", value: "Technology/Cyber Law" },
  { level: "Chemical", value: "Chemical" },
  { level: "Aviation", value: "Aviation" },
  { level: "Consumer Product", value: "Consumer Product" },
  { level: "Financial institution", value: "Financial institution" },
  {
    level: "Manufacture and Industrial",
    value: "Manufacture and Industrial",
  },
  { level: "Maritime & Shipping", value: "Maritime & Shipping" },
  { level: "Media", value: "Media" },
  { level: "Mining and Metals", value: "Mining and Metals" },
  { level: "Oil and Gas", value: "Oil and Gas" },
  {
    level: "Pharmaceuticals and Healthcare",
    value: "Pharmaceuticals and Healthcare",
  },
  { level: "Power", value: "Power" },
  { level: "Private Equity", value: "Private Equity" },
  { level: "Sovereigns", value: "Sovereigns" },
  { level: "Telecommunication", value: "Telecommunication" },
  { level: "Space Laws", value: "Space Laws" },
  { level: "Establishing Business", value: "Establishing Business" },
  { level: "Anti-Money Laundering", value: "Anti-Money Laundering" },
  { level: "Drug Law", value: "Drug Law" },
  { level: "Multinational Disputes", value: "Multinational Disputes" },
  { level: "Labor Law", value: "Labor Law" },
  { level: "Others", value: "Others" },
];

export default function OpenNewCase() {
  const [users, setUsers] = useState([]);
  const [userFile, setUserFile] = useState([]);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [typeInArabic, setTypeInArabic] = useState(false);
  const allUsers = useSelector((state) => state.allUsers);
  const [form] = Form.useForm();

  useEffect(() => {
    setUsers(allUsers);
  }, [allUsers]);

  const getUserFile = async (_, option) => {
    try {
      const res = await fileInstance.get(
        "/fileService/lawyerRoutes/api/v1/files/getFileByUserId",
        {
          params: {
            userId: option.key,
          },
        }
      );
      setUserFile(res.data.file);
    } catch (error) {
      console.error(error);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleOnFinish = async (values) => {
    setFormSubmitting(true);

    const parsedClient = JSON.parse(values.client);
    const emiratesFile = values.emiratesId[0].originFileObj;
    const passportFile = values.passport
      ? values.passport[0].originFileObj
      : undefined;
    const poaCopyFile = values.poaCopy
      ? values.poaCopy[0].originFileObj
      : undefined;
    const visaFile = values.visa ? values.visa[0].originFileObj : undefined;
    const basicCaseEvidanceFile = values.basicCaseEvidance
      ? values.basicCaseEvidance[0].originFileObj
      : undefined;

    const formData = new FormData();
    formData.append("emiratesIdNumber", values.emiratesIdNumber);
    formData.append("emiratesId", emiratesFile);
    formData.append("passport", passportFile);
    formData.append("visa", visaFile);
    formData.append("poaCopy", poaCopyFile);
    formData.append("basicCaseEvidance", basicCaseEvidanceFile);
    formData.append("userId", parsedClient._id);
    formData.append("fileNo", values.fileNo);
    formData.append("nationality", values.nationality);
    formData.append("employerType", values.employerType);
    formData.append("permanentAddress", values.permanentAddress);
    formData.append("homeTel", values.homeTel);
    formData.append("officeTel", values.officeTel);
    formData.append("fax", values.fax);
    formData.append("clientType", values.clientType);
    formData.append("lawyerName", values.lawyerName);
    formData.append("stage", values.stage);
    formData.append("caseType", values.caseType);
    formData.append("poaType", values.poaType);
    formData.append("accusation", values.accusation);
    formData.append("caseDescription", values.caseDescription);
    formData.append("bussinessType", values.bussinessType);
    formData.append("opponentName", values.opponentName);
    formData.append("opponentNationality", values.opponentNationality);
    formData.append("opponentPhoneNumber", values.opponentPhoneNumber);
    formData.append("opponentEmail", values.opponentEmail);
    formData.append("opponentAddress", values.opponentAddress);

    try {
      await fileInstance.post(
        "/fileService/lawyerRoutes/api/v1/cases/openCase",
        formData
      );
      setFormSubmitting(false);
      message.success("Success");
    } catch (error) {
      console.error(error);
      setFormSubmitting(false);
      message.error("Something went wrong");
    }
  };

  const arabicAttributes = {};

  if (typeInArabic) {
    arabicAttributes.dir = "rtl";
    arabicAttributes.lang = "ar";
  }

  return (
    <Wrapper className="container mx-auto bg-white px-8 py-4 rounded-md shadow">
      <div className="w-full flex h-4">
        <div className="w-40 bg-gold"></div>
        <div className="w-20 bg-gold-lighter"></div>
      </div>
      <div className="flex justify-between">
        <Title level={3} className="self-center">
          Open a Case
          <span style={{ fontSize: "inherit", marginLeft: "2rem" }}>
            فتح قضية
          </span>
        </Title>
        <img src={logo} alt="Logo" className="w-2/5" />
      </div>
      <div className="flex justify-between items-center">
        <div className="text-2xl flex items-center">
          Date
          <span className="border border-black ml-1 px-1 text-xs">
            {moment().format("DD")}
          </span>
          <span className="border border-black ml-1 px-1 text-xs">
            {moment().format("MM")}
          </span>
          <span className="border border-black ml-1 px-1 text-xs">
            {moment().format("YYYY")}
          </span>
        </div>
        <Text>Case No BZ/0000</Text>
      </div>
      <div className="w-full flex h-4">
        <div className="w-3/4 bg-gold"></div>
        <div className="w-3/12 bg-gold-lighter"></div>
      </div>
      <div className="flex justify-between items-center">
        <div className="text-2xl">
          Client*
          <span style={{ fontSize: "inherit", marginLeft: "2rem" }}>
            الموكل
          </span>
        </div>
        <label>
          Type in Arabic
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            style={{ marginLeft: 10 }}
            onChange={(checked) => setTypeInArabic(checked)}
          />
        </label>
      </div>
      <div>
        <Form
          layout="vertical"
          form={form}
          onFinish={handleOnFinish}
          onValuesChange={(changedFields) => {
            if (changedFields.client) {
              const parsedClient = JSON.parse(changedFields.client);
              form.setFieldsValue({
                fullName: parsedClient.firstName + " " + parsedClient.lastName,
                email: parsedClient.email,
                mobileNumber: parsedClient.phoneNumber,
                uaeAddress: parsedClient.address
                  ? parsedClient.address
                  : "Address",
              });
            }
          }}
        >
          <Row gutter={16}>
            <Col xs={24} md={8}>
              <AddClientWrapper>
                <Form.Item noStyle name="client">
                  <Select
                    placeholder="Add Client"
                    onChange={getUserFile}
                    dropdownRender={(menu) => (
                      <div className="px-2">
                        <Input.Search
                          onChange={(e) => {
                            const { value } = e.target;
                            if (value.length === 0) {
                              setUsers(allUsers);
                              return;
                            } else {
                              const searchedUsers = users.filter((user) =>
                                user.firstName
                                  .toLowerCase()
                                  .includes(value.toLowerCase())
                              );
                              setUsers(searchedUsers);
                            }
                          }}
                        />
                        <p className="my-4">
                          <UserOutlined
                            style={{
                              fontSize: "1rem",
                              marginRight: "5px",
                            }}
                          />
                          Existing Clients
                        </p>
                        <Divider style={{ margin: "5px 0" }} />
                        {menu}
                      </div>
                    )}
                  >
                    {users.map((user) => (
                      <Select.Option
                        key={user._id}
                        value={JSON.stringify(user)}
                      >
                        <p>{user.firstName + " " + user.lastName}</p>
                        <p>{user.phoneNumber}</p>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </AddClientWrapper>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="fullName"
                label={
                  <div className="flex w-full justify-between items-center">
                    Full Name
                    <span style={{ fontSize: "inherit" }}>الاسم الكامل</span>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "Full Name is required",
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label={
                  <div className="flex w-full justify-between items-center">
                    Mobile Number
                    <span style={{ fontSize: "inherit" }}>
                      رقم الهاتف المحمول
                    </span>
                  </div>
                }
                name="mobileNumber"
                rules={[
                  {
                    required: true,
                    message: "Mobile Number is required",
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24, push: 0 }}
              md={{ span: 8, push: 8 }}
              className="new-case-margin-col"
            >
              <Form.Item
                label={
                  <div className="flex w-full justify-between items-center">
                    Email
                    <span style={{ fontSize: "inherit" }}>
                      رقم الهاتف المحمولالبريد الإلكتروني
                    </span>
                  </div>
                }
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24, push: 0 }}
              md={{ span: 8, push: 8 }}
              className="new-case-margin-col"
            >
              <Form.Item
                label={
                  <div className="flex w-full justify-between items-center">
                    UAE Address
                    <span style={{ fontSize: "inherit" }}>
                      عنوان دولة الإمارات العربية المتحدة
                    </span>
                  </div>
                }
                name="uaeAddress"
                rules={[
                  {
                    required: true,
                    message: "UAE Address is required",
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={
                  <div className="flex w-full justify-between items-center">
                    Emirates ID
                    <span style={{ fontSize: "inherit" }}>هويه الإمارات</span>
                  </div>
                }
                name="emiratesIdNumber"
                rules={[
                  {
                    required: true,
                    message: "Emirates ID is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={
                  <div className="flex w-full justify-between items-center">
                    Nationality
                    <span style={{ fontSize: "inherit" }}>الجنسية</span>
                  </div>
                }
                name="nationality"
                rules={[
                  {
                    required: true,
                    message: "Nationality is required",
                  },
                ]}
              >
                <Select showSearch>
                  <Option value="Afghanistan">Afghanistan</Option>
                  <Option value="Åland Islands">Åland Islands</Option>
                  <Option value="Albania">Albania</Option>
                  <Option value="Algeria">Algeria</Option>
                  <Option value="American Samoa">American Samoa</Option>
                  <Option value="Andorra">Andorra</Option>
                  <Option value="Angola">Angola</Option>
                  <Option value="Anguilla">Anguilla</Option>
                  <Option value="Antarctica">Antarctica</Option>
                  <Option value="Antigua and Barbuda">
                    Antigua and Barbuda
                  </Option>
                  <Option value="Argentina">Argentina</Option>
                  <Option value="Armenia">Armenia</Option>
                  <Option value="Aruba">Aruba</Option>
                  <Option value="Australia">Australia</Option>
                  <Option value="Austria">Austria</Option>
                  <Option value="Azerbaijan">Azerbaijan</Option>
                  <Option value="Bahamas">Bahamas</Option>
                  <Option value="Bahrain">Bahrain</Option>
                  <Option value="Bangladesh">Bangladesh</Option>
                  <Option value="Barbados">Barbados</Option>
                  <Option value="Belarus">Belarus</Option>
                  <Option value="Belgium">Belgium</Option>
                  <Option value="Belize">Belize</Option>
                  <Option value="Benin">Benin</Option>
                  <Option value="Bermuda">Bermuda</Option>
                  <Option value="Bhutan">Bhutan</Option>
                  <Option value="Bolivia">Bolivia</Option>
                  <Option value="Bosnia and Herzegovina">
                    Bosnia and Herzegovina
                  </Option>
                  <Option value="Botswana">Botswana</Option>
                  <Option value="Bouvet Island">Bouvet Island</Option>
                  <Option value="Brazil">Brazil</Option>
                  <Option value="British Indian Ocean Territory">
                    British Indian Ocean Territory
                  </Option>
                  <Option value="Brunei Darussalam">Brunei Darussalam</Option>
                  <Option value="Bulgaria">Bulgaria</Option>
                  <Option value="Burkina Faso">Burkina Faso</Option>
                  <Option value="Burundi">Burundi</Option>
                  <Option value="Cambodia">Cambodia</Option>
                  <Option value="Cameroon">Cameroon</Option>
                  <Option value="Canada">Canada</Option>
                  <Option value="Cape Verde">Cape Verde</Option>
                  <Option value="Cayman Islands">Cayman Islands</Option>
                  <Option value="Central African Republic">
                    Central African Republic
                  </Option>
                  <Option value="Chad">Chad</Option>
                  <Option value="Chile">Chile</Option>
                  <Option value="China">China</Option>
                  <Option value="Christmas Island">Christmas Island</Option>
                  <Option value="Cocos (Keeling) Islands">
                    Cocos (Keeling) Islands
                  </Option>
                  <Option value="Colombia">Colombia</Option>
                  <Option value="Comoros">Comoros</Option>
                  <Option value="Congo">Congo</Option>
                  <Option value="Congo, The Democratic Republic of The">
                    Congo, The Democratic Republic of The
                  </Option>
                  <Option value="Cook Islands">Cook Islands</Option>
                  <Option value="Costa Rica">Costa Rica</Option>
                  <Option value="Cote D'ivoire">Cote D'ivoire</Option>
                  <Option value="Croatia">Croatia</Option>
                  <Option value="Cuba">Cuba</Option>
                  <Option value="Cyprus">Cyprus</Option>
                  <Option value="Czech Republic">Czech Republic</Option>
                  <Option value="Denmark">Denmark</Option>
                  <Option value="Djibouti">Djibouti</Option>
                  <Option value="Dominica">Dominica</Option>
                  <Option value="Dominican Republic">Dominican Republic</Option>
                  <Option value="Ecuador">Ecuador</Option>
                  <Option value="Egypt">Egypt</Option>
                  <Option value="El Salvador">El Salvador</Option>
                  <Option value="Equatorial Guinea">Equatorial Guinea</Option>
                  <Option value="Eritrea">Eritrea</Option>
                  <Option value="Estonia">Estonia</Option>
                  <Option value="Ethiopia">Ethiopia</Option>
                  <Option value="Falkland Islands (Malvinas)">
                    Falkland Islands (Malvinas)
                  </Option>
                  <Option value="Faroe Islands">Faroe Islands</Option>
                  <Option value="Fiji">Fiji</Option>
                  <Option value="Finland">Finland</Option>
                  <Option value="France">France</Option>
                  <Option value="French Guiana">French Guiana</Option>
                  <Option value="French Polynesia">French Polynesia</Option>
                  <Option value="French Southern Territories">
                    French Southern Territories
                  </Option>
                  <Option value="Gabon">Gabon</Option>
                  <Option value="Gambia">Gambia</Option>
                  <Option value="Georgia">Georgia</Option>
                  <Option value="Germany">Germany</Option>
                  <Option value="Ghana">Ghana</Option>
                  <Option value="Gibraltar">Gibraltar</Option>
                  <Option value="Greece">Greece</Option>
                  <Option value="Greenland">Greenland</Option>
                  <Option value="Grenada">Grenada</Option>
                  <Option value="Guadeloupe">Guadeloupe</Option>
                  <Option value="Guam">Guam</Option>
                  <Option value="Guatemala">Guatemala</Option>
                  <Option value="Guernsey">Guernsey</Option>
                  <Option value="Guinea">Guinea</Option>
                  <Option value="Guinea-bissau">Guinea-bissau</Option>
                  <Option value="Guyana">Guyana</Option>
                  <Option value="Haiti">Haiti</Option>
                  <Option value="Heard Island and Mcdonald Islands">
                    Heard Island and Mcdonald Islands
                  </Option>
                  <Option value="Holy See (Vatican City State)">
                    Holy See (Vatican City State)
                  </Option>
                  <Option value="Honduras">Honduras</Option>
                  <Option value="Hong Kong">Hong Kong</Option>
                  <Option value="Hungary">Hungary</Option>
                  <Option value="Iceland">Iceland</Option>
                  <Option value="India">India</Option>
                  <Option value="Indonesia">Indonesia</Option>
                  <Option value="Iran, Islamic Republic of">
                    Iran, Islamic Republic of
                  </Option>
                  <Option value="Iraq">Iraq</Option>
                  <Option value="Ireland">Ireland</Option>
                  <Option value="Isle of Man">Isle of Man</Option>
                  <Option value="Israel">Israel</Option>
                  <Option value="Italy">Italy</Option>
                  <Option value="Jamaica">Jamaica</Option>
                  <Option value="Japan">Japan</Option>
                  <Option value="Jersey">Jersey</Option>
                  <Option value="Jordan">Jordan</Option>
                  <Option value="Kazakhstan">Kazakhstan</Option>
                  <Option value="Kenya">Kenya</Option>
                  <Option value="Kiribati">Kiribati</Option>
                  <Option value="Korea, Democratic People's Republic of">
                    Korea, Democratic People's Republic of
                  </Option>
                  <Option value="Korea, Republic of">Korea, Republic of</Option>
                  <Option value="Kuwait">Kuwait</Option>
                  <Option value="Kyrgyzstan">Kyrgyzstan</Option>
                  <Option value="Lao People's Democratic Republic">
                    Lao People's Democratic Republic
                  </Option>
                  <Option value="Latvia">Latvia</Option>
                  <Option value="Lebanon">Lebanon</Option>
                  <Option value="Lesotho">Lesotho</Option>
                  <Option value="Liberia">Liberia</Option>
                  <Option value="Libyan Arab Jamahiriya">
                    Libyan Arab Jamahiriya
                  </Option>
                  <Option value="Liechtenstein">Liechtenstein</Option>
                  <Option value="Lithuania">Lithuania</Option>
                  <Option value="Luxembourg">Luxembourg</Option>
                  <Option value="Macao">Macao</Option>
                  <Option value="Macedonia, The Former Yugoslav Republic of">
                    Macedonia, The Former Yugoslav Republic of
                  </Option>
                  <Option value="Madagascar">Madagascar</Option>
                  <Option value="Malawi">Malawi</Option>
                  <Option value="Malaysia">Malaysia</Option>
                  <Option value="Maldives">Maldives</Option>
                  <Option value="Mali">Mali</Option>
                  <Option value="Malta">Malta</Option>
                  <Option value="Marshall Islands">Marshall Islands</Option>
                  <Option value="Martinique">Martinique</Option>
                  <Option value="Mauritania">Mauritania</Option>
                  <Option value="Mauritius">Mauritius</Option>
                  <Option value="Mayotte">Mayotte</Option>
                  <Option value="Mexico">Mexico</Option>
                  <Option value="Micronesia, Federated States of">
                    Micronesia, Federated States of
                  </Option>
                  <Option value="Moldova, Republic of">
                    Moldova, Republic of
                  </Option>
                  <Option value="Monaco">Monaco</Option>
                  <Option value="Mongolia">Mongolia</Option>
                  <Option value="Montenegro">Montenegro</Option>
                  <Option value="Montserrat">Montserrat</Option>
                  <Option value="Morocco">Morocco</Option>
                  <Option value="Mozambique">Mozambique</Option>
                  <Option value="Myanmar">Myanmar</Option>
                  <Option value="Namibia">Namibia</Option>
                  <Option value="Nauru">Nauru</Option>
                  <Option value="Nepal">Nepal</Option>
                  <Option value="Netherlands">Netherlands</Option>
                  <Option value="Netherlands Antilles">
                    Netherlands Antilles
                  </Option>
                  <Option value="New Caledonia">New Caledonia</Option>
                  <Option value="New Zealand">New Zealand</Option>
                  <Option value="Nicaragua">Nicaragua</Option>
                  <Option value="Niger">Niger</Option>
                  <Option value="Nigeria">Nigeria</Option>
                  <Option value="Niue">Niue</Option>
                  <Option value="Norfolk Island">Norfolk Island</Option>
                  <Option value="Northern Mariana Islands">
                    Northern Mariana Islands
                  </Option>
                  <Option value="Norway">Norway</Option>
                  <Option value="Oman">Oman</Option>
                  <Option value="Pakistan">Pakistan</Option>
                  <Option value="Palau">Palau</Option>
                  <Option value="Palestinian Territory, Occupied">
                    Palestinian Territory, Occupied
                  </Option>
                  <Option value="Panama">Panama</Option>
                  <Option value="Papua New Guinea">Papua New Guinea</Option>
                  <Option value="Paraguay">Paraguay</Option>
                  <Option value="Peru">Peru</Option>
                  <Option value="Philippines">Philippines</Option>
                  <Option value="Pitcairn">Pitcairn</Option>
                  <Option value="Poland">Poland</Option>
                  <Option value="Portugal">Portugal</Option>
                  <Option value="Puerto Rico">Puerto Rico</Option>
                  <Option value="Qatar">Qatar</Option>
                  <Option value="Reunion">Reunion</Option>
                  <Option value="Romania">Romania</Option>
                  <Option value="Russian Federation">Russian Federation</Option>
                  <Option value="Rwanda">Rwanda</Option>
                  <Option value="Saint Helena">Saint Helena</Option>
                  <Option value="Saint Kitts and Nevis">
                    Saint Kitts and Nevis
                  </Option>
                  <Option value="Saint Lucia">Saint Lucia</Option>
                  <Option value="Saint Pierre and Miquelon">
                    Saint Pierre and Miquelon
                  </Option>
                  <Option value="Saint Vincent and The Grenadines">
                    Saint Vincent and The Grenadines
                  </Option>
                  <Option value="Samoa">Samoa</Option>
                  <Option value="San Marino">San Marino</Option>
                  <Option value="Sao Tome and Principe">
                    Sao Tome and Principe
                  </Option>
                  <Option value="Saudi Arabia">Saudi Arabia</Option>
                  <Option value="Senegal">Senegal</Option>
                  <Option value="Serbia">Serbia</Option>
                  <Option value="Seychelles">Seychelles</Option>
                  <Option value="Sierra Leone">Sierra Leone</Option>
                  <Option value="Singapore">Singapore</Option>
                  <Option value="Slovakia">Slovakia</Option>
                  <Option value="Slovenia">Slovenia</Option>
                  <Option value="Solomon Islands">Solomon Islands</Option>
                  <Option value="Somalia">Somalia</Option>
                  <Option value="South Africa">South Africa</Option>
                  <Option value="South Georgia and The South Sandwich Islands">
                    South Georgia and The South Sandwich Islands
                  </Option>
                  <Option value="Spain">Spain</Option>
                  <Option value="Sri Lanka">Sri Lanka</Option>
                  <Option value="Sudan">Sudan</Option>
                  <Option value="Suriname">Suriname</Option>
                  <Option value="Svalbard and Jan Mayen">
                    Svalbard and Jan Mayen
                  </Option>
                  <Option value="Swaziland">Swaziland</Option>
                  <Option value="Sweden">Sweden</Option>
                  <Option value="Switzerland">Switzerland</Option>
                  <Option value="Syrian Arab Republic">
                    Syrian Arab Republic
                  </Option>
                  <Option value="Taiwan, Province of China">
                    Taiwan, Province of China
                  </Option>
                  <Option value="Tajikistan">Tajikistan</Option>
                  <Option value="Tanzania, United Republic of">
                    Tanzania, United Republic of
                  </Option>
                  <Option value="Thailand">Thailand</Option>
                  <Option value="Timor-leste">Timor-leste</Option>
                  <Option value="Togo">Togo</Option>
                  <Option value="Tokelau">Tokelau</Option>
                  <Option value="Tonga">Tonga</Option>
                  <Option value="Trinidad and Tobago">
                    Trinidad and Tobago
                  </Option>
                  <Option value="Tunisia">Tunisia</Option>
                  <Option value="Turkey">Turkey</Option>
                  <Option value="Turkmenistan">Turkmenistan</Option>
                  <Option value="Turks and Caicos Islands">
                    Turks and Caicos Islands
                  </Option>
                  <Option value="Tuvalu">Tuvalu</Option>
                  <Option value="Uganda">Uganda</Option>
                  <Option value="Ukraine">Ukraine</Option>
                  <Option value="United Arab Emirates">
                    United Arab Emirates
                  </Option>
                  <Option value="United Kingdom">United Kingdom</Option>
                  <Option value="United States">United States</Option>
                  <Option value="United States Minor Outlying Islands">
                    United States Minor Outlying Islands
                  </Option>
                  <Option value="Uruguay">Uruguay</Option>
                  <Option value="Uzbekistan">Uzbekistan</Option>
                  <Option value="Vanuatu">Vanuatu</Option>
                  <Option value="Venezuela">Venezuela</Option>
                  <Option value="Viet Nam">Viet Nam</Option>
                  <Option value="Virgin Islands, British">
                    Virgin Islands, British
                  </Option>
                  <Option value="Virgin Islands, U.S.">
                    Virgin Islands, U.S.
                  </Option>
                  <Option value="Wallis and Futuna">Wallis and Futuna</Option>
                  <Option value="Western Sahara">Western Sahara</Option>
                  <Option value="Yemen">Yemen</Option>
                  <Option value="Zambia">Zambia</Option>
                  <Option value="Zimbabwe">Zimbabwe</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={
                  <div className="flex w-full justify-between items-center">
                    Business
                    <span style={{ fontSize: "inherit" }}>تجارة</span>
                  </div>
                }
                name="bussinessType"
              >
                <Input {...arabicAttributes} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={
                  <div className="flex w-full justify-between items-center">
                    Employer
                    <span style={{ fontSize: "inherit" }}>صاحب العمل</span>
                  </div>
                }
                name="employerType"
              >
                <Input {...arabicAttributes} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={
                  <div className="flex w-full justify-between items-center">
                    Permanent Address
                    <span style={{ fontSize: "inherit" }}>العنوان الدائم</span>
                  </div>
                }
                name="permanentAddress"
                rules={[
                  {
                    required: true,
                    message: "Permanent Address is required",
                  },
                ]}
              >
                <Input {...arabicAttributes} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={
                  <div className="flex w-full justify-between items-center">
                    Home Tel
                    <span style={{ fontSize: "inherit" }}>المنزل هاتف</span>
                  </div>
                }
                name="homeTel"
              >
                <Input {...arabicAttributes} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={
                  <div className="flex w-full justify-between items-center">
                    Office Tel
                    <span style={{ fontSize: "inherit" }}>هاتف المكتب</span>
                  </div>
                }
                name="officeTel"
              >
                <Input {...arabicAttributes} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={
                  <div className="flex w-full justify-between items-center">
                    Fax
                    <span style={{ fontSize: "inherit" }}>فاكس</span>
                  </div>
                }
                name="fax"
              >
                <Input {...arabicAttributes} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="emiratesId"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: "Emirates ID required",
                  },
                ]}
              >
                <Dragger
                  onRemove={(file) => {
                    const fileList = form.getFieldValue("emiratesId");
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                      fileList: newFileList,
                    };
                  }}
                  beforeUpload={(file) => {
                    form.setFieldsValue({ emiratesId: file });
                    return false;
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <IdcardFilled style={{ color: "#be9d40" }} />
                  </p>
                  <p className="ant-upload-hint">Select Emirates ID</p>
                </Dragger>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="passport"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Dragger
                  onRemove={(file) => {
                    const fileList = form.getFieldValue("passport");
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                      fileList: newFileList,
                    };
                  }}
                  beforeUpload={(file) => {
                    form.setFieldsValue({ passport: file });
                    return false;
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <IdcardOutlined style={{ color: "#be9d40" }} />
                  </p>
                  <p className="ant-upload-hint">Select Passport Copy</p>
                </Dragger>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="visa"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Dragger
                  onRemove={(file) => {
                    const fileList = form.getFieldValue("visa");
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                      fileList: newFileList,
                    };
                  }}
                  beforeUpload={(file) => {
                    form.setFieldsValue({ visa: file });
                    return false;
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <UploadOutlined style={{ color: "#be9d40" }} />
                  </p>
                  <p className="ant-upload-hint">Select Visa File</p>
                </Dragger>
              </Form.Item>
            </Col>
            <Col span={24} className="text-2xl">
              File Detail:
              <span style={{ fontSize: "inherit", marginLeft: "2rem" }}>
                تفاصيل الملف
              </span>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="fileNo"
                label={
                  <div className="flex w-full justify-between items-center">
                    File No
                    <span style={{ fontSize: "inherit" }}>رقم الملف</span>
                  </div>
                }
                rules={[
                  { required: true, message: "File No can not be empty" },
                ]}
              >
                <Select>
                  {userFile.map((file) => (
                    <Select.Option value={file.fileNo} key={file._id}>
                      {file.fileNoPrefix + " " + file.fileNo}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="clientType"
                label={
                  <div className="flex w-full justify-between items-center">
                    Client Type
                    <span style={{ fontSize: "inherit" }}>نوع العميل</span>
                  </div>
                }
                rules={[
                  { required: true, message: "Client Type can not be empty" },
                ]}
              >
                <Input {...arabicAttributes} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="lawyerName"
                label={
                  <div className="flex w-full justify-between items-center">
                    Lawyer
                    <span style={{ fontSize: "inherit" }}>محامي</span>
                  </div>
                }
              >
                <Input {...arabicAttributes} />
              </Form.Item>
            </Col>
            <Col span={24} className="text-2xl">
              Case Detail:
              <span style={{ fontSize: "inherit", marginLeft: "2rem" }}>
                تفاصيل القضية
              </span>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="stage"
                label={
                  <div className="flex w-full justify-between items-center">
                    Stage
                    <span style={{ fontSize: "inherit" }}>المسرح</span>
                  </div>
                }
              >
                <Input {...arabicAttributes} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="caseType"
                label={
                  <div className="flex w-full justify-between items-center">
                    Case Type
                    <span style={{ fontSize: "inherit" }}>نوع القضية</span>
                  </div>
                }
                rules={[
                  { required: true, message: "Case Type cannot be empty" },
                ]}
              >
                <Select placeholder="Select a Case">
                  {caseTypeOptions.map((option, i) => (
                    <Select.Option value={option.value} key={i}>
                      {option.level}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="poaType"
                label={
                  <div className="flex w-full justify-between items-center">
                    POA Type
                    <span style={{ fontSize: "inherit" }}>نوع التوكيل</span>
                  </div>
                }
              >
                <Input {...arabicAttributes} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="accusation"
                label={
                  <div className="flex w-full justify-between items-center">
                    Accusation
                    <span style={{ fontSize: "inherit" }}>تهمة</span>
                  </div>
                }
              >
                <Input {...arabicAttributes} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="opponentName"
                label={
                  <div className="flex w-full justify-between items-center">
                    Opponent Name
                    <span style={{ fontSize: "inherit" }}>الخصم</span>
                  </div>
                }
              >
                <Input {...arabicAttributes} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="opponentNationality"
                label={
                  <div className="flex w-full justify-between items-center">
                    Opponent Nationality
                    <span style={{ fontSize: "inherit" }}>الخصم</span>
                  </div>
                }
              >
                <Input {...arabicAttributes} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="opponentPhoneNumber"
                label={
                  <div className="flex w-full justify-between items-center">
                    Opponent Phone Number
                    <span style={{ fontSize: "inherit" }}>الخصم</span>
                  </div>
                }
              >
                <Input {...arabicAttributes} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="opponentEmail"
                label={
                  <div className="flex w-full justify-between items-center">
                    Opponent Email
                    <span style={{ fontSize: "inherit" }}>الخصم</span>
                  </div>
                }
                rules={[{ type: "email", message: "Email is not valid" }]}
              >
                <Input {...arabicAttributes} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="opponentAddress"
                label={
                  <div className="flex w-full justify-between items-center">
                    Opponent Address
                    <span style={{ fontSize: "inherit" }}>الخصم</span>
                  </div>
                }
              >
                <Input {...arabicAttributes} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="caseDescription"
                label={
                  <div className="flex w-full justify-between items-center">
                    Case Description
                    <span style={{ fontSize: "inherit" }}>وصف حالة</span>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "Case Description cannot be empty",
                  },
                ]}
              >
                <Input.TextArea rows={4} {...arabicAttributes} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="poaCopy"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Dragger
                  onRemove={(file) => {
                    const fileList = form.getFieldValue("poaCopy");
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                      fileList: newFileList,
                    };
                  }}
                  beforeUpload={(file) => {
                    form.setFieldsValue({ poaCopy: file });
                    return false;
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <FileTextOutlined style={{ color: "#be9d40" }} />
                  </p>
                  <p className="ant-upload-hint">Upload POA Copy</p>
                </Dragger>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="basicCaseEvidance"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Dragger
                  onRemove={(file) => {
                    const fileList = form.getFieldValue("basicCaseEvidance");
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                      fileList: newFileList,
                    };
                  }}
                  beforeUpload={(file) => {
                    form.setFieldsValue({ basicCaseEvidance: file });
                    return false;
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <Icon
                      component={balanceScaleSolidSVG}
                      style={{ color: "#be9d40" }}
                    />
                  </p>
                  <p className="ant-upload-hint">Upload Case Evidence</p>
                </Dragger>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="policy"
                valuePropName="checked"
                rules={[{ required: true, message: "Accept the Policy" }]}
              >
                <div className="flex">
                  <Checkbox />
                  <ul className="list-decimal ml-8 text-lg">
                    <li>
                      Kindly retain the original computer generated receipt for
                      any upcoming claims. If original receipt is lost, no
                      claims or reprint of the receipt will be entertained.
                    </li>
                    <li>
                      Claims or objections to be made in writing within 10 days
                      from the date of the invoice issued.
                    </li>
                    <li>
                      If no claims or objections are made within the previously
                      mentioned period in clause, then it shall be deemed as an
                      approval of consumer in relation to the provided
                      transaction and their values.
                    </li>
                    <li>
                      Documents for the transactions done to be forwarded along
                      with the original invoice.
                    </li>
                    <li>
                      The maximum liability in the event of any claims or
                      objections is limited to the value of the claimed
                      transactions, which is/are mentioned in the invoice.
                    </li>
                    <li>
                      BADER HAMAD AL ZAABI ADVOCATES &amp; LEGAL CONSULTANTS
                      reserve the right to review and aimed the above terms from
                      time as it judges fit, and as per the applicable laws.
                    </li>
                  </ul>
                </div>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item wrapperCol={{ xs: 24, md: { span: 2, offset: 22 } }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={formSubmitting}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Wrapper>
  );
}
