import React from "react";
import { Modal, Row, Col, Calendar, Button } from "antd";
import { ClockCircleFilled } from "@ant-design/icons";

import modalLogo from "../../assets/images/logo2.png";

function MeetingModal({
  meetingModal,
  setMeetingModal,
  setConfirmModal,
  title,
}) {
  return (
    <Modal
      closable={false}
      visible={meetingModal}
      footer={null}
      width="70vw"
      onCancel={() => setMeetingModal(false)}
      bodyStyle={{ padding: 0 }}
    >
      <Row className="modal-container">
        <Col xs={24} lg={9} className="desc">
          <div className="modal-logo">
            <img src={modalLogo} alt="Modal Logo" width="100%" />
          </div>
          <p>Sardar Muhammad</p>
          <h1>{title}</h1>
          <p>
            <ClockCircleFilled
              style={{
                fontSize: 18,
                color: "black",
                marginRight: 10,
              }}
            />
            30 min
          </p>
          <p style={{ margin: "10px 0" }}>
            Web conferencing detail provided upon confirmation
          </p>
          <p style={{ fontWeight: "lighter" }}>
            We are doing Our best to appointment
          </p>
        </Col>
        <Col
          xs={24}
          lg={15}
          style={{ padding: 20, display: "flex", flexWrap: "wrap" }}
        >
          <div className="site-calendar-demo-card">
            <h1>Select a Date & Time</h1>
            <Calendar
              fullscreen={false}
              // disabledDate={(currentDate) => {
              //   return currentDate && currentDate < moment().endOf('day')
              // }}
              onChange={(date) => {
                console.log(date.format("dddd, MMMM D"));
              }}
            />
            <Button
              className="confirm"
              type="primary"
              size="large"
              onClick={() => setConfirmModal(true)}
            >
              Confirm Meeting
            </Button>
          </div>
          <div className="site-date">
            <p>Monday, July 13</p>
            <ul>
              <li>12:00</li>
              <li>12:00</li>
              <li>12:00</li>
              <li className="active">12:00</li>
              <li>12:00</li>
              <li>12:00</li>
              <li>12:00</li>
              <li>12:00</li>
              <li>12:00</li>
            </ul>
          </div>
        </Col>
      </Row>
    </Modal>
  );
}

export default MeetingModal;
