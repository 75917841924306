import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Typography } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

const { Paragraph } = Typography;

export const meetingColumns = [
  {
    title: "Client",
    dataIndex: "client",
  },
  {
    title: "Case Type",
    dataIndex: "caseType",
  },
  {
    title: "Stage",
    dataIndex: "stage",
  },
  {
    title: "Topic",
    dataIndex: "topic",
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Time",
    dataIndex: "time",
  },
];

export const hearingColumns = [
  {
    title: "Case No.",
    dataIndex: "caseNo",
  },
  {
    title: "Case Type",
    dataIndex: "caseType",
  },
  {
    title: "Stage",
    dataIndex: "stage",
  },
  {
    title: "Topic",
    dataIndex: "topic",
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Time",
    dataIndex: "time",
  },
];

export const caseListColumns = [
  {
    title: "Status",
    render: (data) => {
      switch (data.caseStatus) {
        case "ACTIVE":
          return <p className="text-green-600">{data.caseStatus}</p>;
        case "PENDING":
        case "CLOSED":
          return (
            <p className="text-red-600 flex items-center justify-center">
              <ExclamationCircleFilled className="text-base mr-2" />
              {data.caseStatus}
            </p>
          );
        default:
          return <p>{data.caseStatus}</p>;
      }
    },
    align: "center",
  },
  {
    title: "Case No.",
    dataIndex: "caseNo",
    align: "center",
  },
  {
    title: "File No.",
    dataIndex: "fileNo",
    align: "center",
  },
  {
    title: "Case Type",
    dataIndex: "caseType",
    align: "center",
  },
  {
    title: "Stage",
    dataIndex: "stage",
    align: "center",
  },
  {
    title: "Name",
    render: (data) =>
      data.userProfile.firstName + " " + data.userProfile.lastName,
    align: "center",
  },
  {
    title: "Email",
    render: (data) => data.userProfile.email,
    align: "center",
  },
  {
    title: "Contact",
    render: (data) => data.userProfile.phoneNumber,
    align: "center",
  },
  {
    title: "Emirates ID",
    dataIndex: "emiratesIdNumber",
    align: "center",
  },
  {
    title: "Info",
    render: (data) => (
      <Link to={`/case-detail/${data._id}`}>
        <ExclamationCircleFilled style={{ fontSize: "1.2rem" }} />
      </Link>
    ),
    align: "center",
  },
];

export const calendarColumns = [
  {
    title: () => (
      <Paragraph strong style={{ minWidth: 110 }}>
        صاحب القرار <br /> Decision Maker
      </Paragraph>
    ),
    dataIndex: "decisionMaker",
    align: "center",
  },
  {
    title: () => (
      <Paragraph strong style={{ minWidth: 110 }}>
        الشخص المتابع للقضية <br /> Case Followers
      </Paragraph>
    ),
    dataIndex: "caseFollowers",
    align: "center",
  },
  {
    title: () => (
      <Paragraph strong style={{ minWidth: 150 }}>
        تاريخ الجلسة القادمة القرار <br /> Next Session Decision
      </Paragraph>
    ),
    dataIndex: "nextSessionDecision",
    align: "center",
  },
  {
    title: () => (
      <Paragraph strong style={{ minWidth: 50 }}>
        الطلبات <br /> Requests
      </Paragraph>
    ),
    dataIndex: "request",
    align: "center",
  },
  {
    title: () => (
      <Paragraph strong style={{ minWidth: 150 }}>
        قرار الجلسة السابقة <br /> Previous Session Decision
      </Paragraph>
    ),
    dataIndex: "prevSessionDecision",
    align: "center",
  },
  {
    title: () => (
      <Paragraph strong style={{ minWidth: 120 }}>
        اسم الخصم وصفته
        <br /> Defendant Name/Type
      </Paragraph>
    ),
    render: (record) => (
      <p>
        {record.defendantName}
        <br />
        {record.defendantType}
      </p>
    ),
    align: "center",
  },
  {
    title: () => (
      <Paragraph strong style={{ minWidth: 120 }}>
        اسم الموكل وصفته <br /> Client Name/Type
      </Paragraph>
    ),
    render: (record) => (
      <p>
        {record.clientName}
        <br />
        {record.clientType}
      </p>
    ),
    align: "center",
  },
  {
    title: () => (
      <Paragraph strong style={{ minWidth: 120 }}>
        رقم الدعوى ونوعها <br /> Case Number/Type
      </Paragraph>
    ),
    render: (record) => (
      <p>
        {record.caseNo}
        <br />
        {record.caseType}
      </p>
    ),
    align: "center",
  },
  {
    title: () => (
      <Paragraph strong style={{ minWidth: 120 }}>
        مكان الجلسة الدائرة/ا <br /> Location/Time
      </Paragraph>
    ),
    render: (record) => (
      <p>
        {record.locOfSession && record.locOfSession}
        <br />
        Room No {record.courtRoom}
        <br />
        {moment(record.nextSessionDate).format("MMMM Do YYYY, LTS")}
      </p>
    ),
    align: "center",
  },
  {
    title: () => (
      <Paragraph strong style={{ minWidth: 50 }}>
        رقم الملف <br /> File No.
      </Paragraph>
    ),
    dataIndex: "fileNo",
    align: "center",
  },
  {
    title: () => (
      <Paragraph strong>
        م <br /> Serial No.
      </Paragraph>
    ),
    render: (_text, _record, index) => index + 1,
    align: "center",
  },
];
