import React from "react";
import moment from "moment";
import QRCode from "qrcode.react";
import { Row, Col } from "antd";

import qrImage from "../../assets/images/qrcode-img.jpg";

const Invoiceview = React.forwardRef(({ data }, ref) => {
  const qrCodeValue = ` Case Invoice \n \n Name:${
    data.userProfile.firstName + " " + data.userProfile.lastName
  } \n Your email: ${data.userProfile.email} \n Your Contact: ${
    data.userProfile.phoneNumber
  } \n \n Invoice No: BZ/${data.invoiceNo} \n File No: BZ/${
    data.fileNo
  }\n Invoice Status:${data.status} \n Expiry Date:  ${moment(
    data.expireAt
  ).format("MMMM Do YYYY")}  \n Pending Amount: AED ${
    data.pendingAmount
  } \n Paid Amount: AED ${
    data.amountToBePaid - data.pendingAmount
  }  \n Total Amount: AED ${
    data.amountToBePaid
  }  \n \n Bader Al Zaabi Advocates Office \n Contact:+971 4 2555111 \n Email info@legalemirates.com \n Website: https://legalemirates.com/`;

  return (
    <div className="container" ref={ref}>
      <Row>
        <Col span={24}>
          <div className="invoice-le-view_area">
            <div className="invoice-preview__warning-message le-text--body"></div>
            <div className="invoice-previe-div-body-le">
              <div className="invoice-contemplary-body-inside">
                <section className="invoice-contemplary-body-inside__header">
                  <Row>
                    <Col xs={10} lg={12}>
                      <div className="preview-invoiece-top-area">
                        <center>
                          <div className="preview-invoiece-top-area-top"></div>
                          <p
                            style={{
                              fontSize: "34px",
                              color: "white",
                              marginTop: "40px",
                              marginBottom: "10px",
                            }}
                          >
                            INVOICE
                          </p>
                          <p
                            style={{
                              fontSize: "18px",
                              color: "white",
                              marginBottom: "0px",
                            }}
                          >
                            File No : BZ/
                            {data.fileNo && data.fileNo}
                          </p>
                          <p style={{ fontSize: "18px", color: "white" }}>
                            Invoice No : {data.fileNo && data.invoiceNo}
                          </p>
                        </center>
                      </div>
                    </Col>
                    <Col xs={14} lg={12}>
                      <div as="li" className="media-ride-list-invoice flex">
                        <div className="list-steps-of-ride-invoice">
                          <div className="invoice-contemplary-body-inside__header__logo">
                            <img
                              src="https://wave-prod-accounting.s3.amazonaws.com/uploads/invoices/business_logos/efcee799-07f1-4667-a72c-eaf2265ecbb7.png"
                              alt="logo"
                            />
                          </div>
                        </div>
                        <div className="ride-body-content">
                          <div className="contemporary-template__header__info">
                            <div
                              className="le-heading--title"
                              style={{ fontWeight: "550" }}
                            >
                              BADER HAMAD AL ZAABI
                            </div>
                            <strong className="le-text--strong">
                              ADVOCATES & LEGAL CONSULTANTS
                            </strong>
                            <div className="address-le-view-invoice fs-exclude">
                              <div className="address__field-invoice-view">
                                <p
                                  className="le-text le-text--body"
                                  style={{ fontWeight: "600" }}
                                >
                                  Office No. 3403 - 34th Floor - Burj Al Salam
                                  Tower - Trade Centre 1 - Sheikh Zayed Road -
                                  Dubai - United Arab Emirates - P.O. Box No.
                                  89498, Dubai. {/* TRN: 10037287460003 */}
                                  Tel: +9714 2555111, Fax: +9714 2368888 E-MAIL:
                                  info@legalemirates.com
                                </p>
                              </div>
                              <div className="address__field"></div>
                              <div className="address__field"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </section>

                <div className="contemporary-template__divider--full-width">
                  <div className="le-divider-top"></div>
                </div>

                <div className="description_of_card_of_invoicetable">
                  <div className="description_of_card_of_invoicetable_area">
                    <div className="contemporary-template__header__logo-invoice-view">
                      <Row style={{ width: "100%" }}>
                        <Col xs={10} sm={24} md={10}>
                          <div style={{ marginLeft: "0px" }}>
                            <h2 className="le-table-customer-data-heading">
                              <span>
                                <i className="fa fa-square box-gradient-icon-invoice-view"></i>
                                Client Details
                              </span>
                            </h2>
                            <div>
                              <table className="le-table--plain">
                                <tbody className="le-table__body">
                                  <tr className="le-table__row">
                                    <td className="le-table__cell" colSpan="1">
                                      <strong className="le-text--strong-invoice">
                                        {data.userProfile &&
                                          data.userProfile.firstName +
                                            " " +
                                            data.userProfile.lastName}
                                      </strong>
                                    </td>
                                  </tr>
                                  <tr className="le-table__row">
                                    <td className="le-table__cell" colSpan="1">
                                      <strong
                                        style={{ fontWeight: "600" }}
                                        className="le-text--invoice"
                                      >
                                        {data.userProfile &&
                                          data.userProfile.phoneNumber}
                                      </strong>
                                    </td>
                                  </tr>
                                  <tr className="le-table__row">
                                    <td className="le-table__cell" colSpan="1">
                                      <strong
                                        style={{ fontWeight: "600" }}
                                        className="le-text--invoice"
                                      >
                                        {data.userProfile &&
                                          data.userProfile.email}
                                      </strong>
                                    </td>
                                  </tr>

                                  <tr className="le-table__row">
                                    <td className="le-table__cell" colSpan="1">
                                      <strong
                                        style={{
                                          maxWidth: "24ch",
                                          fontWeight: "600",
                                        }}
                                        className="le-text--invoice"
                                      >
                                        {data.userProfile &&
                                          data.userProfile.address}
                                      </strong>
                                    </td>
                                  </tr>
                                  <tr className="le-table__row">
                                    <td className="le-table__cell" colSpan="1">
                                      <strong className="le-text--invoice">
                                        {/* {this.props.data.customer.email && this.props.data.customer.email} */}
                                      </strong>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>
                        <Col xs={4} sm={24} md={4}>
                          <div className="invoice-contemplary-body-inside__header__logo-inside-tale-customer">
                            {/* QR CODE HERE */}
                            <QRCode
                              value={qrCodeValue}
                              size={170}
                              imageSettings={{
                                src: qrImage,
                                height: 30,
                                width: 30,
                              }}
                            />
                          </div>
                        </Col>
                        <Col xs={10} sm={24} md={10}>
                          <center>
                            <div className="date-info-invoice-le">
                              <h2
                                style={{
                                  textAlign: "left",
                                  marginLeft: "75px",
                                }}
                                className="le-table-customer-data-heading"
                              >
                                <span>
                                  <i className="fa fa-square box-gradient-icon-invoice-view"></i>
                                  DATE & TIME
                                </span>
                              </h2>

                              <div>
                                <table className="le-table--plain">
                                  <tbody className="le-table__body">
                                    <tr className="le-table__row">
                                      <td
                                        className="le-table__cell"
                                        colSpan="1"
                                      >
                                        <strong className="le-text--issuedate-invoice">
                                          Issue date:{" "}
                                          {data.generatedAt &&
                                            moment(data.generatedAt).format(
                                              "MMMM Do YYYY"
                                            )}
                                        </strong>
                                      </td>
                                    </tr>
                                    <tr className="le-table__row">
                                      <td
                                        className="le-table__cell"
                                        colSpan="1"
                                      >
                                        <strong className="le-text--issuedate-invoice">
                                          Issue Time:{" "}
                                          {moment(
                                            new Date(data.expireAt)
                                          ).format("h:mm:ss a")}
                                        </strong>
                                      </td>
                                    </tr>
                                    <tr className="le-table__row">
                                      <td
                                        className="le-table__cell"
                                        colSpan="1"
                                      >
                                        <div
                                          style={{
                                            backgroundColor: "#BD9B3E",
                                            fontWeight: "550",
                                            padding: "5px",
                                            color: "white",
                                            marginTop: "15px",
                                            minWidth: "200px",
                                          }}
                                          className="le-text--issuedate-invoice"
                                        >
                                          <center>
                                            Due Date :{" "}
                                            {data.expireAt &&
                                              moment(data.expireAt).format(
                                                "MMMM Do YYYY"
                                              )}
                                          </center>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </center>
                        </Col>
                      </Row>
                    </div>

                    <Col span={24}>
                      <div className="contemporary-template__items-table">
                        <table className="le-table">
                          <thead
                            className="le-table__header"
                            style={{
                              backgroundColor: "rgb(189, 155, 62)",
                            }}
                          >
                            <tr className="le-table__row">
                              <th
                                className="le-table__cell--amount"
                                colSpan="1"
                                style={{ color: "rgb(255, 255, 255)" }}
                              >
                                Sr. No
                              </th>
                              <th
                                className="le-table__cell--amount"
                                colSpan="1"
                                style={{ color: "rgb(255, 255, 255)" }}
                              >
                                Type
                              </th>

                              <th
                                className="le-table__cell--amount class-table-particular"
                                colSpan="5"
                                style={{
                                  color: "rgb(255, 255, 255)",
                                  width: "335px",
                                }}
                              >
                                Description of Services
                              </th>

                              <th
                                className="le-table__cell--amount"
                                colSpan="1"
                                style={{ color: "rgb(255, 255, 255)" }}
                              >
                                Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.items &&
                              data.items.map((item, index) => {
                                return (
                                  <tr className="le-table__row" key={index}>
                                    <td className="le-table__cell" colSpan="1">
                                      <div className="no-items-message">
                                        <div
                                          className="le-text--emphasized"
                                          style={{
                                            fontWeight: "600",
                                            color: "#666666",
                                          }}
                                        >
                                          {index + 1}.
                                        </div>
                                      </div>
                                    </td>
                                    <td className="le-table__cell" colSpan="1">
                                      <div className="no-items-message">
                                        <div
                                          className="le-text--emphasized"
                                          style={{
                                            fontWeight: "600",
                                            color: "#666666",
                                          }}
                                        >
                                          {item.serviceType}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="le-table__cell" colSpan="5">
                                      <div className="no-items-message">
                                        <div
                                          className="le-text--emphasized"
                                          style={{
                                            fontWeight: "600",
                                            color: "#666666",
                                          }}
                                        >
                                          {item.detail}
                                        </div>
                                      </div>
                                    </td>

                                    <td className="le-table__cell" colSpan="1">
                                      <div className="no-items-message">
                                        <div
                                          className="le-text--emphasized"
                                          style={{
                                            fontWeight: "600",
                                            color: "#666666",
                                            textAlign: "right",
                                          }}
                                        >
                                          AED{" "}
                                          {new Intl.NumberFormat().format(
                                            item.amount
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </Col>

                    <div className="contemporary-template__divider--full-width contemporary-template__divider--bold">
                      <div className="le-divider-below"></div>
                    </div>

                    <Row className="how-to-work-grid">
                      <Col xs={12} sm={24} md={12}>
                        <div
                          style={{
                            marginLeft: "20px",
                            marginBottom: "20px",
                          }}
                        >
                          <h2 className="le-table-customer-data-heading-behind-table">
                            <span>
                              {/*  <i
                                      style={{
                                        fontSize: "18px",
                                        paddingRight: "5px",
                                      }}
                                      class="fa fa-square  box-gradient-icon-invoice-view"
                                    ></i> */}
                              {/*    Payment Method */}
                            </span>
                          </h2>

                          <div>
                            <table className="le-table--plain-behind-table">
                              <tbody className="le-table__body-behind-table">
                                <tr className="le-table__row-behind-table">
                                  <td className="le-table__cell-behind-table">
                                    <strong className="le-text--strong-invoice-behind-table">
                                      {/*   By Cash */}
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={24} md={12}>
                        <div className="contemporary-template__totals__amounts">
                          <div className="contemporary-template__totals__amounts__line">
                            <div className="contemporary-template__totals__amounts__line__label">
                              <strong className="le-text--strong-totals__amounts">
                                Sub Total:
                              </strong>
                            </div>
                            <div className="contemporary-template__totals__amounts__line__amount">
                              <span
                                className="le-text-totals__amounts le-text--body-totals__amounts"
                                style={{
                                  marginRight: "-20px",
                                  fontWeight: "600",
                                  color: "#000000",
                                }}
                              >
                                AED{" "}
                                {data.amountToBePaid &&
                                  new Intl.NumberFormat().format(
                                    data.amountToBePaid - data.vatIncluded
                                  )}
                              </span>
                            </div>
                          </div>
                          <div></div>

                          <div>
                            <div className="contemporary-template__totals__amounts__line">
                              <div className="contemporary-template__totals__amounts__line__label">
                                <strong className="le-text--strong-totals__amounts">
                                  VAT (5%):
                                </strong>
                              </div>
                              <div className="contemporary-template__totals__amounts__line__amount">
                                <strong
                                  className="le-text-totals__amounts le-text--body-totals__amounts"
                                  style={{
                                    marginRight: "-20px",
                                    fontWeight: "600",
                                    color: "#000000",
                                  }}
                                >
                                  AED{" "}
                                  {data.vatIncluded &&
                                    new Intl.NumberFormat().format(
                                      data.vatIncluded
                                    )}
                                </strong>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="contemporary-template__totals__amounts__line">
                              <div className="contemporary-template__totals__amounts__line__label">
                                <strong className="le-text--strong-totals__amounts">
                                  Discount:
                                </strong>
                              </div>
                              <div className="contemporary-template__totals__amounts__line__amount">
                                <strong
                                  className="le-text-totals__amounts le-text--body-totals__amounts"
                                  style={{
                                    marginRight: "-20px",
                                    fontWeight: "600",
                                    color: "#000000",
                                  }}
                                >
                                  AED{" "}
                                  {data.discount
                                    ? new Intl.NumberFormat().format(
                                        data.discount
                                      )
                                    : "0"}
                                </strong>
                              </div>
                            </div>
                          </div>

                          <div>
                            <div
                              className="contemporary-template__totals__amounts__line"
                              style={{ fontWeight: "600" }}
                            >
                              <div className="contemporary-template__totals__amounts__line__label">
                                <strong className="le-text--strong-totals__amounts">
                                  Outstanding Balance:
                                </strong>
                              </div>
                              <div className="contemporary-template__totals__amounts__line__amount">
                                <strong
                                  className="le-text-totals__amounts le-text--body-totals__amounts"
                                  style={{
                                    marginRight: "-20px",
                                    fontWeight: "600",
                                    color: "#000000",
                                  }}
                                >
                                  AED{" "}
                                  {data.amountToBePaid &&
                                    new Intl.NumberFormat().format(
                                      data.amountToBePaid
                                    )}
                                </strong>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="contemporary-template__divider--full-width-sign contemporary-template__divider--bold-sign">
                          <div className="le-divider-amount-small"></div>
                        </div>
                        {data.payments && data.payments.length > 0
                          ? data.payments.map((item) => {
                              return (
                                <div
                                  className="le-text-al-payment-amount-usingcash"
                                  key={item._id}
                                >
                                  <div
                                    className="le-text-al-payment-amount-usingcash"
                                    style={{
                                      fontWeight: "550",
                                      fontSize: "14px",
                                      color: "#000000",
                                    }}
                                  >
                                    Payment on{" "}
                                    {moment(item.createdAt).format(
                                      "MMMM Do YYYY"
                                    )}{" "}
                                    using {item.paymentType}:
                                  </div>
                                  <div
                                    className="text-authorized-payment-amount-usingcash"
                                    style={{
                                      marginRight: "10px",
                                      fontWeight: "550",
                                      fontSize: "14px",
                                      color: "#000000",
                                    }}
                                  >
                                    AED{" "}
                                    {new Intl.NumberFormat().format(
                                      item.paidAmount
                                    )}
                                  </div>
                                </div>
                              );
                            })
                          : ""}

                        <div className="amount-padding-color-price">
                          Remaining Amount : &nbsp; &nbsp;&nbsp; &nbsp;{" "}
                          <span style={{ marginRight: "8px", fontSize: 14 }}>
                            AED
                          </span>{" "}
                          {/* pendingAmount */}
                          <span style={{ marginRight: "-8px", fontSize: 14 }}>
                            {" "}
                            {data.pendingAmount &&
                              new Intl.NumberFormat().format(
                                data.pendingAmount
                              )}
                          </span>
                        </div>

                        {/* <div class="contemporary-template__divider--bold contemporary-template__divider--small-margin">
                <div class="le-divider">
                  </div>
                  </div> */}
                      </Col>
                    </Row>

                    <div className="contemporary-template__divider--full-width contemporary-template__divider--bold">
                      <div className="le-divider"></div>
                    </div>

                    <div className="description_of_card_of_blogpost2">
                      <div className="description_of_card_of_newsroom_area-blogpost2">
                        <h2 className="blogs-description-area-of-main-headings-blogpost2">
                          Terms & Conditions
                        </h2>

                        <p
                          style={{ fontWeight: "600", fontSize: "13px" }}
                          className="invoice-description-area-of-preview--text"
                        >
                          1. Kindly retain the original computer generated
                          receipt for any upcoming claims. If original receipt
                          is lost, no claims or reprint of the receipt will be
                          entertained.
                        </p>

                        <p
                          style={{ fontWeight: "600", fontSize: "13px" }}
                          className="invoice-description-area-of-preview--text"
                        >
                          2. If no claims or objections are made within the
                          previously mentioned period in clause, then it shall
                          be deemed as an approval of consumer in relation to
                          the provided transaction and their values.
                        </p>

                        <p
                          style={{ fontWeight: "600", fontSize: "13px" }}
                          className="invoice-description-area-of-preview--text"
                        >
                          3. Documents for the transactions done to be forwarded
                          along with the original invoice.
                        </p>
                        <p
                          style={{ fontWeight: "600", fontSize: "13px" }}
                          className="invoice-description-area-of-preview--text"
                        >
                          4. The maximum liability in the event of any claims or
                          objections is limited to the value of the claimed
                          transactions, which is/are mentioned in the invoice.
                        </p>

                        <p
                          style={{ fontWeight: "600", fontSize: "13px" }}
                          className="invoice-description-area-of-preview--text"
                        >
                          5. BADER HAMAD AL ZAABI ADVOCATES & LEGAL CONSULTANTS
                          reserve the right to review and aimed the above terms
                          from time as it judges fit, and as per the applicable
                          laws.
                        </p>
                        <p
                          style={{ fontWeight: "900", fontSize: "13px" }}
                          className="invoice-description-area-of-preview---belowfooter-text"
                        >
                          For Questions concerning this invoice, please contact
                          info@legalemirates.com | www.legalemirates.com
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default Invoiceview;
