import React, { Component } from "react";
import styled from "styled-components";
import { Table, Descriptions, AutoComplete } from "antd";

import { profileInstance } from "../utils/baseurl";

const Wrapper = styled.div`
  &
    .ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-radius: 25px;
  }
`;

const columns = [
  {
    title: "Name",
    render: (data) => `${data.firstName} ${data.lastName}`,
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "email",
    align: "center",
  },
  {
    title: "Mobile No",
    dataIndex: "mobileNo",
    align: "center",
  },
  {
    title: "Contact No",
    dataIndex: "contact",
    align: "center",
  },
  {
    title: "Description",
    dataIndex: "visitDescription",
    align: "center",
  },
];

const getVisitorsParams = (params) => ({
  limit: params.pagination?.pageSize,
  pageNo: params.pagination?.current,
  ...params,
});

const expandedRowRender = (record) => (
  <Descriptions bordered column={5} layout="vertical">
    <Descriptions.Item label="Visitor Type">
      {record.visitorType}
    </Descriptions.Item>
    <Descriptions.Item label="Job Title">{record.jobTitle}</Descriptions.Item>
    <Descriptions.Item label="Case No">{record.caseNo}</Descriptions.Item>
    <Descriptions.Item label="Purpose of visit">
      {record.purposeVisit}
    </Descriptions.Item>
    <Descriptions.Item label="File No">{record.fileNo}</Descriptions.Item>
  </Descriptions>
);

export default class Visitors extends Component {
  state = {
    data: [],
    loading: false,
    searchData: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchVisitors({ pagination });
  }

  fetchVisitors = async (params = {}) => {
    this.setState({ loading: true });
    try {
      const res = await profileInstance.get(
        "/profileService/api/v1/iPadPenal/visitor/visitorsData",
        {
          params: getVisitorsParams(params),
        }
      );
      this.setState({
        data: res.data.user.docs,
        loading: false,
        pagination: {
          current: res.data.user.page,
          pageSize: res.data.user.limit,
          total: res.data.user.totalDocs,
        },
      });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  };

  handleTableChange = (pagination) => {
    this.fetchVisitors({
      pagination,
    });
  };

  handleSearch = async (value) => {
    if (value.length === 0) {
      this.fetchVisitors();
      return;
    }
    try {
      const res = await profileInstance.get(
        `/profileService/api/v1/iPadPenal/visitor/visitorsData?search=${value}`
      );
      this.setState({ searchData: res.data.user.docs });
    } catch (error) {
      console.error(error);
    }
  };

  handleOnTapSearch = (_, { key }) => {
    this.fetchVisitors({ userId: key });
  };

  render() {
    const { data, loading, pagination, searchData } = this.state;
    return (
      <Wrapper className="container">
        <Table
          columns={columns}
          bordered
          rowKey={(record) => record._id}
          dataSource={data}
          scroll={{ x: true }}
          loading={loading}
          pagination={pagination}
          onChange={this.handleTableChange}
          expandable={{ expandedRowRender }}
          title={() => (
            <div className="flex justify-between items-center">
              <h1 className="m-0">Visitors</h1>
              <AutoComplete
                placeholder="search with name or phone num"
                style={{ width: 300 }}
                onSearch={this.handleSearch}
                onSelect={this.handleOnTapSearch}
              >
                {searchData.map((data) => (
                  <AutoComplete.Option
                    key={data._id}
                    value={data.firstName + " " + data.mobileNo}
                  >
                    {data.firstName + " " + data.mobileNo}
                  </AutoComplete.Option>
                ))}
              </AutoComplete>
            </div>
          )}
        />
      </Wrapper>
    );
  }
}
