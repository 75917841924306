import React from "react";
import styled from "styled-components";
import { Row, Col, Table, Typography, Button } from "antd";

const Wrapper = styled.div`
  & .ant-table-footer {
    display: flex;
  }
`;

const meetingColumns = [
  {
    title: "Client",
    dataIndex: "client",
  },
  {
    title: "Case Type",
    dataIndex: "caseType",
  },
  {
    title: "Stage",
    dataIndex: "stage",
  },
  {
    title: "Topic",
    dataIndex: "topic",
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Time",
    dataIndex: "time",
  },
];

const hearingColumns = [
  {
    title: "Case No.",
    dataIndex: "caseNo",
  },
  {
    title: "Case Type",
    dataIndex: "caseType",
  },
  {
    title: "Stage",
    dataIndex: "stage",
  },
  {
    title: "Topic",
    dataIndex: "topic",
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Time",
    dataIndex: "time",
  },
];

export default function Events() {
  return (
    <Wrapper className="container">
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Table
            columns={meetingColumns}
            scroll={{ x: true }}
            title={() => (
              <Typography.Title level={4}>Upcoming Meetings</Typography.Title>
            )}
            footer={() => (
              <div className="ml-auto">
                <Button shape="round" className="mr-4">
                  Show all
                </Button>
                <Button type="primary" shape="round">
                  History
                </Button>
              </div>
            )}
          />
        </Col>
        <Col xs={24} md={12} className="mt-4 md:mt-0">
          <Table
            columns={hearingColumns}
            scroll={{ x: true }}
            title={() => (
              <Typography.Title level={4}>Upcoming Hearing</Typography.Title>
            )}
            footer={() => (
              <div className="ml-auto">
                <Button shape="round" className="mr-4">
                  Show all
                </Button>
                <Button type="primary" shape="round">
                  History
                </Button>
              </div>
            )}
          />
        </Col>
      </Row>
    </Wrapper>
  );
}
