import React from "react";
import { Breadcrumb } from "antd";

import {
  File,
  FreeTimeCounselling,
  PaidCounselling,
  WritingCounselling,
  OpenNewCase,
  Login,
  Chat,
  Calendar,
  Cases,
  FileInvoices,
  CaseDetail,
  ViewFileInvoice,
  Visitors,
  CaseInvoices,
  Events,
  BankReceipts,
  PaymentCheques,
  Lawyers,
  AssignConsultant,
  OpenFile,
} from "./components";

const routes = [
  {
    path: "/",
    exact: true,
    breadCrumb: () => (
      <Breadcrumb style={{ margin: "16px 0", fontSize: "14px" }}>
        <Breadcrumb.Item style={{ fontSize: "14px" }}>
          Founder & Law Panel
        </Breadcrumb.Item>
        <Breadcrumb.Item style={{ fontSize: "14px" }}>
          Open a File
        </Breadcrumb.Item>
      </Breadcrumb>
    ),
    main: () => <File />,
  },
  {
    path: "/login",
    main: () => <Login />,
  },
  {
    title: "Free Time Counselling",
    path: "/free-time-counselling",
    breadCrumb: () => (
      <Breadcrumb style={{ margin: "16px 0", fontSize: "14px" }}>
        <Breadcrumb.Item style={{ fontSize: "14px" }}>
          Founder & Law Panel
        </Breadcrumb.Item>
        <Breadcrumb.Item style={{ fontSize: "14px" }}>
          Free On Time Counselling
        </Breadcrumb.Item>
      </Breadcrumb>
    ),
    main: () => <FreeTimeCounselling />,
  },
  {
    title: "Paid Counselling",
    path: "/paid-counselling",
    breadCrumb: () => (
      <Breadcrumb style={{ margin: "16px 0", fontSize: "14px" }}>
        <Breadcrumb.Item style={{ fontSize: "14px" }}>
          Founder & Law Panel
        </Breadcrumb.Item>
        <Breadcrumb.Item style={{ fontSize: "14px" }}>
          Paid Counselling
        </Breadcrumb.Item>
      </Breadcrumb>
    ),
    main: () => <PaidCounselling />,
  },
  {
    title: "Writing Counselling",
    path: "/writing-counselling",
    breadCrumb: () => (
      <Breadcrumb style={{ margin: "16px 0", fontSize: "14px" }}>
        <Breadcrumb.Item style={{ fontSize: "14px" }}>
          Founder & Law Panel
        </Breadcrumb.Item>
        <Breadcrumb.Item style={{ fontSize: "14px" }}>
          Writing Counselling
        </Breadcrumb.Item>
      </Breadcrumb>
    ),
    main: () => <WritingCounselling />,
  },
  {
    title: "Open New Case",
    path: "/open-new-case",
    breadCrumb: () => (
      <Breadcrumb style={{ margin: "16px 0", fontSize: "14px" }}>
        <Breadcrumb.Item style={{ fontSize: "14px" }}>
          Founder & Law Panel
        </Breadcrumb.Item>
        <Breadcrumb.Item style={{ fontSize: "14px" }}>
          Open Cases
        </Breadcrumb.Item>
        <Breadcrumb.Item style={{ fontSize: "14px" }}>
          Open New Case
        </Breadcrumb.Item>
      </Breadcrumb>
    ),
    main: () => <OpenNewCase />,
  },
  {
    title: "Case Invoices",
    path: "/case-invoices",
    main: () => <CaseInvoices />,
  },
  {
    title: "Chat",
    path: "/chat",
    breadCrumb: () => (
      <Breadcrumb style={{ margin: "16px 0", fontSize: "14px" }}>
        <Breadcrumb.Item style={{ fontSize: "14px" }}>
          Founder & Law Panel
        </Breadcrumb.Item>
        <Breadcrumb.Item style={{ fontSize: "14px" }}>
          Live Chatting
        </Breadcrumb.Item>
      </Breadcrumb>
    ),
    main: () => <Chat />,
  },
  {
    title: "Calendar",
    path: "/calendar",
    main: () => <Calendar />,
  },
  {
    title: "All Cases",
    path: "/cases",
    main: () => <Cases />,
  },
  {
    title: "File Invoices",
    path: "/file-invoices",
    main: () => <FileInvoices />,
  },
  {
    path: "/case-detail/:id/assign",
    main: (props) => <AssignConsultant {...props} />,
  },
  {
    path: "/case-detail/:id",
    main: (props) => <CaseDetail {...props} />,
  },
  {
    path: "/view-file-invoice",
    main: (props) => <ViewFileInvoice {...props} />,
  },
  {
    title: "Visitors",
    path: "/visitors",
    main: () => <Visitors />,
  },
  {
    title: "Events",
    path: "/events",
    main: () => <Events />,
  },
  {
    title: "Bank Receipts",
    path: "/bank-receipts",
    main: () => <BankReceipts />,
  },
  {
    title: "Payment Cheques",
    path: "/payment-cheques",
    main: () => <PaymentCheques />,
  },
  {
    title: "Lawyers",
    path: "/lawyers",
    main: () => <Lawyers />,
  },
  {
    title: "Open File",
    path: "/open-file",
    main: () => <OpenFile />,
  },
];

export default routes;
