import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Modal, Popconfirm } from "antd";
import { CheckCircleOutlined, FilePdfOutlined } from "@ant-design/icons";

import { fileInstance } from "../../utils/baseurl";
import PrintInvoiceFile from "./invoiecFIleView";

export default function ActionPopover({ data, fetchInvoices, page }) {
  const [printModal, setPrintModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const ref = React.createRef();

  const sendFileInvoice = async () => {
    try {
      await fileInstance.post(
        "/fileService/lawyerRoutes/api/v1/fileInvioces/sendFileInvoice",
        {
          invoiceId: data._id,
        }
      );
      setConfirmModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const recordPayment = async () => {
    try {
      await fileInstance.post(
        "/fileService/lawyerRoutes/api/v1/files/recordPaymentForFile",
        {
          fileId: data.fileId,
        }
      );
      fetchInvoices({ pagination: page });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Link to={{ pathname: "/view-file-invoice", state: data }}>View</Link>
      <p className="cursor-pointer" onClick={() => setPrintModal(true)}>
        Print PDF
      </p>
      <Popconfirm
        title="Are you sure to send this Open File Invoice to Client?"
        onConfirm={sendFileInvoice}
        okText="Send"
        cancelText="No"
        placement="left"
      >
        <p className="cursor-pointer">Send file invoice</p>
      </Popconfirm>
      {data.invoiceStatus === "UNPAID" && (
        <p className="cursor-pointer" onClick={recordPayment}>
          Record Payment
        </p>
      )}
      <Modal
        centered
        width={300}
        visible={printModal}
        footer={null}
        title={`Invoice Number# ${data.invoiceNo}`}
        onCancel={() => setPrintModal(false)}
        bodyStyle={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <FilePdfOutlined style={{ fontSize: "5rem" }} />
        <ReactToPrint
          trigger={() => (
            <button className="bg-gold px-16 py-2 text-lg text-white mt-3">
              Print
            </button>
          )}
          content={() => ref.current}
        />
        <div style={{ display: "none" }}>
          <PrintInvoiceFile ref={ref} data={data} />
        </div>
      </Modal>
      <Modal
        centered
        width={300}
        visible={confirmModal}
        footer={null}
        onCancel={() => setConfirmModal(false)}
        bodyStyle={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <CheckCircleOutlined style={{ fontSize: "5rem" }} />
        <h1 className="text-3xl mt-3">Thank You!</h1>
        <p className="text-center text-lg">
          The Invoice has been successfully send to the Customer email
        </p>
        <button
          className="bg-gold px-16 py-2 text-lg text-white mt-3"
          onClick={() => setConfirmModal(false)}
        >
          Ok
        </button>
      </Modal>
    </div>
  );
}
