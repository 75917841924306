import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { setCurrentUser } from "./store/actions/authActions";
import "./assets/css/main.css";
import "react-phone-input-2/lib/style.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./store/store";

if (localStorage.token) {
  store.dispatch(setCurrentUser(localStorage.token));
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
