import { profileInstance } from "../../utils/baseurl";
import { SET_CURRENT_USER, SET_LOADING, SET_ALL_USERS } from "../actionTypes";
import setAuthToken from "../../utils/setAuthToken";

export const setCurrentUser = (token) => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  setAuthToken(token);
  try {
    const res = await profileInstance.get(
      "/profileService/api/v1/users/findMe"
    );
    dispatch({ type: SET_CURRENT_USER, payload: res.data.user });
    dispatch({ type: SET_LOADING, payload: false });
  } catch (error) {
    console.error(error);
    dispatch({ type: SET_LOADING, payload: false });
  }
};

export const getAllUsers = () => async (dispatch) => {
  try {
    const res = await profileInstance.get(
      "/profileService/api/v1/lawyer/userData/getUsers"
    );
    dispatch({ type: SET_ALL_USERS, payload: res.data.users });
  } catch (error) {
    console.error(error);
  }
};
