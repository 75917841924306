import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route, useLocation } from "react-router-dom";
import { Layout } from "antd";

import { SideBar, NavBar } from "./";
import routes from "../routes";
import { getAllUsers } from "../store/actions/authActions";

const { Content } = Layout;

function Dashboard() {
  const dispatch = useDispatch();
  const location = useLocation();

  let styles = {
    padding: 24,
    margin: 0,
  };
  if (location.pathname === "/calendar") {
    styles = { padding: "24px 0", margin: 0 };
  }

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  return (
    <>
      <NavBar />
      <Layout>
        <SideBar />
        <Content className="site-layout-background" style={styles}>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={(props) => <route.main {...props} />}
              />
            ))}
          </Switch>
        </Content>
      </Layout>
    </>
  );
}

export default Dashboard;
