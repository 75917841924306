import React, { useState } from 'react'
import { Row, Col } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

import MeetingModal from './MeetingModal'
import ConfirmModal from './ConfirmModal'
import BreadCrumb from '../BreadCrumb'

function PaidCounselling() {
  const [meetingModal, setMeetingModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)

  return (
    <>
      <div className='container'>
        <Row
          style={{
            border: "1px solid",
            backgroundColor: 'white',
            color: 'black',
            fontWeight: 'bold'
          }}
        >
          <Col span={24} style={{
            borderBottom: '1px solid',
            padding: '0 24px',
            display: "flex",
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <BreadCrumb />
            <div
              style={{ display: 'flex', fontWeight: "lighter", cursor: 'pointer' }}
              onClick={() => setMeetingModal(true)}
            >
              Add New
            <PlusCircleOutlined style={{ fontSize: 24, marginLeft: 10 }} />
            </div>
          </Col>
          <Col xs={24} md={8} style={{ padding: 20 }}>
            <div className='file-timeline'>
              <h4>Order No._______</h4>
              <p>Name: Sardar Muhammad</p>
              <p>ID Card Number: 784-1992-6026803-4</p>
              <p>Phone No: +971 55 568 4217</p>
              <div className='link'>
                <span style={{ color: "#1DBF73" }}>Schedule meeting...</span>
                <span style={{ color: '#999999' }}>07-01-2020</span>
              </div>
            </div>
          </Col>
          <Col xs={24} md={8} style={{ padding: 20 }}>
            <div className='file-timeline'>
              <h4>Order No._______</h4>
              <p>Name: Sardar Muhammad</p>
              <p>ID Card Number: 784-1992-6026803-4</p>
              <p>Phone No: +971 55 568 4217</p>
              <div className='link'>
                <span style={{ color: "#1DBF73" }}>Schedule meeting...</span>
                <span style={{ color: '#999999' }}>07-01-2020</span>
              </div>
            </div>
          </Col>
          <Col xs={24} md={8} style={{ padding: 20 }}>
            <div className='file-timeline'>
              <h4>Order No._______</h4>
              <p>Name: Sardar Muhammad</p>
              <p>ID Card Number: 784-1992-6026803-4</p>
              <p>Phone No: +971 55 568 4217</p>
              <div className='link'>
                <span style={{ color: "#1DBF73" }}>Schedule meeting...</span>
                <span style={{ color: '#999999' }}>07-01-2020</span>
              </div>
            </div>
          </Col>
        </Row>
        <Row
          style={{
            border: "1px solid",
            backgroundColor: 'white',
            color: 'black',
            fontWeight: 'bold',
            borderTop: 'none'
          }}
        >
          <Col xs={24} md={8} style={{ padding: 20 }}>
            <div className='file-timeline'>
              <h4>Order No._______</h4>
              <p>Name: Sardar Muhammad</p>
              <p>ID Card Number: 784-1992-6026803-4</p>
              <p>Phone No: +971 55 568 4217</p>
              <div className='link'>
                <span style={{ color: "#1DBF73" }}>Schedule meeting...</span>
                <span style={{ color: '#999999' }}>07-01-2020</span>
              </div>
            </div>
          </Col>
          <Col xs={24} md={8} style={{ padding: 20 }}>
            <div className='file-timeline'>
              <h4>Order No._______</h4>
              <p>Name: Sardar Muhammad</p>
              <p>ID Card Number: 784-1992-6026803-4</p>
              <p>Phone No: +971 55 568 4217</p>
              <div className='link'>
                <span style={{ color: "#1DBF73" }}>Schedule meeting...</span>
                <span style={{ color: '#999999' }}>07-01-2020</span>
              </div>
            </div>
          </Col>
          <Col xs={24} md={8} style={{ padding: 20 }}>
            <div className='file-timeline'>
              <h4>Order No._______</h4>
              <p>Name: Sardar Muhammad</p>
              <p>ID Card Number: 784-1992-6026803-4</p>
              <p>Phone No: +971 55 568 4217</p>
              <div className='link'>
                <span style={{ color: "#1DBF73" }}>Schedule meeting...</span>
                <span style={{ color: '#999999' }}>07-01-2020</span>
              </div>
            </div>
          </Col>
        </Row>

        <Row
          style={{
            border: "1px solid",
            marginTop: '5em',
            backgroundColor: 'white',
            color: 'black',
            fontWeight: 'bold'
          }}
        >
          <Col span={24} style={{ borderBottom: '1px solid', padding: '15px 24px' }}>
            <h4 style={{ margin: 0, fontWeight: 'bold' }}>Schedule Meeting</h4>
          </Col>
          <Col xs={24} md={8} style={{ padding: 20 }}>
            <div className='file-timeline'>
              <h4>Order No._______</h4>
              <p>Name: Sardar Muhammad</p>
              <p>ID Card Number: 784-1992-6026803-4</p>
              <p>Phone No: +971 55 568 4217</p>
              <div className='link'>
                <span style={{ color: "#1DBF73" }}>Click this link:</span>
                <span style={{ color: '#999999' }}>07-01-2020</span>
              </div>
              <p style={{ color: '#1036FC' }}>https://meet.google.com/bqx-msrr-wqx/wqx?hs=5</p>
            </div>
          </Col>
          <Col xs={24} md={8} style={{ padding: 20 }}>
            <div className='file-timeline'>
              <h4>Order No._______</h4>
              <p>Name: Sardar Muhammad</p>
              <p>ID Card Number: 784-1992-6026803-4</p>
              <p>Phone No: +971 55 568 4217</p>
              <div className='link'>
                <span style={{ color: "#1DBF73" }}>Click this link:</span>
                <span style={{ color: '#999999' }}>07-01-2020</span>
              </div>
              <p style={{ color: '#1036FC' }}>https://meet.google.com/bqx-msrr-wqx/wqx?hs=5</p>
            </div>
          </Col>
          <Col xs={24} md={8} style={{ padding: 20 }}>
            <div className='file-timeline'>
              <h4>Order No._______</h4>
              <p>Name: Sardar Muhammad</p>
              <p>ID Card Number: 784-1992-6026803-4</p>
              <p>Phone No: +971 55 568 4217</p>
              <div className='link'>
                <span style={{ color: "#1DBF73" }}>Click this link:</span>
                <span style={{ color: '#999999' }}>07-01-2020</span>
              </div>
              <p style={{ color: '#1036FC' }}>https://meet.google.com/bqx-msrr-wqx/wqx?hs=5</p>
            </div>
          </Col>
        </Row>
      </div>

      <MeetingModal
        title='Paid Counselling'
        meetingModal={meetingModal}
        setMeetingModal={setMeetingModal}
        setConfirmModal={setConfirmModal}
      />

      <ConfirmModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
      />
    </>
  )
}

export default PaidCounselling
