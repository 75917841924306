import { profileInstance, fileInstance } from './baseurl'

export default function setAuthToken(token) {
  if (token) {
    profileInstance.defaults.headers.common["Authorization"] = `bearer ${token}`;
    fileInstance.defaults.headers.common["Authorization"] = `bearer ${token}`;

  } else {
    delete profileInstance.defaults.headers.common["Authorization"];
    delete fileInstance.defaults.headers.common["Authorization"];
  }
}