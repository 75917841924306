import React, { Component } from "react";
import moment from "moment";
import styled from "styled-components";
import { Button, Table, Typography, DatePicker } from "antd";

import { fileInstance } from "../../utils/baseurl";
import { calendarColumns } from "./data";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2em;

  & .ant-btn {
    /* background-color: #222222; */
    /* color: white; */
    padding: 0px 30px;
    height: 50px;
  }

  & .ant-btn span {
    font-size: 1.25rem;
  }
`;

const TableTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > h4 {
    color: white;
    margin: 0;
  }

  & input {
    color: white;
  }

  & .anticon-calendar {
    color: white;
    font-size: 16px;
  }
`;

const getCaseSessionsParams = (params) => {
  return {
    limit: params.pagination?.pageSize,
    pageNo: params.pagination?.current,
    ...params,
  };
};

export default class Calendar extends Component {
  state = {
    data: [],
    loading: false,
    selected: "day",
    pagination: {
      current: 1,
      pageSize: 10,
    },
  };

  componentDidMount() {
    const { pagination } = this.state;
    const startDate = moment().startOf("day").toISOString();
    const endDate = moment().endOf("day").toISOString();
    this.caseSessions({ pagination, startDate, endDate });
  }

  caseSessions = async (params = {}) => {
    this.setState({ loading: true });
    try {
      const res = await fileInstance.get(
        "/fileService/lawyerRoutes/api/v1/caseSessions/getCaseSessionsWithPagination",
        {
          params: getCaseSessionsParams(params),
        }
      );
      this.setState({
        data: res.data.caseSessions.docs,
        loading: false,
        pagination: {
          current: res.data.caseSessions.page,
          pageSize: res.data.caseSessions.limit,
          total: res.data.caseSessions.totalDocs,
        },
      });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  };

  handleTableChange = (pagination) => {
    this.caseSessions({
      pagination,
    });
  };

  handleCaseSessionSelection = (selected) => {
    this.setState({ selected });

    this.caseSessions({
      startDate: moment().startOf(selected).toISOString(),
      endDate: moment().endOf(selected).toISOString(),
    });
  };

  render() {
    const { pagination, data, loading, selected } = this.state;
    return (
      <div className="case-management">
        <ButtonsWrapper>
          <div>
            <Button
              shape="round"
              className="mr-4"
              type={selected === "day" ? "default" : "primary"}
              onClick={() => this.handleCaseSessionSelection("day")}
            >
              <span>Day</span>
            </Button>
            <Button
              shape="round"
              className="mr-4"
              type={selected === "week" ? "default" : "primary"}
              onClick={() => this.handleCaseSessionSelection("week")}
            >
              <span>Week</span>
            </Button>
            <Button
              shape="round"
              className="mr-4"
              type={selected === "month" ? "default" : "primary"}
              onClick={() => this.handleCaseSessionSelection("month")}
            >
              <span>Month</span>
            </Button>
            <Button
              shape="round"
              type={selected === "year" ? "default" : "primary"}
              onClick={() => this.handleCaseSessionSelection("year")}
            >
              <span>Year</span>
            </Button>
          </div>
          <Button
            type="primary"
            shape="round"
            href="https://www.dc.gov.ae/PublicServices/SessionAgenda.aspx?lang=ar-AE"
            target="_blank"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span>View from official site</span>
          </Button>
        </ButtonsWrapper>
        <Table
          bordered
          columns={calendarColumns}
          rowKey={(record) => record._id}
          dataSource={data}
          loading={loading}
          pagination={pagination}
          onChange={this.handleTableChange}
          title={() => (
            <TableTitleWrapper>
              <Title level={4}>Sessions Roll</Title>
              <RangePicker
                bordered={false}
                onChange={(dates) => {
                  if (dates) {
                    this.caseSessions({
                      startDate: moment(dates[0]).toISOString(),
                      endDate: moment(dates[1]).toISOString(),
                    });
                  }
                  this.caseSessions();
                }}
              />
            </TableTitleWrapper>
          )}
        />
      </div>
    );
  }
}
