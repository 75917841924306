import React from "react";
import { useHistory } from "react-router-dom";
import { Modal, Divider } from "antd";

import acceptImg from "../../assets/images/accept.png";
import canceledImg from "../../assets/images/canceled.png";

export default function CaseActionModal({
  caseActionModal,
  setCaseActionModal,
  accepted,
  caseDetail,
}) {
  const history = useHistory();
  return (
    <Modal
      visible={caseActionModal}
      onCancel={() => setCaseActionModal(false)}
      closable={false}
      footer={null}
      bodyStyle={{ padding: 0 }}
      centered
    >
      <div className="h-32 bg-gray-800 flex justify-center py-4">
        <img
          src={accepted ? acceptImg : canceledImg}
          alt="accepted"
          className="max-h-full"
        />
      </div>
      <h1
        className={`text-2xl font-bold text-center mt-4 ${
          accepted ? "text-green-700" : "text-red-500 "
        }`}
      >
        {accepted ? "Accepted" : "Rejected!"}
      </h1>
      {accepted ? (
        <p className="text-center">
          Case Number {caseDetail && caseDetail.caseNo} has been Successfully
          Opened
        </p>
      ) : (
        <p className="text-center">
          Case Number {caseDetail && caseDetail.caseNo} has been Closed
        </p>
      )}
      <Divider />
      <div className="flex justify-center pb-6">
        <button
          className="border py-1 px-10 rounded-full"
          onClick={() => {
            setCaseActionModal(false);
            if (accepted) {
              history.push(`/case-detail/${caseDetail._id}/assign`);
            }
          }}
        >
          OK
        </button>
      </div>
    </Modal>
  );
}
