import React from "react";
import moment from "moment";
import QRCode from "qrcode.react";
import { Row, Col } from "antd";

import qrImage from "../../assets/images/qrcode-img.jpg";

const InvoiceFileView = React.forwardRef(({ data }, ref) => {
  const qrCodeValue = ` Case Invoice \n \n Name:${
    data.userProfile.firstName + " " + data.userProfile.lastName
  } \n Your email: ${data.userProfile.email} \n Your Contact: ${
    data.userProfile.phoneNumber
  } \n \n Invoice No: BZ/${data.invoiceNo} \n Status:${
    data.invoiceStatus
  } \n Opening Date:  ${moment(data.createdAt).format(
    "MMMM Do YYYY"
  )} \n Total Amount: AED ${
    data.amountToBePaid
  }  \n \n Bader Al Zaabi Advocates Office \n Contact:+971 4 2555111 \n Email info@legalemirates.com \n Website: https://legalemirates.com/`;

  return (
    <div className="container" ref={ref}>
      <Row>
        <Col span={24}>
          <div className="invoice-le-view_area">
            <div className="invoice-previe-div-body-le">
              <div className="invoice-contemplary-body-inside">
                <section className="invoice-contemplary-body-inside__header">
                  <Row>
                    <Col xs={14} lg={12}>
                      <div as="li" className="media-ride-list-invoice flex">
                        <div className="list-steps-of-ride-invoice">
                          <div className="invoice-contemplary-body-inside__header__logo">
                            <img
                              src="https://wave-prod-accounting.s3.amazonaws.com/uploads/invoices/business_logos/efcee799-07f1-4667-a72c-eaf2265ecbb7.png"
                              alt="logo"
                            />
                          </div>
                        </div>
                        <div className="ride-body-content">
                          <div className="contemporary-template__header__info">
                            <div
                              className="le-heading--title"
                              style={{ fontWeight: "550" }}
                            >
                              BADER HAMAD AL ZAABI
                            </div>
                            <strong className="le-text--strong">
                              ADVOCATES & LEGAL CONSULTANTS
                            </strong>
                            <div className="address-le-view-invoice fs-exclude">
                              <div className="address__field-invoice-view">
                                <p
                                  className="le-text le-text--body"
                                  style={{ fontWeight: "900" }}
                                >
                                  Office No. 3403 - 34th Floor - Burj Al Salam
                                  Tower - Trade Centre 1 - Sheikh Zayed Road -
                                  Dubai - United Arab Emirates - P.O. Box No.
                                  89498, Dubai. Tel: +9714 2555111, Fax: +9714
                                  2368888 E-MAIL: info@legalemirates.com
                                </p>
                              </div>
                              <div className="address__field"></div>
                              <div className="address__field"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={10} lg={12}>
                      <div className="preview-invoiece-top-area">
                        <center>
                          <div className="preview-invoiece-top-area-top"></div>
                          <p
                            style={{
                              fontSize: "34px",
                              color: "white",
                              marginTop: "40px",
                              marginBottom: "10px",
                            }}
                          >
                            File Invoice
                          </p>
                          <p
                            style={{
                              fontSize: "18px",
                              color: "white",
                              marginBottom: "0px",
                            }}
                          >
                            Invoice No : BZ/
                            {data.invoiceNo}
                          </p>
                        </center>
                      </div>
                    </Col>
                  </Row>
                </section>
                <div className="contemporary-template__divider--full-width">
                  <div className="le-divider-top"></div>
                </div>
                <div className="description_of_card_of_invoicetable">
                  <div className="description_of_card_of_invoicetable_area">
                    <div className="contemporary-template__header__logo-invoice-view">
                      <Row style={{ width: "100%" }}>
                        <Col xs={10} sm={24} md={10}>
                          <div style={{ marginLeft: "0px" }}>
                            <h2 className="le-table-customer-data-heading">
                              <i className="fa fa-square box-gradient-icon-invoice-view"></i>
                              Client Details
                            </h2>
                            <div>
                              <table className="le-table--plain">
                                <tbody className="le-table__body">
                                  <tr className="le-table__row">
                                    <td className="le-table__cell" colSpan="1">
                                      <strong className="le-text--strong-invoice">
                                        {data.userProfile &&
                                          data.userProfile.firstName +
                                            " " +
                                            data.userProfile.lastName}
                                      </strong>
                                    </td>
                                  </tr>
                                  <tr className="le-table__row">
                                    <td className="le-table__cell" colSpan="1">
                                      <strong
                                        style={{ fontWeight: "600" }}
                                        className="le-text--invoice"
                                      >
                                        {data.userProfile &&
                                          data.userProfile.phoneNumber}
                                      </strong>
                                    </td>
                                  </tr>
                                  <tr className="le-table__row">
                                    <td className="le-table__cell" colSpan="1">
                                      <strong
                                        style={{ fontWeight: "600" }}
                                        className="le-text--invoice"
                                      >
                                        {data.userProfile &&
                                          data.userProfile.email}
                                      </strong>
                                    </td>
                                  </tr>
                                  <tr className="le-table__row">
                                    <td className="le-table__cell" colSpan="1">
                                      <strong
                                        style={{
                                          maxWidth: "24ch",
                                          fontWeight: "600",
                                        }}
                                        className="le-text--invoice"
                                      >
                                        {data.userProfile &&
                                          data.userProfile.address}
                                      </strong>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>
                        <Col xs={4} sm={24} md={4}>
                          <div className="invoice-contemplary-body-inside__header__logo-inside-tale-customer">
                            {/* QRCODE */}
                            <QRCode
                              value={qrCodeValue}
                              size={170}
                              imageSettings={{
                                src: qrImage,
                                height: 30,
                                width: 30,
                              }}
                            />
                          </div>
                        </Col>
                        <Col xs={10} sm={24} md={10}>
                          <center>
                            <div className="date-info-invoice-le">
                              <h2
                                style={{
                                  textAlign: "left",
                                  marginLeft: "50px",
                                }}
                                className="le-table-customer-data-heading"
                              >
                                <i className="fa fa-square box-gradient-icon-invoice-view"></i>
                                DATE & TIME
                              </h2>

                              <div>
                                <table className="le-table--plain">
                                  <tbody className="le-table__body">
                                    <tr className="le-table__row">
                                      <td
                                        className="le-table__cell"
                                        colSpan="1"
                                      >
                                        <strong className="le-text--issuedate-invoice">
                                          Issue date:{" "}
                                          {data.createdAt &&
                                            moment(data.createdAt).format(
                                              "MMMM Do YYYY"
                                            )}
                                        </strong>
                                      </td>
                                    </tr>
                                    <tr className="le-table__row">
                                      <td
                                        className="le-table__cell"
                                        colSpan="1"
                                      >
                                        <strong className="le-text--issuedate-invoice">
                                          Issue Time:
                                          {moment(
                                            new Date(data.createdAt)
                                          ).format("h:mm:ss a")}
                                        </strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </center>
                        </Col>
                      </Row>
                    </div>
                    <Col span={24}>
                      <div className="contemporary-template__items-table">
                        <table className="le-table">
                          <thead
                            className="le-table__header"
                            style={{
                              backgroundColor: "rgb(189, 155, 62)",
                            }}
                          >
                            <tr className="le-table__row">
                              <th
                                className="le-table__cell--amount"
                                colSpan="1"
                                style={{ color: "rgb(255, 255, 255)" }}
                              >
                                Sr. No
                              </th>
                              <th
                                className="le-table__cell--amount class-table-particular"
                                colSpan="5"
                                style={{
                                  color: "rgb(255, 255, 255)",
                                  width: "335px",
                                }}
                              >
                                Description of Services
                              </th>
                              <th
                                className="le-table__cell--amount"
                                colSpan="1"
                                style={{ color: "rgb(255, 255, 255)" }}
                              >
                                Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="le-table__row">
                              <td className="le-table__cell" colSpan="1">
                                <div className="no-items-message">
                                  <div
                                    className="le-text--emphasized"
                                    style={{
                                      fontWeight: "600",
                                      color: "#666666",
                                    }}
                                  >
                                    1.
                                  </div>
                                </div>
                              </td>
                              <td className="le-table__cell" colSpan="5">
                                <div className="no-items-message">
                                  <div
                                    className="le-text--emphasized"
                                    style={{
                                      fontWeight: "600",
                                      color: "#666666",
                                    }}
                                  >
                                    Open a File
                                  </div>
                                </div>
                              </td>
                              <td className="le-table__cell" colSpan="1">
                                <div className="no-items-message">
                                  <div
                                    className="le-text--emphasized"
                                    style={{
                                      fontWeight: "600",
                                      color: "#666666",
                                      textAlign: "right",
                                    }}
                                  >
                                    AED{" "}
                                    {data.amountToBePaid && data.amountToBePaid}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Col>
                    <div className="contemporary-template__divider--full-width contemporary-template__divider--bold">
                      <div className="le-divider-below"></div>
                    </div>

                    <Row className="how-to-work-grid">
                      <Col xs={12} sm={24} md={12}>
                        <div className="quotaion-signature-quotation-area">
                          {/* <p style={{fontWeight:'550'}}>To Accept this quotation, sign here and return</p>
                                <div className="quotaion-signature-quotation-area-box">

                                </div> */}
                        </div>
                      </Col>
                      <Col xs={12} sm={24} md={12}>
                        <div className="contemporary-template__totals__amounts">
                          <div className="contemporary-template__totals__amounts__line">
                            <div className="contemporary-template__totals__amounts__line__label">
                              <strong className="le-text--strong-totals__amounts">
                                Sub Total:
                              </strong>
                            </div>
                            <div className="contemporary-template__totals__amounts__line__amount">
                              <span
                                className="le-text-totals__amounts le-text--body-totals__amounts"
                                style={{
                                  marginRight: "-20px",
                                  fontWeight: "600",
                                  color: "#000000",
                                }}
                              >
                                AED {data.amountToBePaid && data.amountToBePaid}
                                {/*  {this.state.fileInvoiceData.amountToBePaid &&
                                        new Intl.NumberFormat().format(
                                          this.state.fileInvoiceData
                                            .amountToBePaid -
                                            this.state.fileInvoiceData.vatIncluded
                                        )} */}
                              </span>
                            </div>
                          </div>
                          <div></div>

                          <div>
                            <div className="contemporary-template__totals__amounts__line">
                              <div className="contemporary-template__totals__amounts__line__label">
                                <strong className="le-text--strong-totals__amounts">
                                  status:
                                </strong>
                              </div>
                              <div className="contemporary-template__totals__amounts__line__amount">
                                <strong
                                  className="le-text-totals__amounts le-text--body-totals__amounts"
                                  style={{
                                    marginRight: "-20px",
                                    fontWeight: "600",
                                    color: "#000000",
                                  }}
                                >
                                  {data.invoiceStatus && data.invoiceStatus}
                                </strong>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                        <div className="amount-padding-color-price">
                          Total Amount : &nbsp; &nbsp;&nbsp; &nbsp;
                          <span
                            style={{ marginRight: "3px", fontSize: "1rem" }}
                          >
                            AED
                          </span>
                          {data.amountToBePaid && data.amountToBePaid}
                        </div>
                      </Col>
                    </Row>

                    <div className="contemporary-template__divider--full-width contemporary-template__divider--bold">
                      <div className="le-divider"></div>
                    </div>

                    <div className="description_of_card_of_blogpost2">
                      <div className="description_of_card_of_newsroom_area-blogpost2">
                        <h2 className="blogs-description-area-of-main-headings-blogpost2">
                          Terms & Conditions
                        </h2>

                        <p
                          style={{ fontWeight: "600", fontSize: "13px" }}
                          className="invoice-description-area-of-preview--text"
                        >
                          1. This a computer generated invoice to open a file
                          payment. This is an electronic receipt; any signature
                          or stamp on it is not required.
                        </p>

                        <p
                          style={{ fontWeight: "600", fontSize: "13px" }}
                          className="invoice-description-area-of-preview--text"
                        >
                          2. The acknowledgment of this opening a file receipt
                          by the customer of the paid amount is shown as a
                          NON-REFUNDABLE payment. The customer has no right to
                          claim the refund of opening a file payment.
                        </p>

                        <p
                          style={{ fontWeight: "600", fontSize: "13px" }}
                          className="invoice-description-area-of-preview--text"
                        >
                          3. BADER HAMAD AL ZAABI’s ADVOCATES & LEGAL
                          CONSULTANTS reserve the right to review and aim the
                          above terms as per the judge's fit and as per the
                          applicable laws.
                        </p>

                        <p
                          style={{
                            fontWeight: "600",
                            fontSize: "13px",
                            textAlign: "left",
                          }}
                          className="invoice-description-area-of-preview---belowfooter-text"
                        >
                          For queries regarding "Open a file", please feel free
                          to contact our office on +971 4 255 5111 or send us an
                          email at info@legalemirates.com{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default InvoiceFileView;
