import React from "react";
import { Switch, Route } from "react-router-dom";

import routes from "../routes";

function BreadCrumb() {
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          children={route.breadCrumb}
        />
      ))}
    </Switch>
  );
}

export default BreadCrumb;
