import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { profileInstance } from "../../utils/baseurl";
import PhoneInput from "react-phone-input-2";
import styled from "styled-components";
import {
  Row,
  Col,
  Typography,
  Form,
  Input,
  Button,
  Modal,
  message,
  Switch,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import { setCurrentUser } from "../../store/actions/authActions";
import logo2 from "../../assets/images/logo2.png";

const { Title, Paragraph } = Typography;

const IntroDiv = styled.div`
  height: calc(100vh - 40px);
  background-color: #222222;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;
const LoginDiv = styled.div`
  height: calc(100vh - 40px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;

  & .login-form-forgot {
    color: #222222;
  }

  & .login-form-button {
    background-color: #222222;
    border-radius: 25px;
    padding: 0;

    & span {
      font-size: 20px;
    }
  }
`;

function Login(props) {
  const [loginBtnLoading, setLoginBtnLoading] = useState(false);
  const [position, setPosition] = useState({ lat: "", lon: "" });
  const [OTPValue, setOTPValue] = useState("");
  const [OTPModal, setOTPModal] = useState({
    visible: false,
    confirmLoading: false,
  });
  const [loginResData, setLoginResData] = useState(null);
  const [emailSendedOnSuspicious, setEmailSendedOnSuspicious] = useState(false);
  const [loginPhone, setLoginPhone] = useState(false);
  const [form] = Form.useForm();

  const getPosition = () =>
    new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            setPosition({
              ...position,
              lat: pos.coords.latitude,
              lon: pos.coords.longitude,
            });
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        message.error("Geolocation is not supported by this browser.");
      }
    });

  const handleSubmit = async (values) => {
    setLoginBtnLoading(true);
    try {
      await getPosition();
      if (loginPhone) {
        const res = await profileInstance.post(
          "/profileService/api/v1/lawyer/auth/lawyerLoginWithNumber",
          { phoneNumber: `+${values.phoneNumber}` }
        );
        if (res.data.msg) {
          setLoginResData(res.data);
          setOTPModal({ ...OTPModal, visible: true });
          setLoginBtnLoading(false);
        }
      } else {
        const res = await profileInstance.post(
          "/profileService/api/v1/lawyer/auth/lawyerLogin",
          { email: values.email, password: values.password }
        );
        if (res.data.msg) {
          // setLoginResData(res.data);
          // setOTPModal({ ...OTPModal, visible: true });
          localStorage.setItem("token", res.data.user.token);
          localStorage.setItem("refreshToken", res.data.user.refreshToken);
          props.setCurrentUser(res.data.user.token);
          setLoginBtnLoading(false);
        }
      }
    } catch (error) {
      setLoginBtnLoading(false);
      if (error.response) {
        form.setFields([
          {
            name: "email",
            errors: [error.response.data.message],
          },
          {
            name: "phoneNumber",
            errors: [error.response.data.message],
          },
        ]);
        return;
      }
      switch (error.code) {
        case error.PERMISSION_DENIED:
          message.error("You have to allow position to login");
          break;
        case error.POSITION_UNAVAILABLE:
          message.error("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          message.error("The request to get user location timed out.");
          break;
        default:
          message.error(error.message);
          break;
      }
    }
  };

  const handleOk = async () => {
    setOTPModal({ ...OTPModal, confirmLoading: true });
    const email = form.getFieldValue("email");
    const phoneNumber = form.getFieldValue("phoneNumber");
    try {
      let res;
      if (loginPhone) {
        res = await profileInstance.post(
          "/profileService/api/v1/lawyer/auth/lawyerVerifyWithNumber",
          {
            phoneNumber: `+${phoneNumber}`,
            otpCode: OTPValue,
          }
        );
      } else {
        res = await profileInstance.post(
          "/profileService/api/v1/lawyer/auth/lawyerVerify",
          {
            email,
            deviceName: "my device",
            barCodeDigit: OTPValue,
            lat: position.lat,
            lon: position.lon,
          }
        );
      }
      setOTPModal({
        ...OTPModal,
        visible: false,
        confirmLoading: false,
      });
      localStorage.setItem("token", res.data.user.token);
      localStorage.setItem("refreshToken", res.data.user.refreshToken);
      props.setCurrentUser(res.data.user.token);
    } catch (error) {
      if (error.response.data.codeName === "EmailSendedOnSuspicious") {
        setEmailSendedOnSuspicious(true);
      }
      if (error.response) {
        message.error(error.response.data.message, 1);
      }
      setOTPModal({
        ...OTPModal,
        confirmLoading: false,
      });
    }
  };

  const handleCancel = () => {
    setOTPModal({ ...OTPModal, visible: false });
  };

  const handleOTPField = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (!isNaN(value) && reg.test(value) && value.length <= 6) {
      setOTPValue(value);
    }
  };

  if (props.isAuthenticated) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="container py-5 mx-auto">
        <Row>
          <Col xs={0} lg={14}>
            <IntroDiv>
              <Title level={4} style={{ color: "#fff" }}>
                LawyerPanel
              </Title>
              <img src={logo2} alt="Logo" style={{ height: 200 }} />
              <div>
                <Title level={3} style={{ color: "#fff" }}>
                  Welcome to
                  <br />
                  Legal Emirates Lawyer Panel
                </Title>
                <Paragraph style={{ maxWidth: 400, color: "#fff" }}>
                  Only authorized users have access to create, handle, and
                  administer the LegalEmirates website, IOS & Android
                  application from this Lawer Panel. You are not allowed to
                  access our Admin panel without the company's permission. We
                  can take the lagal action against any suspecious or
                  unauthorized activity.
                </Paragraph>
              </div>
            </IntroDiv>
          </Col>
          <Col xs={24} lg={10}>
            <LoginDiv>
              <div style={{ width: "100%", maxWidth: 400 }}>
                <Title>
                  Login to
                  <br />
                  to your account
                </Title>
                <Form
                  layout="vertical"
                  size="large"
                  form={form}
                  onFinish={handleSubmit}
                >
                  {loginPhone ? (
                    <Form.Item
                      label="Phone Number"
                      name="phoneNumber"
                      rules={[
                        { required: true, message: "Phone Number is required" },
                      ]}
                    >
                      <PhoneInput
                        country={"ae"}
                        inputStyle={{ width: "100%" }}
                      />
                    </Form.Item>
                  ) : (
                    <>
                      <Form.Item
                        label="username/email"
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                          {
                            required: true,
                            message: "username/email is required",
                          },
                        ]}
                      >
                        <Input placeholder="myemail@address.com" />
                      </Form.Item>
                      <Form.Item
                        label="password"
                        name="password"
                        rules={[
                          { required: true, message: "password is required" },
                        ]}
                      >
                        <Input.Password
                          placeholder="••••••••"
                          iconRender={(visible) =>
                            visible ? (
                              <EyeTwoTone style={{ fontSize: 20 }} />
                            ) : (
                              <EyeInvisibleOutlined
                                style={{ color: "#222222", fontSize: 20 }}
                              />
                            )
                          }
                        />
                      </Form.Item>
                    </>
                  )}
                  {/* <Form.Item>
                  <a className="login-form-forgot" href="">
                    Forgot password
                  </a>
                </Form.Item> */}
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      loading={loginBtnLoading}
                      block
                    >
                      <span>Login</span>
                    </Button>
                  </Form.Item>
                </Form>
                <label>
                  Login with phone number
                  <Switch
                    onChange={(checked) => setLoginPhone(checked)}
                    style={{ marginLeft: "0.5rem" }}
                  />
                </label>
              </div>
            </LoginDiv>
          </Col>
        </Row>
        <Modal
          title="OTP Verification"
          centered
          visible={OTPModal.visible}
          onOk={handleOk}
          confirmLoading={OTPModal.confirmLoading}
          onCancel={handleCancel}
          maskClosable={false}
          closable={false}
          width={300}
          bodyStyle={{ display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {emailSendedOnSuspicious ? (
              <Paragraph>
                You have been prevented as an unusual sign in attempt at Legal
                Emirates Admin panel from different location. Please contact
                your admin to verify yourself through a verification code and
                confirm your login attempt.
              </Paragraph>
            ) : null}
            {loginResData && loginResData.qrcodePic ? (
              <img src={loginResData.qrcodePic} alt="QR PIC" />
            ) : null}
            {loginPhone ? (
              <>
                <Paragraph>Enter 4-digitAuthentication Code</Paragraph>
                <Input.Password
                  value={OTPValue}
                  onChange={handleOTPField}
                  autoFocus
                  placeholder="••••"
                  iconRender={(visible) =>
                    visible ? (
                      <EyeTwoTone style={{ fontSize: 20 }} />
                    ) : (
                      <EyeInvisibleOutlined
                        style={{ color: "#222222", fontSize: 20 }}
                      />
                    )
                  }
                />
              </>
            ) : (
              <>
                <Paragraph>Enter 6-digitAuthentication Code</Paragraph>
                <Input.Password
                  value={OTPValue}
                  onChange={handleOTPField}
                  autoFocus
                  placeholder="••••••"
                  iconRender={(visible) =>
                    visible ? (
                      <EyeTwoTone style={{ fontSize: 20 }} />
                    ) : (
                      <EyeInvisibleOutlined
                        style={{ color: "#222222", fontSize: 20 }}
                      />
                    )
                  }
                />
              </>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.isAuthenticated,
});

export default connect(mapStateToProps, { setCurrentUser })(Login);

// 3169004282
