import React from "react";
import { Modal, Divider, Input } from "antd";
import { CalendarOutlined, LinkOutlined } from "@ant-design/icons";

import modalLogo from "../../assets/images/logo2.png";

function ConfirmModal({ confirmModal, setConfirmModal }) {
  return (
    <Modal
      closable={false}
      visible={confirmModal}
      footer={null}
      bodyStyle={{ padding: 50 }}
      onCancel={() => setConfirmModal(false)}
    >
      <div className="confirm-modal">
        <div className="modal-logo" style={{ margin: "0 auto 20px" }}>
          <img src={modalLogo} alt="Modal Logo" width="100%" />
        </div>
        <h1>Confirmed</h1>
        <p>You are scheduled with Bader Al Zaabi</p>
        <Divider />
        <div style={{ width: "100%" }}>
          <h3 style={{ marginLeft: 40 }}>Free On Time Counselling</h3>
          <h3 style={{ color: "#BE9A39", margin: 0, fontWeight: "bold" }}>
            <CalendarOutlined style={{ fontSize: 20, marginRight: 20 }} />
            13:00 - 13:30, Monday, July 13, 2020
          </h3>
          <p style={{ marginLeft: 40 }}>
            Gulf Standard Time - Dubai, United Arab Emirates (GMT+4)
          </p>
          <div style={{ display: "flex", margin: "30px 0" }}>
            <LinkOutlined
              style={{ color: "#BE9A39", fontSize: 20, marginRight: 20 }}
            />
            <h3 style={{ color: "rgba(0, 0, 0, 0.65)" }}>
              https://meet.google.com/bqx-msrr-wqx/wqx?hs=5
            </h3>
          </div>
          <Divider />
          <p style={{ marginLeft: 40, fontSize: 14, marginBottom: 20 }}>
            Send this link to this email address
          </p>
          <Input
            placeholder="email@gmail.com"
            addonAfter="SEND"
            size="large"
            style={{ borderRadius: 25 }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmModal;
