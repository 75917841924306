import React, { Component } from "react";
import moment from "moment";
import styled from "styled-components";
import { Table, Badge, AutoComplete } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

import PrintCaseInvoice from "./PrintCaseInvoice";
import { fileInstance, profileInstance } from "../../utils/baseurl";

const Wrapper = styled.div`
  &
    .ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-radius: 25px;
  }
  & .ant-table-title {
    padding-bottom: 0;
  }
`;

const getCaseInvoicesParams = (params) => {
  return {
    limit: params.pagination?.pageSize,
    pageNo: params.pagination?.current,
    ...params,
  };
};

export default class CaseInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
      },
      stats: {},
      filterKey: "ALL",
      searchData: [],
    };
    this.printComponentRef = React.createRef();
  }

  async componentDidMount() {
    const { pagination } = this.state;
    this.fetchInvoices({ pagination });
    try {
      const res = await fileInstance.get(
        "/fileService/lawyerRoutes/api/v1/caseInvoices/getCountOfInvoices"
      );
      this.setState({ stats: res.data.stats });
    } catch (error) {
      console.error(error);
    }
  }

  fetchInvoices = async (params = {}) => {
    this.setState({ loading: true });
    try {
      const res = await fileInstance.get(
        "/fileService/lawyerRoutes/api/v1/caseInvoices/getInvoicesWithPagination",
        {
          params: getCaseInvoicesParams(params),
        }
      );
      this.setState({
        data: res.data.invoices.docs,
        loading: false,
        pagination: {
          current: res.data.invoices.page,
          pageSize: res.data.invoices.limit,
          total: res.data.invoices.totalDocs,
        },
      });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  };

  handleTableChange = (pagination) => {
    const { filterKey } = this.state;
    if (filterKey === "SENT") {
      this.fetchInvoices({
        pagination,
        isSent: true,
      });
    } else if (filterKey === "UNSEND") {
      this.fetchInvoices({
        pagination,
        isSent: false,
      });
    } else if (filterKey === "ALL") {
      this.fetchInvoices({
        pagination,
      });
    } else {
      this.fetchInvoices({
        pagination,
        status: filterKey,
      });
    }
  };

  handleSearch = async (value) => {
    if (value.length === 0) {
      this.fetchInvoices();
      return;
    }
    try {
      const res = await profileInstance.get(
        `/profileService/api/v1/lawyer/userData/searchUserByPhoneNumberOrFirstName?searchString=${value}`
      );
      this.setState({ searchData: res.data.users });
    } catch (error) {
      console.error(error);
    }
  };

  handleOnTapSearch = (_, { key }) => {
    const { pagination } = this.state;
    this.fetchInvoices({ pagination, userId: key });
  };

  handleFilterChange = (key) => {
    const { pagination } = this.state;
    this.setState({ filterKey: key });
    if (key === "SENT") {
      this.fetchInvoices({
        pagination,
        pageNo: 1,
        isSent: true,
      });
    } else if (key === "UNSEND") {
      this.fetchInvoices({
        pagination,
        pageNo: 1,
        isSent: false,
      });
    } else if (key === "ALL") {
      this.fetchInvoices({
        pagination,
        pageNo: 1,
      });
    } else {
      this.fetchInvoices({
        pagination,
        pageNo: 1,
        status: key,
      });
    }
  };

  render() {
    const {
      data,
      loading,
      stats,
      filterKey,
      pagination,
      searchData,
    } = this.state;

    const columns = [
      {
        title: "Cutomer",
        render: (data) =>
          data.userProfile
            ? `${data.userProfile.firstName} ${data.userProfile.lastName}`
            : "",
      },
      {
        title: "Invoice No",
        dataIndex: "invoiceNo",
      },
      {
        title: "Status",
        render: (data) => {
          switch (data.status) {
            case "PAID":
            case "CHEQUE_RECIEVED":
              return <p className="text-green-600">{data.status}</p>;
            case "CANCEL":
              return (
                <p className="text-red-600 flex items-center">
                  <ExclamationCircleFilled className="text-base mr-2" />
                  {data.status}
                </p>
              );
            case "UNPAID":
            case "PARTIAL_PAID":
              return <p style={{ color: "#a90941" }}>{data.status}</p>;
            default:
              return <p>{data.status}</p>;
          }
        },
      },
      {
        title: "Created Date",
        render: (data) => moment(data.generatedAt).format("YYYY-MM-DD"),
      },
      {
        title: "Due Date",
        render: (data) => moment(data.expireAt).format("YYYY-MM-DD"),
      },
      {
        title: "Total Amount",
        dataIndex: "amountToBePaid",
      },
      {
        title: "Pending Amount",
        dataIndex: "pendingAmount",
      },
      {
        title: "Print Invoice",
        render: (data) => <PrintCaseInvoice data={data} />,
      },
    ];
    return (
      <Wrapper className="container">
        <Table
          bordered
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={data}
          scroll={{ x: true }}
          loading={loading}
          pagination={pagination}
          onChange={this.handleTableChange}
          title={() => (
            <div>
              <div className="flex justify-between items-center mb-4">
                <h1 className="m-0">Case Invoices</h1>
                <AutoComplete
                  placeholder="search with name or phone num"
                  style={{ width: 300 }}
                  onSearch={this.handleSearch}
                  onSelect={this.handleOnTapSearch}
                >
                  {searchData.map((data) => (
                    <AutoComplete.Option
                      key={data._id}
                      value={data.firstName + " " + data.phoneNumber}
                    >
                      {data.firstName + " " + data.phoneNumber}
                    </AutoComplete.Option>
                  ))}
                </AutoComplete>
              </div>
              <div>
                {Object.entries(stats)
                  .sort()
                  .map(([key, value], i) => (
                    <button
                      className={`mr-4 mb-3 ${
                        key === filterKey && "border-b-2 border-black"
                      }`}
                      onClick={() => this.handleFilterChange(key)}
                      key={i}
                      style={{ outline: "none" }}
                    >
                      <Badge
                        count={value}
                        style={{
                          backgroundColor: "#e2e8f0",
                          color: "black",
                          marginRight: "10px",
                          marginBottom: 5,
                        }}
                      />
                      {key}
                    </button>
                  ))}
              </div>
            </div>
          )}
        />
      </Wrapper>
    );
  }
}

/* <button
                className="border-b-2 border-black mr-4 mb-3 outline-none"
                onClick={() => this.handleTableChange("allInvoices")}
              >
                <Badge
                  count={stats.ALL}
                  style={{
                    backgroundColor: "#e2e8f0",
                    color: "black",
                    marginRight: "10px",
                    marginBottom: 5,
                  }}
                />
                All Invoices
              </button>
              <button
                className="border-b-2 border-black mr-4 mb-3 outline-none"
                onClick={() => this.handleTableChange("sent")}
              >
                <Badge
                  count={stats.SENT}
                  style={{
                    backgroundColor: "#e2e8f0",
                    color: "black",
                    marginRight: "10px",
                    marginBottom: 5,
                  }}
                />
                Sent
              </button>
              <button className="border-b-2 border-black mr-4 mb-3 outline-none">
                <Badge
                  count={stats.UNSEND}
                  style={{
                    backgroundColor: "#e2e8f0",
                    color: "black",
                    marginRight: "10px",
                    marginBottom: 5,
                  }}
                />
                Draft
              </button>
              <button className="border-b-2 border-black mr-4 mb-3 outline-none">
                <Badge
                  count={stats.PAID}
                  style={{
                    backgroundColor: "#e2e8f0",
                    color: "black",
                    marginRight: "10px",
                    marginBottom: 5,
                  }}
                />
                Paid
              </button>
              <button className="border-b-2 border-black mr-4 mb-3 outline-none">
                <Badge
                  count={stats.UNPAID}
                  style={{
                    backgroundColor: "#e2e8f0",
                    color: "black",
                    marginRight: "10px",
                    marginBottom: 5,
                  }}
                />
                Unpaid
              </button>
              <button className="border-b-2 border-black mr-4 mb-3 outline-none">
                <Badge
                  count={stats.PARTIAL_PAID}
                  style={{
                    backgroundColor: "#e2e8f0",
                    color: "black",
                    marginRight: "10px",
                    marginBottom: 5,
                  }}
                />
                Partial Paid
              </button>
              <button className="border-b-2 border-black mr-4 mb-3 outline-none">
                <Badge
                  count={stats.CANCEL}
                  style={{
                    backgroundColor: "#e2e8f0",
                    color: "black",
                    marginRight: "10px",
                    marginBottom: 5,
                  }}
                />
                Cancelled
              </button> */
