import React, { Component } from "react";
import moment from "moment";
import {
  Table,
  Button,
  AutoComplete,
  // Tooltip,
  message,
} from "antd";
import {
  UserAddOutlined,
  // ExclamationCircleFilled,
  // KeyOutlined,
} from "@ant-design/icons";

import AddLawyerModal from "./AddLawyer";
import { profileInstance } from "../../utils/baseurl";

const getLawyersParams = (params) => {
  return {
    profile: "LEGALPENAL_USER",
    limit: params.pagination?.pageSize,
    pageNo: params.pagination?.current,
    ...params,
  };
};

export default class Lawyers extends Component {
  state = {
    userData: null,
    modal: false,
    lawyerImage: "",
    confirmLoading: false,
    data: [],
    loading: false,
    searchData: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchLawyers({ pagination });
  }

  fetchLawyers = async (params = {}) => {
    this.setState({ loading: true });
    try {
      const res = await profileInstance.get(
        "/profileService/api/v1/lawyer/userData/getUserswithSpecificProfile",
        {
          params: getLawyersParams(params),
        }
      );
      this.setState({
        data: res.data.users.docs,
        loading: false,
        pagination: {
          current: res.data.users.page,
          pageSize: res.data.users.limit,
          total: res.data.users.totalDocs,
        },
      });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  };

  handleOnCreateLawyer = async (values) => {
    this.setState({ confirmLoading: true });
    const newValues = {
      ...values,
      profileImage: this.state.lawyerImage,
      dob: values["dob"].toISOString(),
      type: "LEGALPENAL_USER",
    };
    try {
      await profileInstance.post(
        "https://api-profiles.wowets.com/profileService/api/v1/lawyer/userData/createUser",
        newValues
      );
      this.setState({ confirmLoading: false, modal: false });
      message.success("Lawyer Added");
    } catch (error) {
      this.setState({ confirmLoading: false, modal: false });
      console.error(error);
      if (error.response.data.message.includes("email_1 dup key")) {
        message.error("email already exists");
      } else {
        message.error("something went wrong try again later");
      }
    }
  };

  handleTableChange = (pagination) => {
    this.fetchLawyers({
      pagination,
    });
  };

  handleSearch = async (value) => {
    if (value.length === 0) {
      this.fetchLawyers();
      return;
    }
    try {
      const res = await profileInstance.get(
        `/profileService/api/v1/lawyer/userData/searchUserByPhoneNumberOrFirstName?profile=LEGALPENAL_USER&searchString=${value}`
      );
      this.setState({ searchData: res.data.users });
    } catch (error) {
      console.error(error);
    }
  };

  handleOnTapSearch = (_, { key }) => {
    const { pagination } = this.state;
    this.fetchLawyers({ pagination, userId: key });
  };

  render() {
    const {
      modal,
      confirmLoading,
      data,
      loading,
      pagination,
      searchData,
      userData,
    } = this.state;

    const columns = [
      {
        title: "Consultant Profile",
        render: (data) => (
          <img
            src={data.profileImage}
            className="w-16 h-16 rounded-full"
            alt="Lawyer Profile"
          />
        ),
      },
      {
        title: "First Name",
        dataIndex: "firstName",
      },
      {
        title: "last name",
        dataIndex: "lastName",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Contact No",
        dataIndex: "phoneNumber",
      },
      {
        title: "Created on",
        render: (data) =>
          data.createdAt
            ? moment(data.createdAt).format("MM/DD/YYYY, LTS")
            : "",
      },
      // {
      //   title: "Action",
      //   render: (data) => (
      //     <>
      //       <ExclamationCircleFilled
      //         style={{ fontSize: "1.2rem", marginRight: "0.7rem" }}
      //       />
      //       <Tooltip title="Forgot Password?" placement="bottom">
      //         <KeyOutlined
      //           style={{ fontSize: "1.2rem" }}
      //           onClick={() => this.setState({ userData: data, modal: true })}
      //         />
      //       </Tooltip>
      //     </>
      //   ),
      // },
    ];

    return (
      <div className="container mx-auto shadow bg-white">
        <Table
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={data}
          scroll={{ x: true }}
          loading={loading}
          pagination={pagination}
          onChange={this.handleTableChange}
          title={() => (
            <div className="flex justify-between items-center">
              <Button
                type="primary"
                shape="round"
                onClick={() => this.setState({ userData: null, modal: true })}
                style={{
                  padding: "20px 16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <UserAddOutlined
                  style={{
                    fontSize: "1rem",
                  }}
                />
                <span>Add New Consultant</span>
              </Button>
              <AutoComplete
                placeholder="search with name or phone num"
                style={{ width: 300 }}
                onSearch={this.handleSearch}
                onSelect={this.handleOnTapSearch}
              >
                {searchData.map((data) => (
                  <AutoComplete.Option
                    key={data._id}
                    value={data.firstName + " " + data.phoneNumber}
                  >
                    {data.firstName + " " + data.phoneNumber}
                  </AutoComplete.Option>
                ))}
              </AutoComplete>
            </div>
          )}
        />
        <AddLawyerModal
          modal={modal}
          userData={userData}
          confirmLoading={confirmLoading}
          onCreate={this.handleOnCreateLawyer}
          setModal={(action) =>
            this.setState({ userData: null, modal: action })
          }
          setLawyerImage={(value) => this.setState({ lawyerImage: value })}
        />
      </div>
    );
  }
}
