import React, { Component } from "react";
import moment from "moment";
import styled from "styled-components";
import { Table, Popover, AutoComplete } from "antd";
import { ExclamationCircleFilled, PlayCircleOutlined } from "@ant-design/icons";

import { fileInstance, profileInstance } from "../../utils/baseurl";
import ActionPopover from "./ActionPopover";

const Wrapper = styled.div`
  &
    .ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-radius: 25px;
  }
`;

const getFileInvoicesParams = (params) => {
  return {
    limit: params.pagination?.pageSize,
    pageNo: params.pagination?.current,
    ...params,
  };
};

export default class FileInvoices extends Component {
  state = {
    data: [],
    loading: false,
    searchData: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchInvoices({ pagination });
  }

  fetchInvoices = async (params = {}) => {
    this.setState({ loading: true });
    try {
      const res = await fileInstance.get(
        "/fileService/lawyerRoutes/api/v1/fileInvioces/getFileInvoicesWithPagination",
        {
          params: getFileInvoicesParams(params),
        }
      );
      this.setState({
        data: res.data.fileInvoices.docs,
        loading: false,
        pagination: {
          current: res.data.fileInvoices.page,
          pageSize: res.data.fileInvoices.limit,
          total: res.data.fileInvoices.totalDocs,
        },
      });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  };

  handleTableChange = (pagination) => {
    this.fetchInvoices({
      pagination,
    });
  };

  handleSearch = async (value) => {
    if (value.length === 0) {
      this.fetchInvoices();
      return;
    }
    try {
      const res = await profileInstance.get(
        `/profileService/api/v1/lawyer/userData/searchUserByPhoneNumberOrFirstName?searchString=${value}`
      );
      this.setState({ searchData: res.data.users });
    } catch (error) {
      console.error(error);
    }
  };

  handleOnTapSearch = (_, { key }) => {
    const { pagination } = this.state;
    this.fetchInvoices({ pagination, userId: key });
  };

  render() {
    const { data, loading, pagination, searchData } = this.state;

    const columns = [
      {
        title: "Customer",
        render: (data) =>
          data.userProfile
            ? `${data.userProfile.firstName} ${data.userProfile.lastName}`
            : "",
        align: "center",
      },
      {
        title: "Invoice No",
        dataIndex: "invoiceNo",
        align: "center",
      },
      {
        title: "Email",
        render: (data) => (data.userProfile ? `${data.userProfile.email}` : ""),
        align: "center",
      },
      {
        title: "Contact",
        render: (data) =>
          data.userProfile ? `${data.userProfile.phoneNumber}` : "",
        align: "center",
      },
      {
        title: "Created At",
        render: (data) => moment(data.createdAt).format("MMMM Do YYYY, LTS"),
        align: "center",
        width: 200,
      },
      {
        title: "Payment Type",
        dataIndex: "paymentType",
        align: "center",
      },
      {
        title: "Amount",
        render: (data) => `AED ${data.amountToBePaid}`,
        align: "center",
        width: 100,
      },
      {
        title: "Status",
        render: (data) => {
          switch (data.invoiceStatus) {
            case "PAID":
              return <p className="text-green-600">{data.invoiceStatus}</p>;
            case "UNPAID":
            case "CANCELED":
              return (
                <p className="text-red-600 flex items-center">
                  <ExclamationCircleFilled className="text-base mr-2" />
                  {data.invoiceStatus}
                </p>
              );
            default:
              return <p>{data.invoiceStatus}</p>;
          }
        },
        align: "center",
        width: 100,
      },
      {
        title: "Action",
        render: (data) => (
          <Popover
            placement="bottom"
            content={
              <ActionPopover
                data={data}
                fetchInvoices={this.fetchInvoices}
                page={pagination}
              />
            }
          >
            <PlayCircleOutlined className="text-base cursor-pointer" />
          </Popover>
        ),
        align: "center",
      },
    ];

    return (
      <Wrapper className="container">
        <Table
          columns={columns}
          bordered
          rowKey={(record) => record._id}
          dataSource={data}
          scroll={{ x: true }}
          loading={loading}
          pagination={pagination}
          onChange={this.handleTableChange}
          title={() => (
            <div className="flex justify-between items-center">
              <h1 className="m-0">File Invoices</h1>
              <AutoComplete
                placeholder="search with name or phone num"
                style={{ width: 300 }}
                onSearch={this.handleSearch}
                onSelect={this.handleOnTapSearch}
              >
                {searchData.map((data) => (
                  <AutoComplete.Option
                    key={data._id}
                    value={data.firstName + " " + data.phoneNumber}
                  >
                    {data.firstName + " " + data.phoneNumber}
                  </AutoComplete.Option>
                ))}
              </AutoComplete>
            </div>
          )}
        />
      </Wrapper>
    );
  }
}
