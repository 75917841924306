import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import ModalImage from "react-modal-image";
import { Table, AutoComplete } from "antd";

import { fileInstance, profileInstance } from "../utils/baseurl";

const Wrapper = styled.div`
  &
    .ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-radius: 25px;
  }
`;

const columns = [
  {
    title: "Customer",
    render: (data) =>
      data.userProfile
        ? `${data.userProfile.firstName} ${data.userProfile.lastName}`
        : "",
    align: "center",
  },
  {
    title: "Email",
    render: (data) => (data.userProfile ? `${data.userProfile.email}` : ""),
    align: "center",
  },
  {
    title: "Contact",
    render: (data) =>
      data.userProfile ? `${data.userProfile.phoneNumber}` : "",
    align: "center",
  },
  {
    title: "Created At",
    render: (data) => moment(data.createdAt).format("MMMM Do YYYY, LTS"),
    align: "center",
    width: 200,
  },
  {
    title: "Deposite Amount",
    render: (data) => `AED ${data.depositedAmount ? data.depositedAmount : ""}`,
    align: "center",
    width: 100,
  },
  {
    title: "Receipt",
    render: (data) => {
      const ext = data.receiptPath.split(".").pop();
      if (ext === "pdf") {
        return (
          <a
            href={`https://api-files.wowets.com/fileService/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${data.receiptPath}`}
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            Receipt
          </a>
        );
      } else {
        return (
          <ModalImage
            small={`https://api-files.wowets.com/fileService/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${data.receiptPath}`}
            large={`https://api-files.wowets.com/fileService/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${data.receiptPath}`}
            className="small-preview-image"
          />
        );
      }
    },
    align: "center",
  },
];

const getBankReceiptsParams = (params) => {
  return {
    limit: params.pagination?.pageSize,
    pageNo: params.pagination?.current,
    ...params,
  };
};

export default class BankReceipts extends Component {
  state = {
    data: [],
    loading: false,
    searchData: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.bankReceipts({ pagination });
  }

  bankReceipts = async (params = {}) => {
    this.setState({ loading: true });
    try {
      const res = await fileInstance.get(
        "/fileService/lawyerRoutes/api/v1/bankReceipts/getBankReceiptsWithPagination",
        {
          params: getBankReceiptsParams(params),
        }
      );
      this.setState({
        data: res.data.bankReceipts.docs,
        loading: false,
        pagination: {
          current: res.data.bankReceipts.page,
          pageSize: res.data.bankReceipts.limit,
          total: res.data.bankReceipts.totalDocs,
        },
      });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  };

  handleTableChange = (pagination) => {
    this.bankReceipts({
      pagination,
    });
  };

  handleSearch = async (value) => {
    if (value.length === 0) {
      this.bankReceipts();
      return;
    }
    try {
      const res = await profileInstance.get(
        `/profileService/api/v1/lawyer/userData/searchUserByPhoneNumberOrFirstName?searchString=${value}`
      );
      this.setState({ searchData: res.data.users });
    } catch (error) {
      console.error(error);
    }
  };

  handleOnTapSearch = (v, { key }) => {
    const { pagination } = this.state;
    this.bankReceipts({ pagination, userId: key });
  };

  render() {
    const { data, loading, pagination, searchData } = this.state;

    return (
      <Wrapper className="container mx-auto">
        <Table
          columns={columns}
          bordered
          rowKey={(record) => record._id}
          dataSource={data}
          scroll={{ x: true }}
          loading={loading}
          pagination={pagination}
          onChange={this.handleTableChange}
          title={() => (
            <div className="flex justify-between items-center">
              <h1 className="m-0">Bank Receipts</h1>
              <AutoComplete
                placeholder="search with name or phone num"
                style={{ width: 300 }}
                onSearch={this.handleSearch}
                onSelect={this.handleOnTapSearch}
              >
                {searchData.map((data) => (
                  <AutoComplete.Option
                    key={data._id}
                    value={data.firstName + " " + data.phoneNumber}
                  >
                    {data.firstName + " " + data.phoneNumber}
                  </AutoComplete.Option>
                ))}
              </AutoComplete>
            </div>
          )}
        />
      </Wrapper>
    );
  }
}
