import React, { Component } from "react";
import moment from "moment";
import styled from "styled-components";
import ReactToPrint from "react-to-print";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import ModalImage from "react-modal-image";
import {
  Tabs,
  Descriptions,
  Skeleton,
  Typography,
  Form,
  Input,
  message,
} from "antd";
import Icon, {
  PlusCircleFilled,
  PhoneFilled,
  EnvironmentFilled,
  MailOutlined,
} from "@ant-design/icons";

import CaseActionModal from "./CaseActionModal";
import CaseAttachmentModal from "./CaseAttachmentModal";
import VoiceRecorder from "./VoiceRecorder";
import { fileInstance } from "../../utils/baseurl";
import logo2 from "../../assets/images/logo2.png";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const { TabPane } = Tabs;
const { Item } = Descriptions;
const CardContainer = styled.div`
  & p {
    margin: 0;
  }
  & > .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }
  & > .ant-tabs-card .ant-tabs-content {
    margin-top: -16px;
  }
  & > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
  }
  & > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  & > .ant-tabs-card .ant-tabs-tab,
  [data-theme="compact"] & > .ant-tabs-card .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
    flex: 1;
  }
  & > .ant-tabs-card .ant-tabs-tab-active,
  [data-theme="compact"] & > .ant-tabs-card .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
  }
  #components-tabs-demo-card-top .code-box-demo {
    background: #f5f5f5;
    overflow: hidden;
    padding: 24px;
  }
  [data-theme="compact"] & > .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
  }
  [data-theme="dark"] & > .ant-tabs-card .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
  }
  [data-theme="dark"] #components-tabs-demo-card-top .code-box-demo {
    background: #000;
  }
  [data-theme="dark"] & > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #141414;
  }
  [data-theme="dark"] & > .ant-tabs-card .ant-tabs-tab-active {
    border-color: #141414;
    background: #141414;
  }
`;

const CaseWritingDiv = styled.div`
  h1 {
    font-size: 2em !important;
  }
  h2 {
    font-size: 1.5em !important;
  }
  h3 {
    font-size: 1.17em !important;
  }
  h4 {
    font-size: 1em !important;
  }
  h5 {
    font-size: 0.83em !important;
  }
  h6 {
    font-size: 0.67em !important;
  }

  & .case-writing-header {
    margin-bottom: 30px;

    & .case-writing-header-top {
      width: 100%;
      height: 50px;
      background-color: #be9d40;
      -webkit-clip-path: polygon(
        100% 0,
        100% 100%,
        76% 37%,
        61% 10%,
        50% 0,
        35% 13%,
        18% 46%,
        0 100%,
        0 0
      );
      clip-path: polygon(
        100% 0,
        100% 100%,
        76% 37%,
        61% 10%,
        50% 0,
        35% 13%,
        18% 46%,
        0 100%,
        0 0
      );
    }

    & .case-writing-header-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      margin-top: -30px;
      color: #be9d40;

      & .case-writing-header-content-center img {
        width: 150px;
      }
    }
  }

  & .case-writing-content-footer {
    width: 100%;
    background-color: #fff;
    margin-top: 140px;

    & .case-writing-bottom {
      width: 100%;
      height: 50px;
      margin-top: 10px;
      background-color: #be9d40;
      -webkit-clip-path: polygon(
        42% 79%,
        46% 85%,
        50% 93%,
        58% 82%,
        84% 33%,
        100% 0,
        100% 100%,
        0 100%,
        0 0,
        39% 75%
      );
      clip-path: polygon(
        42% 79%,
        46% 85%,
        50% 93%,
        58% 82%,
        84% 33%,
        100% 0,
        100% 100%,
        0 100%,
        0 0,
        39% 75%
      );
    }
  }

  @media print {
    @page {
      size: A4 portrait;
      margin: 0;
    }
    @page :left {
      @bottom-left {
        content: counter(page);
      }
    }
    & .case-writing-content-footer {
      position: fixed;
      bottom: 10px;
    }
    .pageBreak {
      page-break-before: always;
      margin-bottom: 30px;
    }
    & .case-writing-content-footer::before {
      counter-increment: page;
      content: "Page " counter(page);

      margin-top: 20px;
      margin-bottom: -40px;
    }
  }
`;

const TtySolid = () => (
  <svg
    width="1em"
    height="1em"
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="tty"
    className="svg-inline--fa fa-tty fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M5.37 103.822c138.532-138.532 362.936-138.326 501.262 0 6.078 6.078 7.074 15.496 2.583 22.681l-43.214 69.138a18.332 18.332 0 0 1-22.356 7.305l-86.422-34.569a18.335 18.335 0 0 1-11.434-18.846L351.741 90c-62.145-22.454-130.636-21.986-191.483 0l5.953 59.532a18.331 18.331 0 0 1-11.434 18.846l-86.423 34.568a18.334 18.334 0 0 1-22.356-7.305L2.787 126.502a18.333 18.333 0 0 1 2.583-22.68zM96 308v-40c0-6.627-5.373-12-12-12H44c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm-336 96v-40c0-6.627-5.373-12-12-12H92c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zM96 500v-40c0-6.627-5.373-12-12-12H44c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm288 0v-40c0-6.627-5.373-12-12-12H140c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h232c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12z"
    ></path>
  </svg>
);

export default class CaseDetail extends Component {
  state = {
    caseDetail: null,
    fileDetail: null,
    loading: false,
    editorState: EditorState.createEmpty(),
    caseActionModal: false,
    caseAccepted: false,
    attachmentModal: false,
    audioDetails: {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    },
  };

  componentDidMount() {
    this.fetchCaseDetail();
  }

  fetchCaseDetail = async () => {
    this.setState({ loading: true });
    try {
      const res = await fileInstance.get(
        `/fileService/lawyerRoutes/api/v1/cases/getCaseById/${this.props.match.params.id}`
      );
      this.setState({ caseDetail: res.data.caseDetail, loading: false });
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
    }
  };

  fetchFileDetail = async (key) => {
    if (key === "fileDetails") {
      this.setState({ loading: true });
      const { caseDetail } = this.state;
      try {
        const res = await fileInstance.get(
          `/fileService/lawyerRoutes/api/v1/files/getUsersWithPagination?fileNo=${caseDetail.fileNo}`
        );
        this.setState({ fileDetail: res.data.files.docs[0], loading: false });
      } catch (error) {
        console.error(error);
        this.setState({ loading: false });
      }
    }
  };

  saveCaseWriting = async () => {
    const { caseDetail, editorState } = this.state;
    const data = {
      caseId: caseDetail._id,
      caseWrittings: [
        ...caseDetail.caseWrittings,
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
      ],
    };

    try {
      await fileInstance.put(
        "/fileService/legalPenalRoutes/api/v1/cases/updateCaseWrittingsByCaseId",
        data
      );
    } catch (error) {
      console.error(error);
    }
  };

  handleCaseDetailUpdate = async (values, action) => {
    const { caseDetail } = this.state;
    const dataToUpdate = {
      caseId: caseDetail._id,
      attributes: { ...values },
    };

    try {
      await fileInstance.post(
        "/fileService/lawyerRoutes/api/v1/cases/updateCasebyId",
        dataToUpdate
      );
      if (action) {
        this.setState({ caseActionModal: true });
      } else {
        message.success("Case Updates Successfully!");
      }
    } catch (error) {
      console.error(error);
      message.error("Something went wrong plz try again later!");
    }
  };

  handleAudioStop(data) {
    this.setState({ audioDetails: data });
  }

  async handleAudioUpload(file) {
    const key = "file";
    message.loading({ content: "Uploading...", key });
    const audioFile = new File([file], Math.random() + "uploaded_file.mp3", {
      type: file.type,
      lastModified: Date.now(),
    });

    const formData = new FormData();
    formData.append("caseId", this.props.match.params.id);
    formData.append("audioNote", audioFile);

    try {
      await fileInstance.post(
        "/fileService/lawyerRoutes/api/v1/cases/uploadAnAudioNote",
        formData
      );
      message.success({ content: "Success!", key, duration: 2 });
      this.fetchCaseDetail();
    } catch (error) {
      console.error(error);
      message.error({ content: "Something went wrong!", key, duration: 2 });
    }
  }

  handleRest() {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    };
    this.setState({ audioDetails: reset });
  }

  render() {
    const {
      caseDetail,
      loading,
      editorState,
      caseActionModal,
      caseAccepted,
      attachmentModal,
      audioDetails,
    } = this.state;

    return (
      <>
        <CardContainer className="container mx-auto shadow-lg">
          <Tabs
            type="card"
            // onTabClick={this.fetchFileDetail}
          >
            <TabPane tab="Client Details" key="clientDetails">
              <Skeleton loading={loading} active className="mx-6">
                {caseDetail && (
                  <Descriptions bordered column={{ xs: 1, sm: 2, md: 3 }}>
                    <Item label="Full Name">
                      {caseDetail.userProfile.firstName +
                        " " +
                        caseDetail.userProfile.lastName}
                    </Item>
                    <Item label="Gender">
                      {caseDetail.userProfile.gender
                        ? caseDetail.userProfile.gender
                        : ""}
                    </Item>
                    <Item label="Emirates ID">
                      {caseDetail.emiratesIdNumber}
                    </Item>
                    <Item label="Email">{caseDetail.userProfile.email}</Item>
                    <Item label="Phone NO#">
                      {caseDetail.userProfile.phoneNumber}
                    </Item>
                    <Item label="Home Tel">
                      {caseDetail.homeTel === "undefined"
                        ? "Not Provided"
                        : caseDetail.homeTel}
                    </Item>
                    <Item label="Office Tel">
                      {caseDetail.officeTel === "undefined"
                        ? "Not Provided"
                        : caseDetail.officeTel}
                    </Item>
                    <Item label="Fax">
                      {caseDetail.fax === "undefined"
                        ? "Not Provided"
                        : caseDetail.fax}
                    </Item>
                    <Item label="Nationality">{caseDetail.nationality}</Item>
                    <Item label="UAE Address">
                      {caseDetail.permanentAddress}
                    </Item>
                    <Item label="Per Address">
                      {caseDetail.permanentAddress}
                    </Item>
                    <Item label="Occupation">
                      {caseDetail.business ? caseDetail.business : ""}
                    </Item>
                  </Descriptions>
                )}
              </Skeleton>
            </TabPane>
            <TabPane tab="File Details" key="fileDetails">
              <Skeleton loading={loading} active className="mx-6">
                {caseDetail && (
                  <Descriptions bordered column={{ xs: 1, sm: 2 }}>
                    <Item label="File NO#">
                      {caseDetail.caseNoPrefix + caseDetail.fileNo}
                    </Item>
                    <Item label="Client Type">{caseDetail.clientType}</Item>
                    <Item label="Lawyer">{caseDetail.lawyerName}</Item>
                  </Descriptions>
                )}
              </Skeleton>
            </TabPane>
            <TabPane tab="Case Details" key="caseDetails">
              {caseDetail && (
                <Descriptions bordered column={{ xs: 1, sm: 2, md: 3 }}>
                  <Item label="Case NO#">{caseDetail.caseNo}</Item>
                  <Item label="Case Type">{caseDetail.caseType}</Item>
                  <Item label="Case Status">{caseDetail.caseStatus}</Item>
                  <Item label="Client Type">{caseDetail.clientType}</Item>
                  <Item label="Lawyer">{caseDetail.lawyerName}</Item>
                  <Item label="Court">{caseDetail.stage}</Item>
                  <Item label="Opponent Name">{caseDetail.opponentName}</Item>
                  <Item label="POA Type">{caseDetail.poaType}</Item>
                  <Item label="Accusation">{caseDetail.accusation}</Item>
                  <Item label="Opening Date" span={2}>
                    {moment(caseDetail.createdAt).format("MMMM Do YYYY, LTS")}
                  </Item>
                  <Item label="Sign up/Sign in Dubai Online Web Portal">
                    <a
                      href="https://www.dc.gov.ae/DCWeb_Common/CommonPages.Login.aspx?lang=en-US"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link
                    </a>
                  </Item>
                  <Item label="Case Detail" span={3}>
                    {caseDetail.caseDescription}
                  </Item>
                </Descriptions>
              )}
              <div className="max-w-4xl mx-auto mt-12">
                <Form
                  labelCol={{ xs: 4 }}
                  onFinish={(values) =>
                    this.handleCaseDetailUpdate(values, false)
                  }
                >
                  <Form.Item label="Summary of Case" name="summaryOfCase">
                    <Input.TextArea rows={4}></Input.TextArea>
                  </Form.Item>
                  <Form.Item label="Advisory Opinion" name="advisoryOpinion">
                    <Input.TextArea rows={4}></Input.TextArea>
                  </Form.Item>
                  <Form.Item label="Note" name="note">
                    <Input.TextArea rows={4}></Input.TextArea>
                  </Form.Item>
                  <Form.Item label="Remarks" name="remarks">
                    <Input.TextArea rows={4}></Input.TextArea>
                  </Form.Item>
                  <Form.Item>
                    <div className="flex justify-end">
                      <button
                        className="bg-gray-800 hover:bg-black text-white py-2 px-4 rounded-full"
                        type="submit"
                      >
                        Update Case Detail
                      </button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </TabPane>
            <TabPane tab="Case Writing" key="caseWriting" className="p-4">
              <Typography.Title level={2}>Case Writing</Typography.Title>
              <div className="p-4 border">
                <Editor
                  editorClassName="react-draft-editor"
                  editorState={editorState}
                  // editorStyle={{ minHeight: 500 }}
                  onEditorStateChange={(editorState) =>
                    this.setState({ editorState })
                  }
                />
                <div className="flex justify-end">
                  <div className="inline-flex">
                    <ReactToPrint
                      trigger={() => {
                        return (
                          <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l">
                            Print Case Writing
                          </button>
                        );
                      }}
                      content={() => this.componentRef}
                    />
                    <button
                      className="bg-black text-white font-bold py-2 px-4 rounded-r"
                      onClick={this.saveCaseWriting}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <CaseWritingDiv ref={(el) => (this.componentRef = el)}>
                <div className="case-writing-header">
                  <div className="case-writing-header-top" />
                  <div className="case-writing-header-content">
                    <div className="case-writing-header-content-left">
                      <p
                        style={{
                          fontSize: 20,
                          fontWeight: 700,
                          marginBottom: 0,
                        }}
                      >
                        BADER HAMAD AL ZAABI
                      </p>
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 700,
                          marginBottom: 0,
                        }}
                      >
                        ADVOCATES &amp; LEGAL CONSULTANTS
                      </p>
                    </div>
                    <div className="case-writing-header-content-center">
                      <img src={logo2} alt="Logo" />
                    </div>
                    <div className="case-writing-header-content-right">
                      <p
                        className="arabic-font"
                        style={{
                          fontSize: 50,
                          fontWeight: 700,
                          marginBottom: 15,
                          textAlign: "right",
                        }}
                      >
                        بدر حمد الزعابي
                      </p>
                      <p
                        className="arabic-font"
                        style={{
                          fontSize: 25,
                          fontWeight: 700,
                          marginBottom: 0,
                        }}
                      >
                        للمحاماة والاستشارات القانونية
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="page-break"
                  style={{ paddingLeft: 20, paddingRight: 20 }}
                  dangerouslySetInnerHTML={{
                    __html: draftToHtml(
                      convertToRaw(editorState.getCurrentContent())
                    ),
                  }}
                />
                <div className="case-writing-content-footer">
                  <div className="container mx-auto">
                    <div className="flex justify-between items-center flex-wrap">
                      <div>
                        <PhoneFilled
                          style={{
                            backgroundColor: "#be9d40",
                            color: "#fff",
                            padding: "4px 2px 4px 4px",
                            fontSize: 16,
                            marginRight: 5,
                            borderRadius: 5,
                          }}
                        />
                        <span style={{ fontWeight: 700, fontSize: 12 }}>
                          +97142555111
                        </span>
                      </div>
                      <div>
                        <Icon
                          component={TtySolid}
                          style={{
                            backgroundColor: "#be9d40",
                            color: "#fff",
                            padding: "4px 2px 4px 4px",
                            fontSize: 16,
                            marginRight: 5,
                            borderRadius: 5,
                          }}
                        />
                        <span style={{ fontWeight: 700, fontSize: 12 }}>
                          +97142368888
                        </span>
                      </div>
                      <div>
                        <span style={{ fontWeight: 700, fontSize: 12 }}>
                          www.legalemirates.com
                        </span>
                      </div>
                      <div>
                        <MailOutlined
                          style={{
                            backgroundColor: "#be9d40",
                            color: "#fff",
                            padding: "4px 2px 4px 4px",
                            fontSize: 16,
                            marginRight: 5,
                            borderRadius: 5,
                          }}
                        />
                        <span style={{ fontWeight: 700, fontSize: 12 }}>
                          info@legalemirates.com
                        </span>
                      </div>
                      <div className="mt-4">
                        <EnvironmentFilled
                          style={{
                            backgroundColor: "#be9d40",
                            color: "#fff",
                            padding: "4px 2px 4px 4px",
                            fontSize: 16,
                            marginRight: 5,
                            borderRadius: 5,
                          }}
                        />
                        <span style={{ fontWeight: 700, fontSize: 12 }}>
                          Office No. 3403, 34th Floor Burj Al Salam Tower, Trade
                          Centre 1, Sheikh Zayed Road, P.O. Box No. 89498,
                          Dubai, UAE.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="case-writing-bottom"></div>
                </div>
              </CaseWritingDiv>
            </TabPane>
            <TabPane tab="Add Audio" key="addAudio">
              <table className="table-auto min-w-full">
                <thead>
                  <tr>
                    <th className="border px-4 py-6">Serial No</th>
                    <th className="border px-4 py-6">Added Date</th>
                    <th className="border px-4 py-6">Audio</th>
                  </tr>
                </thead>
                <tbody>
                  {caseDetail &&
                    caseDetail.audioNotes.map((audio, i) => (
                      <tr key={audio._id}>
                        <td className="border px-4 py-2">{i + 1}</td>
                        <td className="border px-4 py-2">
                          {audio.createdAt
                            ? moment(audio.createdAt).format("MMMM DD YYYY")
                            : ""}
                        </td>
                        <td className="border px-4 py-2">
                          <audio src={audio.audioPath} controls />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div>
                <VoiceRecorder
                  title="New recording"
                  audioURL={audioDetails.url}
                  showUIAudio
                  handleAudioStop={(data) => this.handleAudioStop(data)}
                  handleAudioUpload={(data) => this.handleAudioUpload(data)}
                  handleRest={() => this.handleRest()}
                />
              </div>
            </TabPane>
            <TabPane tab="Attachment" key="attachment">
              <table className="table-auto min-w-full">
                <thead>
                  <tr>
                    <th className="border px-4 py-6">Serial No</th>
                    <th className="border px-4 py-6">Document Name</th>
                    <th className="border px-4 py-6">Document No</th>
                    <th className="border px-4 py-6">Document File</th>
                  </tr>
                </thead>
                <tbody>
                  {caseDetail &&
                    caseDetail.attachments.map((attachment, i) => {
                      const ext = attachment.docLink.split(".").pop();
                      const doc =
                        ext === "pdf" ? (
                          <a
                            href={`https://api-files.wowets.com/fileService/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${attachment.docLink}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="underline"
                          >
                            Document
                          </a>
                        ) : (
                          <ModalImage
                            small={`https://api-files.wowets.com/fileService/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${attachment.docLink}`}
                            large={`https://api-files.wowets.com/fileService/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${attachment.docLink}`}
                            className="small-preview-image"
                          />
                        );
                      return (
                        <tr key={attachment._id}>
                          <td className="border px-4 py-2">{i + 1}</td>
                          <td className="border px-4 py-2">
                            {attachment.docName}
                          </td>
                          <td className="border px-4 py-2">
                            {attachment.docNumber}
                          </td>
                          <td className="border px-4 py-2">{doc}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <div className="flex justify-center py-8">
                <button
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                  onClick={() => this.setState({ attachmentModal: true })}
                >
                  <PlusCircleFilled
                    style={{ fontSize: "1rem", marginRight: 10 }}
                  />
                  Add Document
                </button>
              </div>
            </TabPane>
            <TabPane tab="Litigation" key="litigation">
              <Typography.Title level={3} className="mt-4 ml-4">
                Case Invoices
              </Typography.Title>
              {caseDetail && caseDetail.invoice.length > 0 ? (
                <table className="table-auto min-w-full">
                  <thead>
                    <tr>
                      <th className="border px-4 py-6">Invoice No</th>
                      <th className="border px-4 py-6">File No</th>
                      <th className="border px-4 py-6">Invoice Status</th>
                      <th className="border px-4 py-6">Remaining Amount</th>
                      <th className="border px-4 py-6">Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {caseDetail.invoice.map((invoice) => (
                      <tr key={invoice._id}>
                        <td className="border px-4 py-2">
                          {invoice.invoiceNo}
                        </td>
                        <td className="border px-4 py-2">{invoice.fileNo}</td>
                        <td className="border px-4 py-2">{invoice.status}</td>
                        <td className="border px-4 py-2">
                          {invoice.pendingAmount}
                        </td>
                        <td className="border px-4 py-2">
                          {invoice.amountToBePaid}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="flex justify-center items-center h-40 font-bold text-lg">
                  No Invoice Exist Yet!
                </p>
              )}
            </TabPane>
          </Tabs>
        </CardContainer>
        {caseDetail && caseDetail.caseStatus === "PENDING" && (
          <div className="container mx-auto mt-4 flex justify-end">
            <button
              className="border border-red-600 text-red-600 hover:bg-red-600 hover:text-white py-2 px-4 rounded-full mr-2"
              onClick={() => {
                this.setState({ caseAccepted: false });
                this.handleCaseDetailUpdate({ caseStatus: "CLOSED" }, true);
              }}
            >
              Reject/Close Case
            </button>
            <button
              className="border border-green-700 text-green-700 hover:bg-green-700 hover:text-white py-2 px-4 rounded-full"
              onClick={() => {
                this.setState({ caseAccepted: true });
                this.handleCaseDetailUpdate({ caseStatus: "ACTIVE" }, true);
              }}
            >
              Accept/Open Case
            </button>
          </div>
        )}
        <CaseActionModal
          caseActionModal={caseActionModal}
          accepted={caseAccepted}
          caseDetail={caseDetail}
          setCaseActionModal={(action) =>
            this.setState({ caseActionModal: action })
          }
        />
        <CaseAttachmentModal
          caseId={this.props.match.params.id}
          attachmentModal={attachmentModal}
          fetchCaseDetail={this.fetchCaseDetail}
          setAttachmentModal={() => this.setState({ attachmentModal: false })}
        />
      </>
    );
  }
}
