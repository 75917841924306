import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Layout } from "antd";

import { Login, Dashboard } from "./components";
import PrivateRoute from "./components/PrivateRoute";

import "./invoiceview.less";
import "./App.less";

function App({ loading }) {
  if (loading) {
    return null;
  } else {
    return (
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute path="/">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Layout>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.loading,
});

export default connect(mapStateToProps)(App);
