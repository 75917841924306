import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import {
  Typography,
  Switch,
  Form,
  Checkbox,
  Row,
  Col,
  Select,
  Input,
  Divider,
  Button,
  DatePicker,
  Upload,
  message,
} from "antd";
import { UserOutlined, InboxOutlined } from "@ant-design/icons";

import { fileInstance } from "../../utils/baseurl";
import logo from "../../assets/images/logo3.png";

const { Option } = Select;

const Wrapper = styled.div`
  & .ant-switch-checked {
    background-color: #be9d40;
  }

  & .ant-form-item-label > label {
    width: 100%;
  }

  & .ant-checkbox-wrapper span {
    font-size: 1.1em;
  }
`;

const AddClientWrapper = styled.div`
  height: 185px;

  & .ant-select {
    height: 100%;
    width: 100%;
  }

  & .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 100%;
  }

  & .ant-select-selection-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .ant-select-selection-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    font-size: 0.85rem;
  }
`;

export default function OpenFile() {
  const [users, setUsers] = useState([]);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [isCheque, setIsCheque] = useState(false);
  const [typeInArabic, setTypeInArabic] = useState(false);
  const allUsers = useSelector((state) => state.allUsers);
  const [form] = Form.useForm();

  useEffect(() => {
    setUsers(allUsers);
  }, [allUsers]);

  const handleOnFinish = async (values) => {
    setFormSubmitting(true);
    const parsedClient = JSON.parse(values.client);
    const formData = new FormData();
    formData.append("userId", parsedClient._id);
    formData.append("fileOptions", values.fileOptions);
    formData.append("fullName", values.fullName);
    formData.append("nationality", values.nationality);
    formData.append("address", values.address);
    formData.append("permanentAddress", values.permanentAddress);
    formData.append("mobileNumber", values.mobileNumber);
    formData.append("homeTel", values.homeTel);
    formData.append("officeTel", values.officeTel);
    formData.append("fax", values.fax);
    formData.append("email", values.email);
    formData.append("summary", values.summary);
    formData.append("paymentType", values.paymentType);
    formData.append("companyName", values.companyName);
    formData.append("personal", values.personal);
    if (values.paymentType === "CHEQUE") {
      formData.append("chequeNumber", values.chequeNo);
      formData.append("expiry", values["expiryDate"].toISOString());
      formData.append("bankCheque", values.bankCheque[0].originFileObj);
    }

    try {
      await fileInstance.post(
        "/fileService/lawyerRoutes/api/v1/files/openFile",
        formData
      );
      setFormSubmitting(false);
      message.success("File Added!");
      form.resetFields();
    } catch (error) {
      console.error(error);
      setFormSubmitting(false);
      message.error("Something went wrong!");
    }
  };

  const arabicAttributes = {};

  if (typeInArabic) {
    arabicAttributes.dir = "rtl";
    arabicAttributes.lang = "ar";
  }

  return (
    <Wrapper className="container mx-auto bg-white px-8 py-4 rounded-md shadow">
      <div className="w-full flex h-4">
        <div className="w-40 bg-gold"></div>
        <div className="w-20 bg-gold-lighter"></div>
      </div>
      <div className="flex justify-between">
        <Typography.Title level={3} className="self-center">
          Customer File
          <span style={{ fontSize: "inherit", marginLeft: "2rem" }}>
            فتح ملف
          </span>
        </Typography.Title>
        <img src={logo} alt="Logo" className="w-2/5" />
      </div>
      <div className="flex justify-between items-center">
        <div className="text-2xl flex items-center">
          Date
          <span className="border border-black ml-1 px-1 text-xs">
            {moment().format("DD")}
          </span>
          <span className="border border-black ml-1 px-1 text-xs">
            {moment().format("MM")}
          </span>
          <span className="border border-black ml-1 px-1 text-xs">
            {moment().format("YYYY")}
          </span>
        </div>
        <Typography.Text>File No BZ/....</Typography.Text>
      </div>
      <div className="w-full flex h-4">
        <div className="w-3/4 bg-gold"></div>
        <div className="w-3/12 bg-gold-lighter"></div>
      </div>
      <div className="flex justify-between items-center">
        <div className="text-2xl">
          Client*
          <span style={{ fontSize: "inherit", marginLeft: "2rem" }}>
            الموكل
          </span>
        </div>
        <label>
          Type in Arabic
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            style={{ marginLeft: 10 }}
            onChange={(checked) => setTypeInArabic(checked)}
          />
        </label>
      </div>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleOnFinish}
        onValuesChange={(changedFields, al) => {
          if (changedFields.paymentType) {
            if (changedFields.paymentType === "CHEQUE") {
              setIsCheque(true);
            } else {
              setIsCheque(false);
              form.resetFields(["chequeNo", "expiryDate", "chequeImage"]);
            }
          }
          if (changedFields.client) {
            const parsedClient = JSON.parse(changedFields.client);
            form.setFieldsValue({
              fullName: parsedClient.firstName + " " + parsedClient.lastName,
              email: parsedClient.email,
              mobileNumber: parsedClient.phoneNumber,
            });
          }
        }}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="fileOptions"
              rules={[
                {
                  validator(_, value) {
                    if (
                      value !== undefined &&
                      value.find((op) => op === "FILE_REG")
                    ) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        "Must select the File Registration"
                      );
                    }
                  },
                },
              ]}
            >
              <Checkbox.Group style={{ width: "100%" }}>
                <Row>
                  <Col span={12}>
                    <Checkbox value="FILE_REG" style={{ lineHeight: "32px" }}>
                      <span>File Registration</span>
                      <span className="ml-8"> تسجيل الملف</span>
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="CORPORATE" style={{ lineHeight: "32px" }}>
                      <span>Corporate</span>
                      <span className="ml-8"> الشركات</span>
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox
                      value="CONSULTATION"
                      style={{ lineHeight: "32px" }}
                    >
                      <span>Consultation</span>
                      <span className="ml-8">استشارة</span>
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="BANK" style={{ lineHeight: "32px" }}>
                      <span>bank</span>
                      <span className="ml-8">بنوك</span>
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox
                      value="CREATE_ATTORNEY"
                      style={{ lineHeight: "32px" }}
                    >
                      <span>Create a power of attorney</span>
                      <span className="ml-8">عمل وكالة</span>
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="INDIVIDUAL" style={{ lineHeight: "32px" }}>
                      <span>Individual</span>
                      <span className="ml-8">فرد</span>
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox
                      value="BEHALF_OF_ANOTHER"
                      style={{ lineHeight: "32px" }}
                    >
                      <span>Behalf of another Person</span>
                      <span className="ml-8">نيابة عن شخص آخر</span>
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="text-2xl my-4">Client Details</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="border p-2">
              <h1 className="text-2xl m-0 py-2">Note*</h1>
              <p className="text-lg">
                You can only register the file of client once you have selected
                the client first, then you can proceed further. His name,
                address and other credentials will be filled automatically.
              </p>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <AddClientWrapper className="mt-4 md:mt-0">
              <Form.Item noStyle name="client">
                <Select
                  placeholder="Add Client"
                  dropdownRender={(menu) => (
                    <div className="px-2">
                      <Input.Search
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.length === 0) {
                            setUsers(allUsers);
                            return;
                          } else {
                            const searchedUsers = users.filter((user) =>
                              user.firstName
                                .toLowerCase()
                                .includes(value.toLowerCase())
                            );
                            setUsers(searchedUsers);
                          }
                        }}
                      />
                      <p className="my-4">
                        <UserOutlined
                          style={{
                            fontSize: "1rem",
                            marginRight: "5px",
                          }}
                        />
                        Existing Clients
                      </p>
                      <Divider style={{ margin: "5px 0" }} />
                      {menu}
                    </div>
                  )}
                >
                  {users.map((user) => (
                    <Select.Option key={user._id} value={JSON.stringify(user)}>
                      <p>{user.firstName + " " + user.lastName}</p>
                      <p>{user.phoneNumber}</p>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </AddClientWrapper>
          </Col>
          <Col xs={24} md={12} className="mt-8">
            <Form.Item
              name="fullName"
              label={
                <div className="flex w-full justify-between items-center">
                  Full Name
                  <span style={{ fontSize: "inherit" }}>الاسم الكامل</span>
                </div>
              }
              rules={[
                {
                  required: true,
                  message: "Full Name is required",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} className="mt-8">
            <Form.Item
              name="nationality"
              label={
                <div className="flex w-full justify-between items-center">
                  Nationality
                  <span style={{ fontSize: "inherit" }}>الجنسية</span>
                </div>
              }
              rules={[
                {
                  required: true,
                  message: "Nationality is required",
                },
              ]}
            >
              <Select showSearch>
                <Option value="Afghanistan">Afghanistan</Option>
                <Option value="Åland Islands">Åland Islands</Option>
                <Option value="Albania">Albania</Option>
                <Option value="Algeria">Algeria</Option>
                <Option value="American Samoa">American Samoa</Option>
                <Option value="Andorra">Andorra</Option>
                <Option value="Angola">Angola</Option>
                <Option value="Anguilla">Anguilla</Option>
                <Option value="Antarctica">Antarctica</Option>
                <Option value="Antigua and Barbuda">Antigua and Barbuda</Option>
                <Option value="Argentina">Argentina</Option>
                <Option value="Armenia">Armenia</Option>
                <Option value="Aruba">Aruba</Option>
                <Option value="Australia">Australia</Option>
                <Option value="Austria">Austria</Option>
                <Option value="Azerbaijan">Azerbaijan</Option>
                <Option value="Bahamas">Bahamas</Option>
                <Option value="Bahrain">Bahrain</Option>
                <Option value="Bangladesh">Bangladesh</Option>
                <Option value="Barbados">Barbados</Option>
                <Option value="Belarus">Belarus</Option>
                <Option value="Belgium">Belgium</Option>
                <Option value="Belize">Belize</Option>
                <Option value="Benin">Benin</Option>
                <Option value="Bermuda">Bermuda</Option>
                <Option value="Bhutan">Bhutan</Option>
                <Option value="Bolivia">Bolivia</Option>
                <Option value="Bosnia and Herzegovina">
                  Bosnia and Herzegovina
                </Option>
                <Option value="Botswana">Botswana</Option>
                <Option value="Bouvet Island">Bouvet Island</Option>
                <Option value="Brazil">Brazil</Option>
                <Option value="British Indian Ocean Territory">
                  British Indian Ocean Territory
                </Option>
                <Option value="Brunei Darussalam">Brunei Darussalam</Option>
                <Option value="Bulgaria">Bulgaria</Option>
                <Option value="Burkina Faso">Burkina Faso</Option>
                <Option value="Burundi">Burundi</Option>
                <Option value="Cambodia">Cambodia</Option>
                <Option value="Cameroon">Cameroon</Option>
                <Option value="Canada">Canada</Option>
                <Option value="Cape Verde">Cape Verde</Option>
                <Option value="Cayman Islands">Cayman Islands</Option>
                <Option value="Central African Republic">
                  Central African Republic
                </Option>
                <Option value="Chad">Chad</Option>
                <Option value="Chile">Chile</Option>
                <Option value="China">China</Option>
                <Option value="Christmas Island">Christmas Island</Option>
                <Option value="Cocos (Keeling) Islands">
                  Cocos (Keeling) Islands
                </Option>
                <Option value="Colombia">Colombia</Option>
                <Option value="Comoros">Comoros</Option>
                <Option value="Congo">Congo</Option>
                <Option value="Congo, The Democratic Republic of The">
                  Congo, The Democratic Republic of The
                </Option>
                <Option value="Cook Islands">Cook Islands</Option>
                <Option value="Costa Rica">Costa Rica</Option>
                <Option value="Cote D'ivoire">Cote D'ivoire</Option>
                <Option value="Croatia">Croatia</Option>
                <Option value="Cuba">Cuba</Option>
                <Option value="Cyprus">Cyprus</Option>
                <Option value="Czech Republic">Czech Republic</Option>
                <Option value="Denmark">Denmark</Option>
                <Option value="Djibouti">Djibouti</Option>
                <Option value="Dominica">Dominica</Option>
                <Option value="Dominican Republic">Dominican Republic</Option>
                <Option value="Ecuador">Ecuador</Option>
                <Option value="Egypt">Egypt</Option>
                <Option value="El Salvador">El Salvador</Option>
                <Option value="Equatorial Guinea">Equatorial Guinea</Option>
                <Option value="Eritrea">Eritrea</Option>
                <Option value="Estonia">Estonia</Option>
                <Option value="Ethiopia">Ethiopia</Option>
                <Option value="Falkland Islands (Malvinas)">
                  Falkland Islands (Malvinas)
                </Option>
                <Option value="Faroe Islands">Faroe Islands</Option>
                <Option value="Fiji">Fiji</Option>
                <Option value="Finland">Finland</Option>
                <Option value="France">France</Option>
                <Option value="French Guiana">French Guiana</Option>
                <Option value="French Polynesia">French Polynesia</Option>
                <Option value="French Southern Territories">
                  French Southern Territories
                </Option>
                <Option value="Gabon">Gabon</Option>
                <Option value="Gambia">Gambia</Option>
                <Option value="Georgia">Georgia</Option>
                <Option value="Germany">Germany</Option>
                <Option value="Ghana">Ghana</Option>
                <Option value="Gibraltar">Gibraltar</Option>
                <Option value="Greece">Greece</Option>
                <Option value="Greenland">Greenland</Option>
                <Option value="Grenada">Grenada</Option>
                <Option value="Guadeloupe">Guadeloupe</Option>
                <Option value="Guam">Guam</Option>
                <Option value="Guatemala">Guatemala</Option>
                <Option value="Guernsey">Guernsey</Option>
                <Option value="Guinea">Guinea</Option>
                <Option value="Guinea-bissau">Guinea-bissau</Option>
                <Option value="Guyana">Guyana</Option>
                <Option value="Haiti">Haiti</Option>
                <Option value="Heard Island and Mcdonald Islands">
                  Heard Island and Mcdonald Islands
                </Option>
                <Option value="Holy See (Vatican City State)">
                  Holy See (Vatican City State)
                </Option>
                <Option value="Honduras">Honduras</Option>
                <Option value="Hong Kong">Hong Kong</Option>
                <Option value="Hungary">Hungary</Option>
                <Option value="Iceland">Iceland</Option>
                <Option value="India">India</Option>
                <Option value="Indonesia">Indonesia</Option>
                <Option value="Iran, Islamic Republic of">
                  Iran, Islamic Republic of
                </Option>
                <Option value="Iraq">Iraq</Option>
                <Option value="Ireland">Ireland</Option>
                <Option value="Isle of Man">Isle of Man</Option>
                <Option value="Israel">Israel</Option>
                <Option value="Italy">Italy</Option>
                <Option value="Jamaica">Jamaica</Option>
                <Option value="Japan">Japan</Option>
                <Option value="Jersey">Jersey</Option>
                <Option value="Jordan">Jordan</Option>
                <Option value="Kazakhstan">Kazakhstan</Option>
                <Option value="Kenya">Kenya</Option>
                <Option value="Kiribati">Kiribati</Option>
                <Option value="Korea, Democratic People's Republic of">
                  Korea, Democratic People's Republic of
                </Option>
                <Option value="Korea, Republic of">Korea, Republic of</Option>
                <Option value="Kuwait">Kuwait</Option>
                <Option value="Kyrgyzstan">Kyrgyzstan</Option>
                <Option value="Lao People's Democratic Republic">
                  Lao People's Democratic Republic
                </Option>
                <Option value="Latvia">Latvia</Option>
                <Option value="Lebanon">Lebanon</Option>
                <Option value="Lesotho">Lesotho</Option>
                <Option value="Liberia">Liberia</Option>
                <Option value="Libyan Arab Jamahiriya">
                  Libyan Arab Jamahiriya
                </Option>
                <Option value="Liechtenstein">Liechtenstein</Option>
                <Option value="Lithuania">Lithuania</Option>
                <Option value="Luxembourg">Luxembourg</Option>
                <Option value="Macao">Macao</Option>
                <Option value="Macedonia, The Former Yugoslav Republic of">
                  Macedonia, The Former Yugoslav Republic of
                </Option>
                <Option value="Madagascar">Madagascar</Option>
                <Option value="Malawi">Malawi</Option>
                <Option value="Malaysia">Malaysia</Option>
                <Option value="Maldives">Maldives</Option>
                <Option value="Mali">Mali</Option>
                <Option value="Malta">Malta</Option>
                <Option value="Marshall Islands">Marshall Islands</Option>
                <Option value="Martinique">Martinique</Option>
                <Option value="Mauritania">Mauritania</Option>
                <Option value="Mauritius">Mauritius</Option>
                <Option value="Mayotte">Mayotte</Option>
                <Option value="Mexico">Mexico</Option>
                <Option value="Micronesia, Federated States of">
                  Micronesia, Federated States of
                </Option>
                <Option value="Moldova, Republic of">
                  Moldova, Republic of
                </Option>
                <Option value="Monaco">Monaco</Option>
                <Option value="Mongolia">Mongolia</Option>
                <Option value="Montenegro">Montenegro</Option>
                <Option value="Montserrat">Montserrat</Option>
                <Option value="Morocco">Morocco</Option>
                <Option value="Mozambique">Mozambique</Option>
                <Option value="Myanmar">Myanmar</Option>
                <Option value="Namibia">Namibia</Option>
                <Option value="Nauru">Nauru</Option>
                <Option value="Nepal">Nepal</Option>
                <Option value="Netherlands">Netherlands</Option>
                <Option value="Netherlands Antilles">
                  Netherlands Antilles
                </Option>
                <Option value="New Caledonia">New Caledonia</Option>
                <Option value="New Zealand">New Zealand</Option>
                <Option value="Nicaragua">Nicaragua</Option>
                <Option value="Niger">Niger</Option>
                <Option value="Nigeria">Nigeria</Option>
                <Option value="Niue">Niue</Option>
                <Option value="Norfolk Island">Norfolk Island</Option>
                <Option value="Northern Mariana Islands">
                  Northern Mariana Islands
                </Option>
                <Option value="Norway">Norway</Option>
                <Option value="Oman">Oman</Option>
                <Option value="Pakistan">Pakistan</Option>
                <Option value="Palau">Palau</Option>
                <Option value="Palestinian Territory, Occupied">
                  Palestinian Territory, Occupied
                </Option>
                <Option value="Panama">Panama</Option>
                <Option value="Papua New Guinea">Papua New Guinea</Option>
                <Option value="Paraguay">Paraguay</Option>
                <Option value="Peru">Peru</Option>
                <Option value="Philippines">Philippines</Option>
                <Option value="Pitcairn">Pitcairn</Option>
                <Option value="Poland">Poland</Option>
                <Option value="Portugal">Portugal</Option>
                <Option value="Puerto Rico">Puerto Rico</Option>
                <Option value="Qatar">Qatar</Option>
                <Option value="Reunion">Reunion</Option>
                <Option value="Romania">Romania</Option>
                <Option value="Russian Federation">Russian Federation</Option>
                <Option value="Rwanda">Rwanda</Option>
                <Option value="Saint Helena">Saint Helena</Option>
                <Option value="Saint Kitts and Nevis">
                  Saint Kitts and Nevis
                </Option>
                <Option value="Saint Lucia">Saint Lucia</Option>
                <Option value="Saint Pierre and Miquelon">
                  Saint Pierre and Miquelon
                </Option>
                <Option value="Saint Vincent and The Grenadines">
                  Saint Vincent and The Grenadines
                </Option>
                <Option value="Samoa">Samoa</Option>
                <Option value="San Marino">San Marino</Option>
                <Option value="Sao Tome and Principe">
                  Sao Tome and Principe
                </Option>
                <Option value="Saudi Arabia">Saudi Arabia</Option>
                <Option value="Senegal">Senegal</Option>
                <Option value="Serbia">Serbia</Option>
                <Option value="Seychelles">Seychelles</Option>
                <Option value="Sierra Leone">Sierra Leone</Option>
                <Option value="Singapore">Singapore</Option>
                <Option value="Slovakia">Slovakia</Option>
                <Option value="Slovenia">Slovenia</Option>
                <Option value="Solomon Islands">Solomon Islands</Option>
                <Option value="Somalia">Somalia</Option>
                <Option value="South Africa">South Africa</Option>
                <Option value="South Georgia and The South Sandwich Islands">
                  South Georgia and The South Sandwich Islands
                </Option>
                <Option value="Spain">Spain</Option>
                <Option value="Sri Lanka">Sri Lanka</Option>
                <Option value="Sudan">Sudan</Option>
                <Option value="Suriname">Suriname</Option>
                <Option value="Svalbard and Jan Mayen">
                  Svalbard and Jan Mayen
                </Option>
                <Option value="Swaziland">Swaziland</Option>
                <Option value="Sweden">Sweden</Option>
                <Option value="Switzerland">Switzerland</Option>
                <Option value="Syrian Arab Republic">
                  Syrian Arab Republic
                </Option>
                <Option value="Taiwan, Province of China">
                  Taiwan, Province of China
                </Option>
                <Option value="Tajikistan">Tajikistan</Option>
                <Option value="Tanzania, United Republic of">
                  Tanzania, United Republic of
                </Option>
                <Option value="Thailand">Thailand</Option>
                <Option value="Timor-leste">Timor-leste</Option>
                <Option value="Togo">Togo</Option>
                <Option value="Tokelau">Tokelau</Option>
                <Option value="Tonga">Tonga</Option>
                <Option value="Trinidad and Tobago">Trinidad and Tobago</Option>
                <Option value="Tunisia">Tunisia</Option>
                <Option value="Turkey">Turkey</Option>
                <Option value="Turkmenistan">Turkmenistan</Option>
                <Option value="Turks and Caicos Islands">
                  Turks and Caicos Islands
                </Option>
                <Option value="Tuvalu">Tuvalu</Option>
                <Option value="Uganda">Uganda</Option>
                <Option value="Ukraine">Ukraine</Option>
                <Option value="United Arab Emirates">
                  United Arab Emirates
                </Option>
                <Option value="United Kingdom">United Kingdom</Option>
                <Option value="United States">United States</Option>
                <Option value="United States Minor Outlying Islands">
                  United States Minor Outlying Islands
                </Option>
                <Option value="Uruguay">Uruguay</Option>
                <Option value="Uzbekistan">Uzbekistan</Option>
                <Option value="Vanuatu">Vanuatu</Option>
                <Option value="Venezuela">Venezuela</Option>
                <Option value="Viet Nam">Viet Nam</Option>
                <Option value="Virgin Islands, British">
                  Virgin Islands, British
                </Option>
                <Option value="Virgin Islands, U.S.">
                  Virgin Islands, U.S.
                </Option>
                <Option value="Wallis and Futuna">Wallis and Futuna</Option>
                <Option value="Western Sahara">Western Sahara</Option>
                <Option value="Yemen">Yemen</Option>
                <Option value="Zambia">Zambia</Option>
                <Option value="Zimbabwe">Zimbabwe</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="companyName"
              label={
                <div className="flex w-full justify-between items-center">
                  Company Name
                  <span style={{ fontSize: "inherit" }}>العنوان الإماراتي</span>
                </div>
              }
            >
              <Input {...arabicAttributes} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="personal"
              label={
                <div className="flex w-full justify-between items-center">
                  personal/company Manager
                  <span style={{ fontSize: "inherit" }}>
                    الشخص بصفتة الشخصية/ مدير الشركة
                  </span>
                </div>
              }
            >
              <Input {...arabicAttributes} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="address"
              label={
                <div className="flex w-full justify-between items-center">
                  UAE Address
                  <span style={{ fontSize: "inherit" }}>العنوان الإماراتي</span>
                </div>
              }
              rules={[
                {
                  required: true,
                  message: "UAE Address is required",
                },
              ]}
            >
              <Input {...arabicAttributes} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="permanentAddress"
              label={
                <div className="flex w-full justify-between items-center">
                  Permanent Address
                  <span style={{ fontSize: "inherit" }}>العنوان الثابت</span>
                </div>
              }
              rules={[
                {
                  required: true,
                  message: "Permanent Address is required",
                },
              ]}
            >
              <Input {...arabicAttributes} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="mobileNumber"
              label={
                <div className="flex w-full justify-between items-center">
                  Mobile Number
                  <span style={{ fontSize: "inherit" }}>الجنسية</span>
                </div>
              }
              rules={[
                {
                  required: true,
                  message: "Mobile Number is required",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="homeTel"
              label={
                <div className="flex w-full justify-between items-center">
                  Home Tel
                  <span style={{ fontSize: "inherit" }}>هاتف المنزل</span>
                </div>
              }
            >
              <Input {...arabicAttributes} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="officeTel"
              label={
                <div className="flex w-full justify-between items-center">
                  Office Tel
                  <span style={{ fontSize: "inherit" }}>هاتف المكتب</span>
                </div>
              }
            >
              <Input {...arabicAttributes} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="fax"
              label={
                <div className="flex w-full justify-between items-center">
                  Fax
                  <span style={{ fontSize: "inherit" }}>فاكس</span>
                </div>
              }
            >
              <Input {...arabicAttributes} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="email"
              label={
                <div className="flex w-full justify-between items-center">
                  Certified Email
                  <span style={{ fontSize: "inherit" }}>
                    البريد الالكتروني المعتمد
                  </span>
                </div>
              }
              rules={[
                {
                  required: true,
                  message: "Certified Email is required",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="summary"
              label={
                <div className="flex w-full justify-between items-center">
                  Summary Of Matter
                  <span style={{ fontSize: "inherit" }}>ملخص الموضوع</span>
                </div>
              }
              rules={[
                {
                  required: true,
                  message: "Summary Of Matter is required",
                },
              ]}
            >
              <Input.TextArea rows={4} {...arabicAttributes} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="text-2xl">
              Payment Type*
              <span style={{ fontSize: "inherit", marginLeft: "2rem" }}>
                نوع الدفع
              </span>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="paymentType"
              label={
                <div className="flex w-full justify-between items-center">
                  Payment Method
                  <span style={{ fontSize: "inherit" }}>ملخص الموضوع</span>
                </div>
              }
              rules={[
                {
                  required: true,
                  message: "Payment Method is required",
                },
              ]}
            >
              <Select>
                <Select.Option value="CASH">CASH</Select.Option>
                <Select.Option value="ONLINE_BANK_TRANSFER">
                  BANK TRANSFER
                </Select.Option>
                <Select.Option value="CHEQUE">CHEQUE</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {isCheque && (
            <>
              <Col xs={24} md={{ span: 12, offset: 1, pull: 1 }}>
                <Form.Item
                  name="chequeNo"
                  label="Cheque No"
                  rules={[
                    {
                      required: true,
                      message: "Cheque No is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={{ span: 12, offset: 1, pull: 1 }}>
                <Form.Item
                  name="expiryDate"
                  label="Expiry Date"
                  rules={[
                    {
                      required: true,
                      message: "Expiry Date is required",
                    },
                  ]}
                >
                  <DatePicker style={{ minWidth: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} md={{ span: 12, offset: 1, pull: 1 }}>
                <Form.Item
                  name="bankCheque"
                  label="Upload Image"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => {
                    if (Array.isArray(e)) {
                      return e;
                    }
                    return e && e.fileList;
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Cheque Image is required",
                    },
                  ]}
                >
                  <Upload.Dragger
                    onRemove={(file) => {
                      const fileList = form.getFieldValue("bankCheque");
                      const index = fileList.indexOf(file);
                      const newFileList = fileList.slice();
                      newFileList.splice(index, 1);
                      return {
                        fileList: newFileList,
                      };
                    }}
                    beforeUpload={(file) => {
                      form.setFieldsValue({ bankCheque: file });
                      return false;
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Select Cheque Image/PDF</p>
                  </Upload.Dragger>
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={24}>
            <p className="text-lg">
              Our Professional Fees Of 1000 + VAT For Open A File Or Verbal
              Legal Advise Will Be Deducted From Our Fees For Matter Of Further
              Work Is Required.
            </p>
            <p className="text-base font-bold text-right">
              يتقاضى المكتب 1000 درهم + ضريبة القيمة المضافة عن فتح ملف او
              الاستشارة الشفوية و سيتم خصم المبلغ من أتعاب القضية اذا ما تم
              قبولها او تسجيلها لدى المكتب
            </p>
          </Col>
          <Col span={24} className="mt-8">
            <Form.Item
              name="policy"
              valuePropName="checked"
              rules={[{ required: true, message: "Accept the Policy" }]}
            >
              <div className="flex">
                <Checkbox />
                <ul className="list-decimal ml-8 text-lg">
                  <li>
                    This a computer generated invoice to open a file payment.
                    This is an electronic receipt; any signature or stamp on it
                    is not required.
                  </li>
                  <li>
                    Our professional fess of AED 1000 + VAT for open a file or
                    verbal legal advice will be deducted from our fess for
                    matter of further work is required.
                  </li>
                  <li>
                    The acknowledgment of this opening a file receipt by the
                    customer of the paid amount AED 1000 is shown as a
                    NON-REFUNDABLE payment. The customer has no right to claim
                    the refund of opening a file payment.
                  </li>
                </ul>
              </div>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item wrapperCol={{ xs: 24, md: { span: 2, offset: 22 } }}>
              <Button type="primary" htmlType="submit" loading={formSubmitting}>
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Wrapper>
  );
}
