import React, { Component } from "react";
import { Row, Col, Table, AutoComplete } from "antd";

import { fileInstance, profileInstance } from "../../utils/baseurl";
import { caseListColumns } from "./data";
import totalCaseIcon from "../../assets/images/total-cases.png";
import activeCaseIcon from "../../assets/images/active-cases.png";
import pendingCaseIcon from "../../assets/images/pending-cases.png";
import closeCaseIcon from "../../assets/images/close-cases.png";

const getAllCasesParams = (params) => {
  return {
    limit: params.pagination?.pageSize,
    pageNo: params.pagination?.current,
    ...params,
  };
};

export default class Cases extends Component {
  state = {
    stats: null,
    data: [],
    loading: false,
    searchData: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
  };

  componentDidMount() {
    const { pagination } = this.state;

    this.getCasesCount();
    this.getCasesList({ pagination });
  }

  getCasesCount = async () => {
    try {
      const res = await fileInstance.get(
        "/fileService/lawyerRoutes/api/v1/cases/getCountOfCases"
      );
      this.setState({ stats: res.data.stats });
    } catch (error) {
      console.error(error);
    }
  };

  getCasesList = async (params = {}) => {
    this.setState({ loading: true });
    try {
      const res = await fileInstance.get(
        "/fileService/lawyerRoutes/api/v1/cases/getCasesWithPagination",
        {
          params: getAllCasesParams(params),
        }
      );
      this.setState({ data: res.data.cases.docs });
      this.setState({
        data: res.data.cases.docs,
        loading: false,
        pagination: {
          current: res.data.cases.page,
          pageSize: res.data.cases.limit,
          total: res.data.cases.totalDocs,
        },
      });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  };

  handleSearch = async (value) => {
    if (value.length === 0) {
      this.getCasesList();
      return;
    }
    try {
      const res = await profileInstance.get(
        `/profileService/api/v1/lawyer/userData/searchUserByPhoneNumberOrFirstName?searchString=${value}`
      );
      this.setState({ searchData: res.data.users });
    } catch (error) {
      console.error(error);
    }
  };

  handleOnTapSearch = (_, { key }) => {
    const { pagination } = this.state;
    this.getCasesList({ pagination, userId: key });
  };

  handleTableChange = (pagination) => {
    this.getCasesList({
      pagination,
    });
  };

  render() {
    const { stats, data, loading, pagination, searchData } = this.state;
    return (
      <div className="container mx-auto">
        <Row gutter={30} className="mb-12">
          <Col xs={24} sm={12} md={6}>
            <div className="shadow-md p-2 flex justify-around items-center bg-white rounded">
              <div className="mx-4">
                <img src={totalCaseIcon} alt="ICON" width="50px" />
              </div>
              <div>
                <p>Total Cases</p>
                <h1 className="text-3xl m-0 text-center">{stats?.ALL}</h1>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <div className="shadow-md p-2 flex justify-around items-center bg-white rounded">
              <div className="mx-4">
                <img src={activeCaseIcon} alt="ICON" width="50px" />
              </div>
              <div>
                <p>Active Cases</p>
                <h1 className="text-3xl m-0 text-center">{stats?.ACTIVE}</h1>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <div className="shadow-md p-2 flex justify-around items-center bg-white rounded">
              <div className="mx-4">
                <img src={pendingCaseIcon} alt="ICON" width="50px" />
              </div>
              <div>
                <p>Pending Cases</p>
                <h1 className="text-3xl m-0 text-center">{stats?.PENDING}</h1>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <div className="shadow-md p-2 flex justify-around items-center bg-white rounded">
              <div className="mx-4">
                <img src={closeCaseIcon} alt="ICON" width="50px" />
              </div>
              <div>
                <p>Closed Cases</p>
                <h1 className="text-3xl m-0 text-center">{stats?.CLOSED}</h1>
              </div>
            </div>
          </Col>
        </Row>
        <Table
          columns={caseListColumns}
          rowKey={(record) => record._id}
          dataSource={data}
          scroll={{ x: true }}
          loading={loading}
          pagination={pagination}
          onChange={this.handleTableChange}
          bordered
          title={() => (
            <div className="flex justify-between items-center">
              <h1 className="m-0">Cases</h1>
              <AutoComplete
                placeholder="search with name or phone num"
                style={{ width: 300 }}
                onSearch={this.handleSearch}
                onSelect={this.handleOnTapSearch}
              >
                {searchData.map((data) => (
                  <AutoComplete.Option
                    key={data._id}
                    value={data.firstName + " " + data.phoneNumber}
                  >
                    {data.firstName + " " + data.phoneNumber}
                  </AutoComplete.Option>
                ))}
              </AutoComplete>
            </div>
          )}
        />
      </div>
    );
  }
}
