import React from "react";
import ReactToPrint from "react-to-print";

import CaseInvoiceTemp from "./invoiceview";

export default function PrintCaseInvoice({ data }) {
  const ref = React.createRef();

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <button className="bg-gold px-16 py-2 text-lg text-white mt-3">
            Print
          </button>
        )}
        content={() => ref.current}
      />
      <div style={{ display: "none" }}>
        <CaseInvoiceTemp ref={ref} data={data} />
      </div>
    </>
  );
}
